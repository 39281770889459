import './Shop.css';
import {Col, Container, Row} from "react-bootstrap";

const ShopTitle = () => {
    
    return (
        <Container className="mt-3 mb-3">
            <Row>
                <Col>
                    <h1 className='shopTitle'>Local</h1>
                </Col>
            </Row>
        </Container>
    )

}

export default ShopTitle;