import React, {useState} from 'react';
import './Menu.css';
import { AiOutlineClose } from 'react-icons/ai';
import { IoHomeOutline, IoShareSocialOutline, IoStorefrontOutline, IoGridOutline, IoCartOutline } from "react-icons/io5"
import { HiOutlinePaintBrush } from "react-icons/hi2";
import { BsCartPlus } from "react-icons/bs";
import { FiMenu } from 'react-icons/fi';
import { PiClipboardText } from 'react-icons/pi';
import MenuItem from './MenuItem';
import MenuRechargeCartaButton from './MenuRechargeCartaButton';
import MenuLogoutButton from './MenuLogoutButton';

export default function Menu() {


  const [isOpen, setIsOpen] = useState(false);
  
  const toggleSidebar = () => setIsOpen(!isOpen);
  
    return (
        <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>

          <button onClick={toggleSidebar} className="toggle-btn">
              {isOpen ? <AiOutlineClose /> : <FiMenu />}
          </button>

          <nav>
            <MenuItem isOpen={isOpen} path={"/home"} Icon={IoHomeOutline} text={"Inicio"} />
            <MenuItem isOpen={isOpen} path={"/shop"} Icon={IoStorefrontOutline} text={"Locales"}/>
            <MenuItem isOpen={isOpen} path={"/categories"} Icon={IoGridOutline} text={"Categorías"}/>
            <MenuItem isOpen={isOpen} path={"/misProductos"} Icon={PiClipboardText} text={"Productos"}/>
            <MenuItem isOpen={isOpen} path={"/diseño"} Icon={HiOutlinePaintBrush} text={"Diseño"}/>
            <MenuItem isOpen={isOpen} path={"/pedidoMostrador"} Icon={BsCartPlus} text={"Crear pedido"}/>
            <MenuItem isOpen={isOpen} path={"/orders"} Icon={IoCartOutline} text={"Pedidos"}/>
            <MenuRechargeCartaButton isOpen={isOpen} />
          </nav>

          <MenuLogoutButton isOpen={isOpen} />

        </div>
    );
}
