import {useEffect, useState} from 'react';
import '../CartaOrderShop.css';
import iconoCubiertos from '../iconoCubiertos.svg';
import iconoFooter from '../logoFooter.svg';
import {
  AddAlt,
  LogoFacebook,
  LogoInstagram,
  LogoTwitter,
  Search,
  ShoppingCart,
  SubtractAlt,
  TrashCan
} from '@carbon/icons-react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { IoIosArrowDropleftCircle , IoIosArrowDroprightCircle  } from "react-icons/io";

export default function Carta() {

  const [categories, setCategories] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [shop, setShop] = useState();
  
  const [instagram, setInstagram] = useState('');
  const [facebook, setFacebook] = useState('');
  const [twitter, setTwitter] = useState('');

  const [imageSrc, setImageSrc] = useState('');
  const [colorshop, setColorShop] = useState('#000000');
  const [nameShop, setNameShop] = useState('');
  const [nameCategorySelected, setNameCategorySelected] = useState('');

  const [productsForCategory, setProductsForCategory] = useState([]);

  const [searchProducts, setSearchProducts] = useState([]);
  const [searchCategoriesWithProducts, setSearchCategoriesWithProducts] = useState([]);
  const [searching, setSearching] = useState(false);

  const [allProducts, setAllProducts] = useState(true);
  const allProductsText = "TODOS LOS PRODUCTOS";

  const [dataProductsPedidoModal, setDataProductsPedidoModal] = useState([]);
  const [observationsPedido, setObservationsPedido] = useState('');

  //datos de envio
  const [nameAndSuername, setNameAndSuername] = useState('');
  const [isDelivery, setIsDelivery] = useState(false);
  const [stateAddress, setStateAddress] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [numberAddress, setNumberAddress] = useState('');
  const [floorAddress, setFloorAddress] = useState('');
  const [deptoAddress, setDeptoAddress] = useState('');
  const [observationsAddress, setObservationsAddress] = useState('');

  const [modules, setModules] = useState([]);
  const [moduleConfigs, setModuleConfigs] = useState([]);

  const SendOrderType = {
    A_DOMICILIO: { label: "A domicilio", value: 1 },
    RETIRO_LOCAL: { label: "Retira en local", value: 2 }
  };
  const OrderType = {
    WHATSAP: { label: "WhatsApp", value: 1 },
    EN_MESA: { label: "En Mesa", value: 2 },
    DELIVERY: { label: "Delivery", value: 3 },
    A_DISTANCIA: { label: "A Distancia", value: 4 },
    MOSTRADOR: { label: "Mostrador", value: 5 }
  };

  useEffect(() => {
    document.title = 'CARTA';
    if(!localStorage.getItem("productsPedido")){
      const listadoVacio = [];
      const listadoVacioJSON = JSON.stringify(listadoVacio);
      localStorage.setItem("productsPedido", listadoVacioJSON);
    }
    getAllCategories();
    getShop();
    getRedes();
    getAllProvinces();
    getAllModules();
    getAllModuleConfigs();

    setTimeout(updateConfigs, 10000);
    setInterval(updateConfigs, 10000);
  }, []);

  const updateConfigs = () => {
    getAllModules();
    getAllModuleConfigs();
  }

  const getAllProvinces = () => {
    axios.get(process.env.REACT_APP_API_PROVINCES_ROUTE, {
      withCredentials: true
    })
    .then(response => {
        setProvinces(response.data);
      })
      .catch(error => {
        console.error(error)});
  }

  const getAllCategories = () => {
    axios.get(process.env.REACT_APP_API_CATEGORIES_PRODUCTS_ROUTE, {
      withCredentials: true
    })
    .then(response => {
        setCategories(response.data);
        setNameCategorySelected(allProductsText);
      })
      .catch(error => {
        console.error(error)});
  }

  const getShop = () => {
    axios.get(process.env.REACT_APP_API_SHOP_ROUTE + '/1', {
      withCredentials: true
    })
    .then(response => {
        const shopData = response.data;
        if(shopData.image != null){
          const arrayBuffer = Uint8Array.from(atob(shopData.image), c => c.charCodeAt(0));
          const blob = new Blob([arrayBuffer], { type: 'image/jpeg' }); // Cambia 'image/jpeg' al tipo MIME correcto según el tipo de imagen que recibas
          const imageUrl = URL.createObjectURL(blob);
          setImageSrc(imageUrl);
        } else {
          setImageSrc(null);
        }
        setShop(shopData);
        setColorShop(shopData.principalColorShop);
        setNameShop(shopData.name);
      })
      .catch(error => {
        console.error(error)});
  }

  const getRedes = () => {
    axios.get(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE, {
      withCredentials: true,
        params: {
            status: true,
        }
      })
      .then(response => {
          getValueRedInstagram(response.data);
          getValueRedFacebook(response.data);
          getValueRedTwitter(response.data);
      })
  }

  const getAllModules = () => {
    axios.get(process.env.REACT_APP_API_MODULES_ROUTE, {
      withCredentials: true
    })
        .then(response => {
          setModules(response.data);
        })
        .catch(error => {
          console.error(error)});
  }

  const getAllModuleConfigs = () => {
    axios.get(process.env.REACT_APP_API_MODULES_CONFIGS_ROUTE + "1", {
      withCredentials: true
    })
        .then(response => {
          setModuleConfigs(response.data);
        })
        .catch(error => {
          console.error(error)});
  }

  function getValueByCode(code) {
    const moduleId = modules.find(module => module.code === code)?.id;
    if (moduleId === undefined) {
      return null;
    }

    const config = moduleConfigs.find(config => config.moduleConfigId === moduleId);
    if (config) {
      return config.value;
    } else {
      return null;
    }
  }

  const getValueRedInstagram = (redes) => {
    let instagram = redes && redes.find(red => red.socialMediaType === "INSTAGRAM");
    if(instagram != null){
        setInstagram(instagram.socialMediaLink);
    }
  }

  const getValueRedFacebook = (redes) =>{
      let facebook = redes && redes.find(red => red.socialMediaType === "FACEBOOK");
      if(facebook != null){
          setFacebook(facebook.socialMediaLink);
      }
  }

  const getValueRedTwitter = (redes) =>{
      let twitter = redes && redes.find(red => red.socialMediaType === "TWITTER");
      if(twitter != null){
          setTwitter(twitter.socialMediaLink);
      }
  }

  const fillProductsByIdCategorySelected = (idCategory) => {
      setProductsForCategory('');
      document.getElementById('nameProdSearch').value = "";
      search('');
      const categoria = categories.find(categoria => categoria.id === idCategory);
      setProductsForCategory(categoria ? categoria.productDTOList : []);
  }

  const fillAllProducts = () => {
    setProductsForCategory('');
    document.getElementById('nameProdSearch').value = "";
    search('');
  }

const filtrarCategoriasPorProducto = (texto) => {
  const categoriasFiltradas = [];

  categories.forEach(categoria => {
      const productosFiltrados = categoria.productDTOList.filter(producto => {
          return producto.name.toLowerCase().includes(texto.toLowerCase());
      });

      if (productosFiltrados.length > 0) {
          const categoriaFiltrada = {
              ...categoria,
              productDTOList: productosFiltrados
          };
          categoriasFiltradas.push(categoriaFiltrada);
      }
  });

  console.log("filtrado:");
  console.log(categoriasFiltradas);
  return categoriasFiltradas;
}

const search = (nameProd) => {
  if(nameProd != ''){
    setSearching(true);
    if(allProducts){
      setSearchCategoriesWithProducts(filtrarCategoriasPorProducto(nameProd));
    } else {
      const productsFilters = productsForCategory.filter(product =>
        product.name.toLowerCase().includes(nameProd.toLowerCase()));
      setSearchProducts(productsFilters);
    }
  }else{
    setSearching(false);
    setSearchProducts('');
    setSearchCategoriesWithProducts('');
  }
}

function formatPrice(price) {
  if (!price) return ''; // Manejar caso de valor vacío
  const parts = price.toString().split('.'); // Separar parte entera de la decimal
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agregar separador de miles
  return parts.join(','); // Unir las partes con coma como separador decimal
}

  const addProdPedido = (newProd) => {
      let productsPedido = JSON.parse(localStorage.getItem("productsPedido"));
      const notExistProduct = productsPedido.findIndex(product => product.id === newProd.id) == -1;
      if(notExistProduct){
        let newProduct = {
          id: newProd.id,
          name: newProd.name,
          description: newProd.description,
          price: newProd.price,
          cant: 1
        }
        productsPedido.push(newProduct);
      }else{
        const indiceProducto = productsPedido.findIndex(product => product.id === newProd.id);
        productsPedido[indiceProducto].cant += 1;
      }
      localStorage.setItem("productsPedido", JSON.stringify(productsPedido));
      setDataProductsPedidoModal(JSON.parse(localStorage.getItem("productsPedido")));
      document.getElementById('cantEnPedido').value = document.getElementById('cantEnPedido').value + 1;
  }

  const minusProdPedido = (idProduct) => {
    if(localStorage.getItem("productsPedido") != ''){
      let productsPedido = JSON.parse(localStorage.getItem("productsPedido"));
      const notExistProduct = productsPedido.findIndex(product => product.id === idProduct) == -1;
      if(!notExistProduct){
        const indiceProducto = productsPedido.findIndex(product => product.id === idProduct);
        if(productsPedido[indiceProducto].cant > 1){
          productsPedido[indiceProducto].cant -= 1;
        }else{
          productsPedido = productsPedido.filter(producto => producto.id !== idProduct);
        }
        console.log("guardar");
        localStorage.setItem("productsPedido", JSON.stringify(productsPedido));
        setDataProductsPedidoModal(JSON.parse(localStorage.getItem("productsPedido")));
        document.getElementById('cantEnPedido').value = document.getElementById('cantEnPedido').value - 1;
      }
    }
  }

  const deleteProductPedido = (idProduct) => {
    if(localStorage.getItem("productsPedido") != ''){
      let productsPedido = JSON.parse(localStorage.getItem("productsPedido"));
      productsPedido = productsPedido.filter(producto => producto.id !== idProduct);
      localStorage.setItem("productsPedido", JSON.stringify(productsPedido));
      setDataProductsPedidoModal(JSON.parse(localStorage.getItem("productsPedido")));
    }
  }

  const contProd = (idProduct) => {
    if(localStorage.getItem("productsPedido") != ''){
      let productsPedido = JSON.parse(localStorage.getItem("productsPedido"));
      if(productsPedido != null){
        const notExistProduct = productsPedido.findIndex(product => product.id === idProduct) == -1;
        if(notExistProduct){
          return "0";
        }
        const indiceProducto = productsPedido.findIndex(product => product.id === idProduct);
        return productsPedido[indiceProducto].cant.toString();
      }
      return "0";
    }
    return "0";
  }

  const calcularTotal = (productos) => {
    let total = 0;
  
    for (let i = 0; i < productos.length; i++) {
      const producto = productos[i];
      const subtotal = producto.price * producto.cant;
      total += subtotal;
    }
  
    return total;
  }

  const cleanPedidoBorrador = () => {
    Swal.fire({
      title: '¿Estás seguro de borrar el pedido?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Borrar',
      confirmButtonColor: colorshop,
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        cleanPedido();
      } else {
        const botonCarrito = document.getElementById('btnCarrito');
        botonCarrito.click();
      }
    });
  }

  const cleanPedido = () => {
    document.getElementById('cantEnPedido').value = "0";
    const listadoVacio = [];
    const listadoVacioJSON = JSON.stringify(listadoVacio);
    localStorage.setItem("productsPedido", listadoVacioJSON);
    window.location.reload();
  }

  const sendPedido = () => {
    if ((nameAndSuername == '' || nameAndSuername == null) || (isDelivery && ( (streetAddress == '' || streetAddress == null) || (numberAddress == '' || numberAddress == null) ))) {

      if (nameAndSuername == '' || nameAndSuername == null) {
        let fieldRequiredElement = document.querySelector('.errorNameAndSurname');
        fieldRequiredElement.style.display = 'block';
        let inputElement = document.getElementById('nameAndSurname');
        inputElement.style.border = '1px solid #ff1717';
      }
      if(isDelivery){
        if (streetAddress == '' || streetAddress == null) {
          let fieldRequiredElement = document.querySelector('.errorStreetAddress');
          fieldRequiredElement.style.display = 'block';
          let inputElement = document.getElementById('streetAddress');
          inputElement.style.border = '1px solid #ff1717';
        }
        if (numberAddress == '' || numberAddress == null) {
          let fieldRequiredElement = document.querySelector('.errorNumberAddress');
          fieldRequiredElement.style.display = 'block';
          let inputElement = document.getElementById('numberAddress');
          inputElement.style.border = '1px solid #ff1717';
        }
      }
    } else {
      Swal.fire({
        title: '¿Enviar pedido?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Enviar',
        confirmButtonColor: colorshop,
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          let orderNumber = Date.now();
          sendOrder(orderNumber);
        }
      });
    }    
  }

  const sendOrder = (orderNumber) => {

    let order = {
      id: null,
      orderNumber: orderNumber,
      ownerOrder: nameAndSuername,
      totalAmount: 0,
      tableId: 1, //setear numero de mesa que selecciono el usuario
      orderType: OrderType.MOSTRADOR.value,
      sendOrderType: (isDelivery && getValueByCode("isDelivery") == "true") ? SendOrderType.A_DOMICILIO.value : SendOrderType.RETIRO_LOCAL.value,
      address: streetAddress,
      numberAdress: numberAddress,
      floor: floorAddress,
      department: deptoAddress,
      provinceId: 20, //ver como mando el id de la provincia
      orderDetails: [],
      orderObservation: observationsPedido,
      deliveryObservation: observationsAddress,
    }

    let products = JSON.parse(localStorage.getItem("productsPedido"));
    products.forEach((product) => {
      order.totalAmount += (product.cant * product.price);
      order.orderDetails.push(createOrderDetail(product));
    });

    order.totalAmount = order.totalAmount.toFixed(2);
    
    axios.post(process.env.REACT_APP_API_ORDER_ROUTE, order, {
      withCredentials: true
    })
    .then(response => {
      Swal.fire({
        title: 'Pedido realizado con éxito',
        icon: 'success',
        showConfirmButton: false,
        timer: 2000,  // El SweetAlert se cerrará automáticamente después de 2 segundos
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      }).then(() => {
          cleanPedido();
      });
      })
      .catch(error => {
        console.log(error);
        console.log(error.response.data.errorMsg);
        
        Swal.fire({
          title: 'Error con el pedido:',
          text: error.response.data.errorMsg,
          icon: 'error',
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
        });
      });
  }

  const createOrderDetail = (product) => {
    return {
      id: null,
      orderId: null,
      productId: product.id,
      quantity: product.cant,
      unitaryPrice: Number(product.price),
      subTotal: Number(product.cant * product.price)
    };
  }

  const cleanErrorNameAndSurname = () => {
    if (nameAndSuername != '' && nameAndSuername != null) {
      let fieldRequiredElement = document.querySelector('.errorNameAndSurname');
      fieldRequiredElement.style.display = 'none';
      let inputElement = document.getElementById('nameAndSurname');
      inputElement.style.border = '';
    }
  }

  const cleanErrorStateAddress = () => {
    if (stateAddress != '' && stateAddress != null) {
      let fieldRequiredElement = document.querySelector('.errorStateAddress');
      fieldRequiredElement.style.display = 'none';
      let inputElement = document.getElementById('stateAddress');
      inputElement.style.border = '';
    }
  }

  const cleanErrorStreetAddress = () => {
    if (streetAddress != '' && streetAddress != null) {
      let fieldRequiredElement = document.querySelector('.errorStreetAddress');
      fieldRequiredElement.style.display = 'none';
      let inputElement = document.getElementById('streetAddress');
      inputElement.style.border = '';
    }
  }

  const cleanErrorNumberAddress = () => {
    if (numberAddress != '' && numberAddress != null) {
      let fieldRequiredElement = document.querySelector('.errorNumberAddress');
      fieldRequiredElement.style.display = 'none';
      let inputElement = document.getElementById('numberAddress');
      inputElement.style.border = '';
    }
  }

  const cleanErrorSendPedido = () => {
    let fieldRequiredElement = document.querySelector('.errorNameAndSurname');
    if (fieldRequiredElement) {
        fieldRequiredElement.style.display = 'none';
    }
    
    let inputElement = document.getElementById('nameAndSurname');
    if (inputElement) {
        inputElement.style.border = '';
    }

    setNameAndSuername('');

    if (isDelivery) {
        fieldRequiredElement = document.querySelector('.errorAddressPedido');
        if (fieldRequiredElement) {
            fieldRequiredElement.style.display = 'none';
        }
        
        inputElement = document.getElementById('addressPedido');
        if (inputElement) {
            inputElement.style.border = '';
        }
    }
    setStateAddress('');
    setStreetAddress('');
    setNumberAddress('');
    setFloorAddress('');
    setDeptoAddress('');
    setObservationsAddress('')
    setIsDelivery(false);
}

  function scrollLeft() {
    document.querySelector('.categories-inner-container').scrollBy({
      left: -200,
      behavior: 'smooth'
    });
  }

  function scrollRight() {
    document.querySelector('.categories-inner-container').scrollBy({
      left: 200,
      behavior: 'smooth'
    });
  }


    return (
      <div className="carta-body-mostrador">
        <div className="categories-outer-container">
          <button className="scroll-button left" onClick={scrollLeft}><IoIosArrowDropleftCircle size={30}/></button>
          <div className="categories-inner-container">
            <div className="categories-mostrador">
              <div className="category" style={{marginLeft: '2%'}}>
                <button className={`round-button ${allProducts ? 'selectedCategory' : ''}`} onClick={() => {fillAllProducts(); setAllProducts(true); setNameCategorySelected("Todos los productos");}} style={{backgroundColor: colorshop}}>
                  <img src={iconoCubiertos} alt="Icono" />
                </button>
                <div className="category-name">Todos los productos</div>
              </div>
              {categories.map(category => (
                  <div className="category" key={category.id}>
                    <button className={`round-button ${category.name === nameCategorySelected ? 'selectedCategory' : ''}`} onClick={() => {fillProductsByIdCategorySelected(category.id); setNameCategorySelected(category.name); setAllProducts(false)}} style={{backgroundColor: colorshop}}>
                      <img src={iconoCubiertos} alt="Icono" />
                    </button>
                    <div className="category-name">{category.name}</div>
                  </div>
              ))}
            </div>
          </div>
          <button className="scroll-button right" onClick={scrollRight}><IoIosArrowDroprightCircle size={30}/></button>
        </div>


        <div className='margin5'>
            <div className="searchProduct">
              <div className="search">
                <input type="text" id='nameProdSearch' className="searchProdCarta" placeholder="Buscar producto" style={{width : "100%"}} onChange={(e) => search(e.target.value)}/>
                <Search className="btnSearchCarta" size="35"/>
              </div>
            </div>

            <div className="products" style={{minHeight: '40vh'}}>
              {allProducts ? (
                (searching) ? (
                  <>
                    <h6 className='titleCategory'>{allProductsText}</h6>
                    <br/>
                    {searchCategoriesWithProducts.map(category => (
                      <>
                        <h6 className='titleCategory'>{category.name}</h6>
                        {category.productDTOList.map(product => (
                          <div className='product'>
                            <p className='nameProd'>{product.name}</p>
                            <p className='descProd'>{product.description}</p>
                              <div className='divPriceAndBotonera'>
                                <p className='priceProd'>${formatPrice(product.price)}</p>
                                    <div className='divBotonera'>
                                      <button className='btn'><SubtractAlt size={25} onClick={() => minusProdPedido(product.id)}/></button>
                                      <p id='cantEnPedido' style={{margin: '0'}}>{contProd(product.id)}</p>
                                      <button className='btn'><AddAlt size={25} style={{marginLeft: '5%'}} onClick={() => addProdPedido(product)}/></button>
                                    </div>
                              </div>
                          </div>
                        ))}
                        <br/>
                      </>
                    ))}
                    
                  </>
                ) : (
                  <>
                  <h6 className='titleCategory'>{allProductsText}</h6>
                  <br/>
                  {categories.map(category => (
                    <>
                      <h6 className='titleCategory'>{category.name}</h6>
                      {category.productDTOList.map(product => (
                        <div className='product'>
                          <p className='nameProd'>{product.name}</p>
                          <p className='descProd'>{product.description}</p>
                            <div className='divPriceAndBotonera'>
                              <p className='priceProd'>${formatPrice(product.price)}</p>
                                  <div className='divBotonera'>
                                    <button className='btn'><SubtractAlt size={25} onClick={() => minusProdPedido(product.id)}/></button>
                                    <p id='cantEnPedido' style={{margin: '0'}}>{contProd(product.id)}</p>
                                    <button className='btn'><AddAlt size={25} style={{marginLeft: '5%'}} onClick={() => addProdPedido(product)}/></button>
                                  </div>
                            </div>
                        </div>
                      ))}
                      <br/>
                    </>
                  ))}
                  
                </>
                )
                
              ) : (
                <>
                <h6 className='titleCategory'>{nameCategorySelected}</h6>
                { (searching) ? (
                  searchProducts.map(product => (
                    <div className='product'>
                          <p className='nameProd'>{product.name}</p>
                          <p className='descProd'>{product.description}</p>

                            <div className='divPriceAndBotonera'>
                              <p className='priceProd'>${formatPrice(product.price)}</p>
                              <div className='divBotonera'>
                                <button className='btn'><SubtractAlt size={25} onClick={() => minusProdPedido(product.id)}/></button>
                                <p id='cantEnPedido' style={{margin: '0'}}>{contProd(product.id)}</p>
                                <button className='btn'><AddAlt size={25} style={{marginLeft: '5%'}} onClick={() => addProdPedido(product)}/></button>
                              </div>
                            </div>

                        </div>
                  ))
                ) : (
                  productsForCategory.map(product => (
                    <div className='product'>
                    <p className='nameProd'>{product.name}</p>
                    <p className='descProd'>{product.description}</p>

                            <div className='divPriceAndBotonera'>
                              <p className='priceProd'>${formatPrice(product.price)}</p>
                              <div className='divBotonera'>
                                <button className='btn'><SubtractAlt size={25} onClick={() => minusProdPedido(product.id)}/></button>
                                <p id='cantEnPedido' style={{margin: '0'}}>{contProd(product.id)}</p>
                                <button className='btn'><AddAlt size={25} style={{marginLeft: '5%'}} onClick={() => addProdPedido(product)}/></button>
                              </div>
                            </div>

                  </div>
                )))
                }
                </>
              )}
            </div>
          </div>

          {(localStorage.getItem("productsPedido") != '[]') && (
            <button id='btnCarrito' data-bs-toggle="modal" data-bs-target="#modalProductsPedido" className="round-button-pedido-mostrador"
              onClick={() => {setDataProductsPedidoModal(JSON.parse(localStorage.getItem("productsPedido")))}}>
              <ShoppingCart size={30}/>
            </button>
          )}

          <div class="modal fade modal-lg" id="modalProductsPedido" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalProductsPedidoLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header modalPedido">
                  <h1 class="modal-title fs-5" id="modalProductsPedidoLabel" 
                  className="titleModalPedido" style={{marginTop: '4%', marginLeft: '4%'}}>Pedido</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btnCloseCarrito"></button>
                </div>
                <div class="modal-body modalPedido">

                    {dataProductsPedidoModal.map(product => (
                      <div className='productCarrito'>
                        <p className='nameProdPedido'>{product.name}</p>

                        <div className='divPriceAndBotoneraPedido'>
                          <p className='priceProdPedido'>${formatPrice(product.price)}</p>
                            <div className='divBotonera'>
                              <button className='btn' style={{color: 'red'}}><TrashCan size={22} onClick={() => deleteProductPedido(product.id)}/></button>
                              <button className='btn'><SubtractAlt size={22} onClick={() => minusProdPedido(product.id)}/></button>
                              <p id='cantEnPedido' style={{margin: '0'}}>{contProd(product.id)}</p>
                              <button className='btn'><AddAlt size={22} style={{marginLeft: '5%'}} onClick={() => addProdPedido(product)}/></button>
                            </div>
                        </div>
                        <p className='priceProdPedidoSubtotal'>Subtotal: ${formatPrice((product.price * product.cant).toFixed(2))}</p>
                      </div>
                    ))}
                    <br/>
                    <p className='priceProdTotalPedido'>TOTAL: ${formatPrice(calcularTotal(dataProductsPedidoModal).toFixed(2))}</p>
                    <br/>
                    <div style={{marginLeft: '5%'}}>
                      <label for="observations" class="col-form-label">Obvservaciones del pedido:</label>
                      <textarea rows="3" className="form-control observationsPedido" id="observationsPedido" 
                        alue={observationsPedido} onChange={(e) => {setObservationsPedido(e.target.value);}}/>
                    </div>
                </div>
                <div class="modal-footer modalPedido">
                  <button id="btnCloseModalAdd" type="button" class="btn btn-dark" data-bs-dismiss="modal" onClick={() => cleanPedidoBorrador()}>Borrar pedido</button>
                  <button id="btnSendPedidoForWhatsapp" type="button" class="btn" 
                    data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#modalDataForPedido"
                    style={{backgroundColor: colorshop, color: '#ffffff' }}
                    disabled={dataProductsPedidoModal.length == 0 ? true : false}>Siguiente</button>
                </div>
              </div>
            </div>
          </div>

          <div class="modal fade modal-lg" id="modalDataForPedido" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalDataForPedidoLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header modalPedido">
                  <h1 class="modal-title fs-5" id="modalDataForPedidoLabel" className="titleModalDatosPedido">Datos del cliente</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" data-bs-toggle="modal" data-bs-target="#modalProductsPedido" onClick={() => cleanErrorSendPedido()}></button>
                  <button id="btnCloseModalPersona" hidden={true} type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => cleanErrorSendPedido()}></button>
                </div>
                <div class="modal-body modalPedido">

                <label for="nameAndSurname" class="col-form-label">Nombre y apellido:</label>
                <input type="text" className="form-control nameAndSuername" id="nameAndSurname" 
                  value={nameAndSuername} onChange={(e) => {setNameAndSuername(e.target.value); cleanErrorNameAndSurname();}}
                  onBlur={() => cleanErrorNameAndSurname()}/>
                <div className='errorNameAndSurname' id='errorNameAndSurname'>Campo obligatorio</div>

                <label for="isDelivery" class="col-form-label">Envío a domicilio:</label>
                  {(getValueByCode("isDelivery") == "true") ? <>
                    <div className="form-check">
                      <input
                          className="form-check-input"
                          type="radio"
                          id="isDeliveryTrue"
                          name="isDelivery"
                          value="true"
                          checked={isDelivery === true}
                          onChange={() => setIsDelivery(true)}
                      />
                      <label className="form-check-label" htmlFor="isDeliveryTrue">
                        Sí
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                          className="form-check-input"
                          type="radio"
                          id="isDeliveryFalse"
                          name="isDelivery"
                          value="false"
                          checked={isDelivery === false}
                          onChange={() => {setIsDelivery(false); setStateAddress(''); setStreetAddress(''); setNumberAddress(''); setFloorAddress(''); setDeptoAddress(''); setObservationsAddress('')}}
                      />
                      <label className="form-check-label" htmlFor="isDeliveryFalse">
                        No
                      </label>
                    </div>
                  </> : <>
                    <p style={{color: '#CB3234'}}>Por el momento no realizamos envíos, ¡retiralo en nuestro local!</p>
                    <div className="form-check">
                      <input
                          className="form-check-input"
                          type="radio"
                          id="isDeliveryTrue"
                          name="isDelivery"
                          value="false"
                          disabled={true}
                      />
                      <label className="form-check-label" htmlFor="isDeliveryTrue">
                        Sí
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                          className="form-check-input"
                          type="radio"
                          id="isDeliveryFalse"
                          name="isDelivery"
                          value="true"
                          disabled={true}
                      />
                      <label className="form-check-label" htmlFor="isDeliveryFalse">
                        No
                      </label>
                    </div>
                  </>}


                {isDelivery && 
                  <>
                    <br/>
                    <p style={{color: "#959595"}}>Valor del envío: ${formatPrice(getValueByCode("priceDeliveryRange1"))}</p>
                    <label for="addressPedido" className="col-form-label">Dirección de entrega:</label>
                    <br/>

                    {/* 
                    <label for="stateAddress" className="col-form-label">Provincia:</label>
                    <select
                      className="form-control stateAddress" id="stateAddress"
                      value={stateAddress}
                      onChange={(e) => {setStateAddress(e.target.value); cleanErrorStateAddress();}}
                      onBlur={() => cleanErrorStateAddress()}
                    >
                      <option value=''>Seleccione provincia</option>
                      {provinces
                            .map(province => (
                              <option value={province.id}>{province.name}</option>
                          ))}
                    </select>
                    <div className='errorStateAddress' id='errorStateAddress'>Campo obligatorio</div>
                    */}

                    <div className='row'>
                      <div className='col-8'>
                        <label for="streetAddress" class="col-form-label">Calle:</label>
                        <input type="text" className="form-control streetAddress" id="streetAddress" 
                          value={getValueByCode("isDelivery") == "false" ? "" : streetAddress} onChange={(e) => {setStreetAddress(e.target.value); cleanErrorStreetAddress();}}
                          onBlur={() => cleanErrorStreetAddress()} disabled={getValueByCode("isDelivery") == "false"}/>
                        <div className='errorStreetAddress' id='errorStreetAddress'>Campo obligatorio</div>
                      </div>
                      
                      <div className='col'>
                        <label for="numberDirection" class="col-form-label">Numero:</label>
                        <input type="number" className="form-control numberAddress" id="numberAddress" 
                          value={getValueByCode("isDelivery") == "false" ? "" : numberAddress} onChange={(e) => {setNumberAddress(e.target.value); cleanErrorNumberAddress();}}
                          onBlur={() => cleanErrorNumberAddress()} disabled={getValueByCode("isDelivery") == "false"}/>
                        <div className='errorNumberAddress' id='errorNumberAddress'>Campo obligatorio</div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col'>
                        <label for="piso" class="col-form-label">Piso:</label>
                        <input type="number" className="form-control piso" id="piso" 
                          value={getValueByCode("isDelivery") == "false" ? "" : floorAddress} onChange={(e) => {setFloorAddress(e.target.value);}} disabled={getValueByCode("isDelivery") == "false"}/>
                      </div>
                      
                      <div className='col'>
                        <label for="depto" class="col-form-label">Depto:</label>
                        <input type="text" className="form-control depto" id="depto" 
                          value={getValueByCode("isDelivery") == "false" ? "" : deptoAddress} onChange={(e) => {setDeptoAddress(e.target.value);}} disabled={getValueByCode("isDelivery") == "false"}/>
                      </div>
                    </div>
                    <br/>

                    <label for="observations" class="col-form-label">Indicacion adicional del destino:</label>
                    <textarea placeholder='Casa con rejas negras, sin timbre' rows="3" className="form-control observations" id="observations" 
                      value={getValueByCode("isDelivery") == "false" ? "" : observationsAddress} onChange={(e) => {setObservationsAddress(e.target.value);}} disabled={getValueByCode("isDelivery") == "false"}/>
                  </>
                }
              </div>
                
                <div class="modal-footer modalPedido">
                  <button id="btnCloseModalSendPedido" type="button" class="btn btn-secondary" 
                    data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#modalProductsPedido" onClick={() => cleanErrorSendPedido()}>Volver</button>
                  <button type="button" className="btn"
                    style={{backgroundColor: colorshop, color: '#ffffff' }}
                    onClick={() => sendPedido()}>Finalizar pedido</button>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }