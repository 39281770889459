import {convertToBase64} from "./ConversorService";
import {postWithCredentials, putWithCredentials} from "./AxiosService";
import Swal from "sweetalert2";

export const save = async(formData, handleCloseModal, setIsEdit) => {
    if (formData.image && formData.image instanceof File) {
        try {
            const base64Image = await convertToBase64(formData.image);
            const updatedFormData = {
                ...formData,
                image: base64Image  // Reemplaza la imagen con su versión en Base64
            };
            postWithCredentials(process.env.REACT_APP_API_SHOP_ROUTE, updatedFormData)
                .then(() => {
                    Swal.fire({
                        title: 'Local creado con éxito',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    handleCloseModal();
                    setIsEdit(true);
                })
                .catch(error => {
                    console.error('Error posting data:', error);
                });
        } catch (error) {
            console.error('Error converting image to Base64:', error);
        }
    } else {
        // Maneja el caso de que no haya imagen o el formato no sea adecuado
        postWithCredentials(process.env.REACT_APP_API_SHOP_ROUTE, formData)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.error('Error posting data:', error);
            });
    }
}


export const update = async(formData, handleCloseModal, setShowEditView) => {
    if (formData.image && formData.image instanceof File) {
        try {
            const base64Image = await convertToBase64(formData.image);
            const updatedFormData = {
                ...formData,
                image: base64Image  // Reemplaza la imagen con su versión en Base64
            };
            putWithCredentials(process.env.REACT_APP_API_SHOP_ROUTE + '/1', updatedFormData)
                .then(() => {
                    handleCloseModal();
                    setShowEditView(true);
                })
                .catch(error => {
                    console.error('Error posting data:', error);
                });
        } catch (error) {
            console.error('Error converting image to Base64:', error);
        }
    } else {
        // Maneja el caso de que no haya imagen o el formato no sea adecuado
        putWithCredentials(process.env.REACT_APP_API_SHOP_ROUTE + '/1', formData)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.error('Error posting data:', error);
            });
    }
}
