import React, {useState} from 'react'
import {Button, Col, Container, Form, InputGroup, Modal, Row} from "react-bootstrap";
import * as shopService from "../../services/ShopService";

const ShopForm = ({ isEdit, setIsEdit, shopDTO }) => {

    console.log(shopDTO);

    // Constantes útiles
    const initialState = {
        name: '',
        businessName: '',
        phone: '',
        address: '',
        location: '',
        state: '',
        image: null,
        imageUrl: '',
        principalColorShop: '#ffffff'
    };

    // Estados
    const [formData, setFormData] = useState(shopDTO);
    const [validated, setValidated] = useState(false);
    const [showModal, setShowModal] = useState(false);

    // Funciones
    const handleShowModal = () => {
        setFormData(shopDTO)
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
        if (formData.imageUrl) {
            URL.revokeObjectURL(formData.imageUrl);
        }
        setFormData(initialState);
        setValidated(false);
    };

    const handleChangeInput = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            const file = files[0];
            if (file) {
                const imageUrl = URL.createObjectURL(file);
                setFormData(prev => ({
                    ...prev,
                    image: file,
                    imageUrl: imageUrl
                }));
            }
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleSubmitForm = async (event) => {

        event.preventDefault();
        if (event.currentTarget.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;  // Detiene la función si el formulario no es válido
        }

        if(isEdit) {
            await shopService.update(formData);
        } else {
            await shopService.save(formData, handleCloseModal, setIsEdit);
        }

    };

    return (
        <>
            {isEdit ? (
                <Container>
                    <Row xs={1} md={2}>
                        <Col>
                            <Form.Label htmlFor="basic-url">Nombre</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control id="basic-url" aria-describedby="basic-addon3" disabled value={shopDTO.name}/>
                            </InputGroup>
                        </Col>
                        <Col>
                            <Form.Label htmlFor="basic-url">Razón Social</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control id="basic-url" aria-describedby="basic-addon3" disabled value={shopDTO.businessName}/>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row xs={1} md={2}>
                        <Col>
                            <Form.Label htmlFor="basic-url">Teléfono</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control id="basic-url" aria-describedby="basic-addon3" disabled value={shopDTO.phone}/>
                            </InputGroup>
                        </Col>
                        <Col>
                            <Form.Label htmlFor="basic-url">Dirección</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control id="basic-url" aria-describedby="basic-addon3" disabled value={shopDTO.address}/>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row xs={1} md={2}>
                        <Col>
                            <Form.Label htmlFor="basic-url">Provincia</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control id="basic-url" aria-describedby="basic-addon3" disabled value={shopDTO.state}/>
                            </InputGroup>
                        </Col>
                        <Col>
                            <Form.Label htmlFor="basic-url">Ciudad</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control id="basic-url" aria-describedby="basic-addon3" disabled value={shopDTO.location}/>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row xs={1} md={2}>
                        <Col>
                            <Form.Label htmlFor="basic-url">Color</Form.Label>
                            <Container className="p-0" fluid>
                                <Row>
                                    <Col>
                                        <InputGroup className="mb-3">
                                            <Form.Control id="basic-url" aria-describedby="basic-addon3" disabled value={shopDTO.principalColorShop}/>
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <InputGroup className="mb-3">
                                            <Form.Control type="color" id="basic-url" aria-describedby="basic-addon3" disabled value={shopDTO.principalColorShop}/>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Container>

                        </Col>
                    </Row>
                    <Row xs={1} md={2}>
                        <Col>
                            <Form.Label htmlFor="basic-url">Banner</Form.Label>
                            <InputGroup className="mb-3">
                                <img className="marco" src={"data:image/jpeg;base64," + shopDTO.image} alt="Banner Preview"
                                     style={{width: '100%'}}/>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row xs={1} md={2}>
                        <Col>
                            <Button className="mb-3" variant="primary" onClick={handleShowModal}>Editar Local</Button>
                        </Col>
                    </Row>
                </Container>
            ) : (

                <Container className="mt-5">
                    <Row>
                        <Col className="text-alig-center">
                            <h2 className='textNoShop'>¡El primer paso para digitalizar tu negocio es completar con los
                                datos de tu local!</h2>
                            <Button className="mt-3" variant="primary" onClick={handleShowModal}>Ingresar datos</Button>
                        </Col>
                    </Row>
                </Container>


            )}

            <Modal size="xl" show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Completa con los datos de tu local</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id='shopCreateForm' noValidate validated={validated} onSubmit={handleSubmitForm}>

                        <Row className="mb-md-3">
                            <Form.Group className="mb-3 mb-md-0" as={Col} md={6} controlId="validationCustom1">
                                <Form.Label>Nombre:</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="name"
                                    onChange={handleChangeInput}
                                    value={formData.name}
                                >
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>Campo requerido.</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3 mb-md-0" as={Col} md={6} controlId="validationCustom2">
                                <Form.Label>Razón Social:</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="businessName"
                                    onChange={handleChangeInput}
                                    value={formData.businessName}
                                >
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>Campo requerido.</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-md-3">
                            <Form.Group className="mb-3 mb-md-0" as={Col} md={6} controlId="validationCustom3">
                                <Form.Label>Número de teléfono:</Form.Label>
                                <Form.Control
                                    required
                                    type="tel"
                                    name="phone"
                                    onChange={handleChangeInput}
                                    value={formData.phone}
                                >
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>Campo requerido.</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3 mb-md-0" as={Col} md={6} controlId="validationCustom4">
                                <Form.Label>Dirección:</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="address"
                                    onChange={handleChangeInput}
                                    value={formData.address}
                                >
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>Campo requerido.</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-md-3">
                            <Form.Group className="mb-3 mb-md-0" as={Col} md={6} controlId="validationCustom5">
                                <Form.Label>Provincia:</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="location"
                                    onChange={handleChangeInput}
                                    value={formData.location}
                                >
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>Campo requerido.</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3 mb-md-0" as={Col} md={6} controlId="validationCustom6">
                                <Form.Label>Ciudad:</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="state"
                                    onChange={handleChangeInput}
                                    value={formData.state}
                                >
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>Campo requerido.</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-md-3">
                            <Form.Group className="mb-3 mb-md-0" as={Col} md={6} controlId="validationCustom6">
                                <Form.Label>Color:</Form.Label>
                                <Form.Control
                                    required
                                    type="color"
                                    name="principalColorShop"
                                    onChange={handleChangeInput}
                                    value={formData.principalColorShop}
                                >
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>Campo requerido.</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-md-3">
                            <Form.Group className="mb-3 mb-md-0" as={Col} md={12} controlId="validationCustom6">
                                <Form.Label>Banner:</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="image"
                                    onChange={handleChangeInput}
                                    accept="image/"
                                >
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>Campo requerido.</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-md-3">
                            {formData.imageUrl && (
                                <Col>
                                    <img className="marco" src={formData.imageUrl} alt="Banner Preview" style={{ width: '100%', marginTop: '10px' }} />
                                </Col>
                            )}
                        </Row>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Cerrar</Button>
                    <Button type="submit" variant="primary" form="shopCreateForm">Guardar</Button>
                </Modal.Footer>

            </Modal>

        </>
    )

}

export default ShopForm;