import * as axiosService from "./AxiosService";
import axios from "axios";

export const getShop = async() => {
    try {
        const response = await axiosService.getWithCredentialsSync(process.env.REACT_APP_API_SHOP_ROUTE + '/1')
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const updateShop = async(shopData) => {
    try {
        const response = await axiosService.putWithCredentialsSync(process.env.REACT_APP_API_SHOP_ROUTE + '/1', shopData);
        return response.data;
    } catch (error) {
        throw error;
    }
}
