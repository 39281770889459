import React, { useState, useEffect } from 'react';
import './Menu.css';
import { AiOutlineClose } from 'react-icons/ai';
import { IoHomeOutline, IoShareSocialOutline, IoStorefrontOutline, IoGridOutline, IoCartOutline } from "react-icons/io5";
import { HiOutlinePaintBrush } from "react-icons/hi2";
import { BsCartPlus } from "react-icons/bs";
import { FiMenu } from 'react-icons/fi';
import { PiClipboardText } from 'react-icons/pi';
import MenuItem from './MenuItem';
import MenuRechargeCartaButton from './MenuRechargeCartaButton';
import MenuLogoutButton from './MenuLogoutButton';

export default function Menu() {
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    // Función para verificar el tamaño de la pantalla y definir si es móvil o no
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Define tamaño de pantalla móvil como <= 768px
    };

    useEffect(() => {
        handleResize(); // Verifica al montar el componente
        window.addEventListener('resize', handleResize); // Agrega evento de escucha para redimensionar

        return () => {
            window.removeEventListener('resize', handleResize); // Limpia evento al desmontar componente
        };
    }, []);

    const toggleSidebar = () => setIsOpen(!isOpen);
    const closeSidebar = () => setIsOpen(false);

    return (
        <>
            {isMobile ?
                (
                    <div className={`menu-container ${isOpen ? 'open' : ''}`}>
                        {/* Botón de hamburguesa para abrir/cerrar el menú */}
                        <button className="menu-toggle-btn" onClick={toggleSidebar}>
                            {isOpen ? <AiOutlineClose/> : <FiMenu/>}
                        </button>

                        {/* Contenedor del menú que se despliega cuando isOpen es true */}
                        <nav className={`mobile-nav ${isOpen ? 'open' : 'closed'}`}>
                            <MenuItem isOpen={isOpen} path={"/home"} Icon={IoHomeOutline} text={"Inicio"} onClick={closeSidebar} />
                            <MenuItem isOpen={isOpen} path={"/shop"} Icon={IoStorefrontOutline} text={"Locales"} onClick={closeSidebar} />
                            <MenuItem isOpen={isOpen} path={"/categories"} Icon={IoGridOutline} text={"Categorías"} onClick={closeSidebar} />
                            <MenuItem isOpen={isOpen} path={"/misProductos"} Icon={PiClipboardText} text={"Productos"} onClick={closeSidebar} />
                            <MenuItem isOpen={isOpen} path={"/personalizacion"} Icon={HiOutlinePaintBrush} text={"Diseño"} onClick={closeSidebar} />
                            <MenuItem isOpen={isOpen} path={"/pedidoMostrador"} Icon={BsCartPlus} text={"Crear pedido"} onClick={closeSidebar} />
                            <MenuItem isOpen={isOpen} path={"/orders"} Icon={IoCartOutline} text={"Pedidos"} onClick={closeSidebar} />
                            <MenuRechargeCartaButton isOpen={isOpen}/>
                            {isOpen && <MenuLogoutButton isOpen={isOpen} isMobile={true}/>}
                        </nav>

                    </div>
                )
                :
                (
                    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
                        <button onClick={toggleSidebar} className="toggle-btn">
                            {isOpen ? <AiOutlineClose/> : <FiMenu/>}
                        </button>

                        <nav className="menu-nav">
                            {/* Lista de items del menú */}
                            <MenuItem isOpen={isOpen} path={"/home"} Icon={IoHomeOutline} text={"Inicio"}/>
                            <MenuItem isOpen={isOpen} path={"/shop"} Icon={IoStorefrontOutline} text={"Locales"}/>
                            <MenuItem isOpen={isOpen} path={"/categories"} Icon={IoGridOutline} text={"Categorías"}/>
                            <MenuItem isOpen={isOpen} path={"/misProductos"} Icon={PiClipboardText} text={"Productos"}/>
                            <MenuItem isOpen={isOpen} path={"/personalizacion"} Icon={HiOutlinePaintBrush} text={"Diseño"}/>
                            <MenuItem isOpen={isOpen} path={"/pedidoMostrador"} Icon={BsCartPlus}
                                      text={"Crear pedido"}/>
                            <MenuItem isOpen={isOpen} path={"/orders"} Icon={IoCartOutline} text={"Pedidos"}/>
                            <MenuRechargeCartaButton isOpen={isOpen}/>
                        </nav>

                        <MenuLogoutButton isOpen={isOpen} isMobile={false}/>
                    </div>
                )
            }
        </>
    );
}
