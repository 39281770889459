import { Link, useLocation } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

const MenuItem = ({path, isOpen, Icon, text}) => {

    const location = useLocation();

    return (
        <div className= {location.pathname === path ? 'menu-item-container active-link' : 'menu-item-container'}>
            <Nav.Link as={Link} to={path} >
                <div>
                    <Icon className="icon" />
                </div>
                <div className='span-container'>
                    <span className={isOpen ? "text" : "text hidden"}>{text}</span>
                </div>
            </Nav.Link>
        </div>
    )

}

export default MenuItem;