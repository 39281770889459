import ShopOnlyReadInput from './ShopOnlyReadInput';

const ShopGenericPanel = ({title, data}) => {

    return (
        
        <div className='shopGenericInfoPanelContainer'>
            
            <div className='shopGenericInfoPanelTitleContainer'>
                <h1 className='shopGenericInfoPanelTitle'>{title}</h1>
            </div>

            <div className='shopGenericInfoPanelInputsContainer'>
                {
                    Object.entries(data).map(([key, value]) => {
                        return <ShopOnlyReadInput field={key} labelText={value.field} value={value.value} />
                    })
                }
            </div>

        </div>

    );

}

export default ShopGenericPanel;