import { useEffect } from 'react';
import Swal from 'sweetalert2';
import { useNavigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('authToken'); // Asumiendo que el token se guarda aquí

    useEffect(() => {
        if (!token) {
            Swal.fire({
                title: 'Sesión expirada'
            })
            navigate('/', { replace: true, state: { from: location } });
        }
    }, [token, navigate, location]);

    // Si hay token, renderiza el componente protegido
    return token ? children : null;
};

export default ProtectedRoute;