import React, {useState} from 'react';
import {PiInfo} from "react-icons/pi";
import Loading from "../components/Loading";
import axios from 'axios';
import Swal from 'sweetalert2';
import {Modal} from "react-bootstrap";
import { TbStatusChange } from "react-icons/tb";
import { PiNotepad } from "react-icons/pi";
import { FiEdit, FiEdit2  } from "react-icons/fi";
import {AddAlt, SubtractAlt} from "@carbon/icons-react";

const OrderStatusOption = ({ id, value, label, checked, onChange }) => (
    <div className="form-check">
      <input
          className="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id={id}
          value={value}
          checked={checked}
          onChange={onChange}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
);

const Order = ({order, getAllOrders, actualStatus}) => {

  const keyLocalStorage = 'authToken';

  const [isLoading, setIsLoading] = useState(true);

  const [orderDetails, setOrdersDetails] = useState([]);
  const [orderLogsStatus, setOrderLogsStatus] = useState([]);
  const [orderAddress, setOrdersAddress] = useState(null);

  const [reasonCancellation, setReasonCancellation] = useState("");

  const [showModalDetails, setShowModalDetails] = useState(false);
  const [showModalAddress, setShowModalAddress] = useState(false);
  const [showModalCancell, setShowModalCancell] = useState(false);
  const [showModalSetStatus, setShowModalSetStatus] = useState(false);
  const [showModalLogsStatus, setShowModalLogsStatus] = useState(false);
  const [showModalEditOrder, setShowModalEditOrder] = useState(false);
  const [showModalAddProduct, setShowModalAddProduct] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState('');
  const [observationChangeStatus, setObservationChangeStatus] = useState('');

  const [categories, setCategories] = useState([]);
  const [productsForCategory, setProductsForCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [quantityAddProduct, setQuantityAddProduct] = useState(1);
  const [products, setProducts] = useState([]);

  const [isDelivery, setIsDelivery] = useState(order.delivery);
  const [nameAndSurname, setNameAndSurname] = useState(order.ownerOrder);
  const [streetAddress, setStreetAddress] = useState('');
  const [numberAddress, setNumberAddress] = useState('');
  const [floorAddress, setFloorAddress] = useState('');
  const [deptoAddress, setDeptoAddress] = useState('');
  const [observationsAddress, setObservationsAddress] = useState('');
  const [observationsPedido, setObservationsPedido] = useState(order.orderObservation);

  const SendOrderType = {
    A_DOMICILIO: { label: "A domicilio", value: 1 },
    RETIRO_LOCAL: { label: "Retira en local", value: 2 }
  };
  const OrderType = {
    WHATSAP: { label: "WhatsApp", value: 1 },
    EN_MESA: { label: "En Mesa", value: 2 },
    DELIVERY: { label: "Delivery", value: 3 },
    A_DISTANCIA: { label: "A Distancia", value: 4 },
    MOSTRADOR: { label: "Mostrador", value: 5 }
  };
  const getOrderTypeValueByLabel = (label) => {
    for (const key in OrderType) {
      if (OrderType[key].label === label) {
        return OrderType[key].value;
      }
    }
    return null;
  };

  const handleCloseModal = () => {
    setShowModalDetails(false);
  };

  const getAllCategories = () => {
    axios.get(process.env.REACT_APP_API_CATEGORIES_PRODUCTS_ROUTE_CACHED, {
      withCredentials: true
    })
        .then(response => {
          setCategories(response.data);
          console.log(response.data)
        })
        .catch(error => {
          console.error(error)});
  }

  const findOrdersDetails = () => {
    axios.get(process.env.REACT_APP_API_ORDER_DETAILS_ROUTE + order.orderId, {
      withCredentials: true,
      headers: {
        Authorization: localStorage.getItem(keyLocalStorage),
      }
    })
    .then(response => {
        setOrdersDetails(response.data);
      })
      .catch(() => {
        alertError();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const findOrdersLogsStatus = () => {
    axios.get(process.env.REACT_APP_API_ORDER_LOGS_ROUTE + order.orderId, {
      withCredentials: true,
      headers: {
        Authorization: localStorage.getItem(keyLocalStorage),
      }
    })
        .then(response => {
          setOrderLogsStatus(response.data);
        })
        .catch((r) => {
          console.log(r.data)
          alertError();
        })
        .finally(() => {
          setIsLoading(false);
        });
  }

  const findAddressOrder = () => {
    axios.get(process.env.REACT_APP_API_ORDER_WHATSAPP_ADDRESS_ROUTE + order.orderId, {
      withCredentials: true,
      headers: {
        Authorization: localStorage.getItem(keyLocalStorage),
      }
    })
    .then(response => {
        setOrdersAddress(response.data);
        if (response.data != null) {
          setStreetAddress(response.data.adress);
          setDeptoAddress(response.data.departament);
          setNumberAddress(response.data.numberAdress);
          setFloorAddress(response.data.flor);
          setObservationsAddress(response.data.deliveryObservation);
        }
      })
      .catch(() => {
        alertError();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelOrder = () => {
    Swal.fire({
      title: '¿Estás seguro de cancelar el pedido?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      confirmButtonColor: 'rgb(246, 139, 127)',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.put(
            `${process.env.REACT_APP_API_ORDER_ROUTE}/${order.orderId}`+"/status",
            null,  // No se envía ningún cuerpo de solicitud
            {
              withCredentials: true,
              headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
              },
              params: {
                status: 'CANCELLED',
                observation: reasonCancellation,
              },
            }
        )
            .then(response => {
              Swal.fire({
                icon: 'success',
                title: '¡Se canceló correctamente!',
                timer: 1200,
                showConfirmButton: false,
              });
              setObservationChangeStatus("");
              setSelectedStatus('');
              setShowModalCancell(false);
              getAllOrders(actualStatus);
            })
            .catch(error => {
              alertError();
              console.error(error);
            })
            .finally(() => {
              setIsLoading(false);
            });
      }
    });
  }

  function alertError(){
    Swal.fire({
      title: 'Upsss',
      text: 'Ocurrió un error, vuelve a intentar',
      icon: 'error',
      timer: 1500,
      showConfirmButton: false,
    });
  }

  function formatPrice(price) {
    if (!price) return ''; // Manejar caso de valor vacío
    const parts = price.toString().split('.'); // Separar parte entera de la decimal
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agregar separador de miles
    return parts.join(','); // Unir las partes con coma como separador decimal
  }

  const getStatus = (status) => {
    switch (status){
      case "RECEIVED" : return "Recibido";
      case "IN_PREPARATION" : return "En preparacion";
      case "DELIVERY_IN_PROGRESS" : return "En camino";
      case "FINALIZED" : return "Finalizado";
      case "CANCELLED" : return "Cancelado";
    }
  }

  const renderStatusOptions = () => {
    const statusOptions = [];

    if (order.status === 'RECEIVED') {
      statusOptions.push(
          { id: 'IN_PREPARATION', value: 'IN_PREPARATION', label: 'En preparación' },
          { id: 'DELIVERY_IN_PROGRESS', value: 'DELIVERY_IN_PROGRESS', label: 'En camino' },
          { id: 'FINALIZED', value: 'FINALIZED', label: 'Finalizado' },
          { id: 'CANCELLED', value: 'CANCELLED', label: 'Cancelado' }
      );
    } else if (order.status === 'IN_PREPARATION') {
      statusOptions.push(
          { id: 'DELIVERY_IN_PROGRESS', value: 'DELIVERY_IN_PROGRESS', label: 'En camino' },
          { id: 'FINALIZED', value: 'FINALIZED', label: 'Finalizado' },
          { id: 'CANCELLED', value: 'CANCELLED', label: 'Cancelado' }
      );
    } else {
      statusOptions.push(
          { id: 'FINALIZED', value: 'FINALIZED', label: 'Finalizado' },
          { id: 'CANCELLED', value: 'CANCELLED', label: 'Cancelado' }
      );
    }

    return statusOptions.map((option) => (
        <React.Fragment key={option.id}>
          <OrderStatusOption
              id={option.id}
              value={option.value}
              label={option.label}
              checked={selectedStatus === option.value}
              onChange={(event) => setSelectedStatus(event.target.value)}
          />
          <br />
        </React.Fragment>
    ));
  };

  const setStatusOrder = () => {
    if (selectedStatus === 'CANCELLED') {
      Swal.fire({
        title: '¿Estás seguro de cancelar el pedido?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        confirmButtonColor: 'rgb(246, 139, 127)',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          axios.put(
              `${process.env.REACT_APP_API_ORDER_ROUTE}/${order.orderId}`+"/status",
              null,  // No se envía ningún cuerpo de solicitud
              {
                withCredentials: true,
                headers: {
                  Authorization: localStorage.getItem(keyLocalStorage),
                },
                params: {
                  status: selectedStatus,
                  observation: observationChangeStatus,
                },
              }
          )
              .then(response => {
                Swal.fire({
                  icon: 'success',
                  title: 'Listo',
                  timer: 800,
                  showConfirmButton: false,
                });
                setShowModalSetStatus(false);
                getAllOrders(actualStatus);
              })
              .catch(error => {
                alertError();
                console.error(error);
              })
              .finally(() => {
                setIsLoading(false);
                setSelectedStatus('');
                setObservationChangeStatus('');
              });
        }
      });
    } else {
      axios.put(
          `${process.env.REACT_APP_API_ORDER_ROUTE}/${order.orderId}`+"/status",
          null,  // No se envía ningún cuerpo de solicitud
          {
            withCredentials: true,
            headers: {
              Authorization: localStorage.getItem(keyLocalStorage),
            },
            params: {
              status: selectedStatus,
              observation: observationChangeStatus,
            },
          }
      )
          .then(response => {
            Swal.fire({
              icon: 'success',
              title: 'Listo',
              timer: 800,
              showConfirmButton: false,
            });
            setShowModalSetStatus(false);
            getAllOrders(actualStatus);
          })
          .catch(error => {
            alertError();
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
            setSelectedStatus('');
            setObservationChangeStatus('');
          });
    }

  };

  const contCantProdDetail = (detail) => {
    if (detail.quantity === 0) {
      setOrdersDetails(prevDetails => prevDetails.filter(d => d.id !== detail.id));
    }
    document.getElementById('cantEnPedido'+detail.id).innerText = detail.quantity;
    document.getElementById('subtotal'+detail.id).innerText = "$"+formatPrice(detail.unitaryPrice * detail.quantity);
    document.getElementById('total').innerText = "$"+formatPrice(orderDetails.reduce((acc, detail) => acc + (detail.quantity * detail.unitaryPrice), 0)+ (isDelivery ? 500 : 0));
  }

  const updateOrder = () => {
    console.log(order);
    let orderEdit = {
      id: order.orderId,
      orderNumber: order.orderNumber,
      ownerOrder: nameAndSurname,
      totalAmount: 0,
      tableId: 1, //setear numero de mesa que selecciono el usuario
      orderType: getOrderTypeValueByLabel(order.orderType),
      sendOrderType: (isDelivery) ? SendOrderType.A_DOMICILIO.value : SendOrderType.RETIRO_LOCAL.value,
      address: streetAddress,
      numberAdress: numberAddress,
      floor: floorAddress,
      department: deptoAddress,
      provinceId: 20, //ver como mando el id de la provincia
      orderDetails: orderDetails,
      orderObservation: observationsPedido,
      deliveryObservation: observationsAddress,
    }

    axios.put(process.env.REACT_APP_API_ORDER_ROUTE, orderEdit, {
      withCredentials: true,
      headers: {
        Authorization: localStorage.getItem(keyLocalStorage),
      },
    })
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: '¡Pedido editado!',
            timer: 1000,
            showConfirmButton: false,
          });
          setShowModalEditOrder(false);
          getAllOrders(actualStatus);
        })
        .catch(error => {
          alertError();
        })
        .finally(() => {
          setSelectedStatus('');
          setObservationChangeStatus('');
        });
  }

  const addProduct = () => {
    let category = categories.find(cat => cat.id === parseInt(selectedCategory));
    let products = category.productDTOList;
    let product = products.find(prod => prod.id === parseInt(selectedProduct));

    let existingDetail = orderDetails.find(detail => detail.productId === product.id);

    if (existingDetail != null) {
      existingDetail.quantity += quantityAddProduct;
      existingDetail.subTotal = (parseFloat(existingDetail.unitaryPrice) * existingDetail.quantity);
    } else {
      let newDetail = {
        "id": Math.random(),
        "orderId": order.id,
        "productId": product.id,
        "productName": product.name,
        "quantity": quantityAddProduct,
        "subTotal": (parseFloat(product.price) * quantityAddProduct),
        "unitaryPrice": parseFloat(product.price)
      };
      orderDetails.push(newDetail);
    }

    setShowModalAddProduct(false);
    setSelectedCategory('');
    setSelectedProduct('');
    setQuantityAddProduct(0);
  };

  const cleanErrorNameAndSurname = () => {
    if (nameAndSurname != '' && nameAndSurname != null) {
      let fieldRequiredElement = document.querySelector('.errorNameAndSurname');
      fieldRequiredElement.style.display = 'none';
      let inputElement = document.getElementById('nameAndSurname');
      inputElement.style.border = '';
    }
  }

  const cleanErrorStreetAddress = () => {
    if (streetAddress != '' && streetAddress != null) {
      let fieldRequiredElement = document.querySelector('.errorStreetAddress');
      fieldRequiredElement.style.display = 'none';
      let inputElement = document.getElementById('streetAddress');
      inputElement.style.border = '';
    }
  }

  const cleanErrorNumberAddress = () => {
    if (numberAddress != '' && numberAddress != null) {
      let fieldRequiredElement = document.querySelector('.errorNumberAddress');
      fieldRequiredElement.style.display = 'none';
      let inputElement = document.getElementById('numberAddress');
      inputElement.style.border = '';
    }
  }

  return (
    <>

      <tr key={order.id}>
        <td className="text-alig-center">{order.orderId}</td>
        <td className="text-alig-center">{order.orderNumber}</td>
        <td className="text-alig-center">{order.orderDate}</td>
        <td className="text-alig-center">{order.ownerOrder}</td>
        <td className="text-alig-center">{order.orderType}</td>
        <td className="text-alig-center">{getStatus(order.status)}</td>
        <td className="text-alig-center">${formatPrice(order.totalAmount + (order.amountDelivery != null ? order.amountDelivery : 0))}</td>
        <td className="text-alig-center">
          {(order.delivery) ? (
              <>
                SI
                <button type="button" className="btn" onClick={() => {
                  findAddressOrder();
                  setIsLoading(true);
                  setShowModalAddress(true)
                }}>
                  <PiInfo className="" size="24" style={{color: '#2658cb'}}/>
                </button>
              </>
          ) : "NO"}
        </td>
        <td>
          <div className='divBtnsActionsOrder'>
            <button type="button" className="btn btn-success" onClick={() => {
              findOrdersDetails();
              setIsLoading(true)
              setShowModalDetails(true)
            }}>
              Detalle
            </button>

            {order.status === "FINALIZED" ? (
                  <button type="button" className="btn btn-danger" style={{marginLeft: '6%'}}
                  onClick={() => setShowModalCancell(true)}>
                    Cancelar
                  </button>
              ) : (
                  order.status !== "CANCELLED" && (
                      <>
                        <button type="button" className="btn btn-warning" style={{marginLeft: '3%'}} onClick={() => {
                          setShowModalSetStatus(true);
                        }}>
                          <TbStatusChange size={30} style={{color: '#fff'}}/>
                        </button>

                        <button type="button" className="btn btn-info" style={{marginLeft: '3%'}} onClick={() => {
                          findOrdersDetails();
                          if(order.delivery) {
                            findAddressOrder();
                          }
                          getAllCategories();
                          setIsLoading(true);
                          setShowModalEditOrder(true);
                          }}>
                          <FiEdit size={28} style={{color: '#fff'}}/>
                        </button>
                    </>
              ))
            }

            <button type="button" className="btn btn-secondary" style={{marginLeft: '3%'}} onClick={() => {
              findOrdersLogsStatus();
              setIsLoading(true);
              setShowModalLogsStatus(true);
            }}>
              <PiNotepad size={30}/>
            </button>
          </div>
        </td>
      </tr>

      <Modal size="l" show={showModalSetStatus} onHide={() => setShowModalSetStatus(false)}>
        <Modal.Header className="modal-header modalPedido">
          <h3 className="modal-title fs-5 titleModalOrderDetails" id="modalOrderDetailsLabel" style={{marginTop: '3%'}}>Cambio de estado del pedido #{order.orderId}</h3>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
            setShowModalSetStatus(false);
            setSelectedStatus('');
            setObservationChangeStatus('');
          }}></button>
        </Modal.Header>

        <Modal.Body className="modal-body bodyModalOrderDetails">
          <p>Pasar el pedido a:</p>
          {renderStatusOptions()}
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="canceledText" value={observationChangeStatus} onChange={(e) => {setObservationChangeStatus(e.target.value)}}/>
            <label htmlFor="floatingInput">Observacion de cambio:</label>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer modalPedido">
          <button id="btnCloseModalAdd" type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => {
            setShowModalSetStatus(false);
            setSelectedStatus('');
            setObservationChangeStatus('');
          }}>Cerrar</button>
          <button type="button" className="btn btn-primary" onClick={setStatusOrder} disabled={selectedStatus == null || selectedStatus == ''}>Confirmar</button>
        </Modal.Footer>
      </Modal>

      <Modal size="xl" show={showModalEditOrder} onHide={() => setShowModalEditOrder(false)}>
        <Modal.Header className="modal-header modalPedido">
          <h3 className="modal-title fs-5 titleModalOrderDetails" id="modalOrderDetailsLabel" style={{marginTop: '3%'}}>
            Editando pedido #{order.orderId}
          </h3>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModalEditOrder(false)}></button>
        </Modal.Header>

        <Modal.Body className="modal-body bodyModalOrderDetails">
          {isLoading ? (
              <Loading/>
          ) : (
              <>
                <p><strong>Productos:</strong></p>
                {orderDetails.map(detail => (
                    <div className='detail' key={detail.id}>
                      <p>{detail.productName + " (id: " + detail.productId + ")"}</p>
                      <div style={{display: 'flex', justifyContent: 'space-between', width: '30%'}}>
                        <div className='divBotonera'>
                          <button className='btn'>
                            <SubtractAlt size={25} onClick={() => {
                              detail.quantity = Math.max(0, detail.quantity - 1);
                              contCantProdDetail(detail);
                            }}/>
                          </button>
                          <p id={"cantEnPedido" + detail.id} style={{margin: '0'}}>{detail.quantity}</p>
                          <button className='btn'>
                            <AddAlt size={25} style={{marginLeft: '5%'}} onClick={() => {
                              detail.quantity += 1;
                              contCantProdDetail(detail);
                            }}/>
                          </button>
                        </div>
                        <p id={"subtotal" + detail.id}>${formatPrice(detail.subTotal)}</p>
                      </div>
                    </div>
                ))}
                {isDelivery &&
                    <div className='detail'>
                      <p>Envio:</p>
                      <div style={{display: 'flex'}}>
                        <p id="envio">${formatPrice(500)}</p>
                      </div>
                    </div>
                }
                <div className='detail'>
                  <p><strong>Subtotal:</strong></p>
                  <div style={{display: 'flex'}}>
                    <strong id="total">${formatPrice(orderDetails.reduce((acc, detail) => acc + (detail.quantity * detail.unitaryPrice), 0) + (isDelivery ? 500 : 0))}
                    </strong>
                  </div>
                </div>
                <button type="button" className="btn btn-secondary mt-3"
                        style={{backgroundColor: '#C65A4E', border: "none"}} onClick={() => {
                  setShowModalAddProduct(true)
                }}>Agregar producto
                </button>

                <br/>
                <br/>

                <div>
                  <label htmlFor="observations" className="col-form-label">Obvservaciones del pedido:</label>
                  <textarea rows="3" className="form-control observationsPedido" id="observationsPedido"
                            value={observationsPedido} onChange={(e) => {
                    setObservationsPedido(e.target.value);
                  }}/>
                </div>

                <br/>
                <br/>

                <label htmlFor="nameAndSurname" className="col-form-label">Nombre y apellido:</label>
                <input type="text" className="form-control nameAndSurname" id="nameAndSurname"
                       value={nameAndSurname} onChange={(e) => {
                  setNameAndSurname(e.target.value);
                  cleanErrorNameAndSurname();
                }}
                       onBlur={() => cleanErrorNameAndSurname()}/>
                <div className='errorNameAndSurname' id='errorNameAndSurname'>Campo obligatorio</div>

                <br/>
                <label htmlFor="isDelivery" className="col-form-label">Envío a domicilio:</label>
                <div className="form-check">
                  <input
                      className="form-check-input"
                      type="radio"
                      id="isDeliveryTrue"
                      name="isDelivery"
                      value="true"
                      checked={isDelivery === true}
                      onChange={() => setIsDelivery(true)}
                  />
                  <label className="form-check-label" htmlFor="isDeliveryTrue">
                    Sí
                  </label>
                </div>
                <div className="form-check">
                  <input
                      className="form-check-input"
                      type="radio"
                      id="isDeliveryFalse"
                      name="isDelivery"
                      value="false"
                      checked={isDelivery === false}
                      onChange={() => {
                        setIsDelivery(false);
                        setStreetAddress('');
                        setNumberAddress('');
                        setFloorAddress('');
                        setDeptoAddress('');
                        setObservationsAddress('')
                      }}
                  />
                  <label className="form-check-label" htmlFor="isDeliveryFalse">
                    No
                  </label>
                </div>
                {isDelivery && (
                    <>
                      <br/>
                      <label htmlFor="addressPedido" className="col-form-label">Dirección de entrega:</label>
                      <br/>

                      <div className='row'>
                        <div className='col-8'>
                          <label htmlFor="streetAddress" className="col-form-label">Calle:</label>
                          <input type="text" className="form-control streetAddress" id="streetAddress"
                                 value={streetAddress} onChange={(e) => {
                            setStreetAddress(e.target.value);
                            cleanErrorStreetAddress();
                          }}
                                 onBlur={() => cleanErrorStreetAddress()}
                          />
                          <div className='errorStreetAddress' id='errorStreetAddress'>Campo obligatorio</div>
                        </div>

                        <div className='col'>
                          <label htmlFor="numberDirection" className="col-form-label">Numero:</label>
                          <input type="number" className="form-control numberAddress" id="numberAddress"
                                 value={numberAddress} onChange={(e) => {
                            setNumberAddress(e.target.value);
                            cleanErrorNumberAddress();
                          }}
                                 onBlur={() => cleanErrorNumberAddress()}
                          />
                          <div className='errorNumberAddress' id='errorNumberAddress'>Campo obligatorio</div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col'>
                          <label htmlFor="piso" className="col-form-label">Piso:</label>
                          <input type="number" className="form-control piso" id="piso"
                                 value={floorAddress} onChange={(e) => {
                            setFloorAddress(e.target.value);
                          }}
                          />
                        </div>

                        <div className='col'>
                          <label htmlFor="depto" className="col-form-label">Depto:</label>
                          <input type="text" className="form-control depto" id="depto"
                                 value={deptoAddress} onChange={(e) => {
                            setDeptoAddress(e.target.value);
                          }}
                          />
                        </div>
                      </div>
                      <br/>

                      <label htmlFor="observations" className="col-form-label">Indicacion adicional del destino:</label>
                      <textarea placeholder='Casa con rejas negras, sin timbre' rows="3"
                                className="form-control observations" id="observations"
                                value={observationsAddress} onChange={(e) => {
                        setObservationsAddress(e.target.value);
                      }}
                      />
                    </>

                )}
              </>
          )}
        </Modal.Body>

        <Modal.Footer className="modal-footer modalPedido">
          <button id="btnCloseModalAdd" type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                  onClick={() => setShowModalEditOrder(false)}>Cerrar
          </button>
          <button type="button" className="btn btn-primary" onClick={() => updateOrder()}>Guardar</button>
        </Modal.Footer>
      </Modal>

      <Modal size="xl" show={showModalAddProduct} onHide={() => setShowModalAddProduct(false)}>
        <Modal.Header className="modal-header modalPedido">
          <h3 className="modal-title fs-5 titleModalOrderDetails" id="modalOrderDetailsLabel" style={{marginTop: '3%'}}>
            Agregar producto
          </h3>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
            setSelectedCategory('');
            setSelectedProduct('');
            setQuantityAddProduct(0);
            setShowModalAddProduct(false);
          }}></button>
        </Modal.Header>

        <Modal.Body className="modal-body bodyModalOrderDetails">
          <div className="mb-3">
            <label htmlFor="categorySelect" className="form-label">Categoría</label>
            <select
                id="categorySelect"
                className="form-select"
                value={selectedCategory}
                onChange={e => {
                  setSelectedCategory(e.target.value);
                  const category = categories.find(cat => cat.id === parseInt(e.target.value));
                  setProducts(category ? category.productDTOList : []);
                }}
            >
              <option value="">Seleccione una categoría</option>
              {categories.map(category => (
                  <option key={category.id} value={category.id}>{category.name}</option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="productSelect" className="form-label">Producto</label>
            <select
                id="productSelect"
                className="form-select"
                value={selectedProduct}
                onChange={e => setSelectedProduct(e.target.value)}
                disabled={!selectedCategory}
            >
              <option value="">Seleccione un producto</option>
              {products.map(product => (
                  <option key={product.id} value={product.id}>{product.name}</option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="quantityInput" className="form-label">Cantidad</label>
            <input
                type="number"
                id="quantityInput"
                className="form-control"
                value={quantityAddProduct}
                min="1"
                onChange={e => setQuantityAddProduct(Math.max(1, parseInt(e.target.value)))}
            />
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer modalPedido">
          <button id="btnCloseModalAdd" type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => {
            setSelectedCategory('');
            setSelectedProduct('');
            setQuantityAddProduct(0);
            setShowModalAddProduct(false);
          }}>Cerrar</button>
          <button type="button" className="btn btn-primary" onClick={() => addProduct()}>Agregar</button>
        </Modal.Footer>
      </Modal>

      <Modal size="xl" show={showModalLogsStatus} onHide={() => setShowModalLogsStatus(false)}>
        <Modal.Header className="modal-header modalPedido">
          <h3 className="modal-title fs-5 titleModalOrderDetails" id="modalOrderDetailsLabel" style={{marginTop: '3%'}}>Todos los cambios de estado del pedido #{order.orderId}</h3>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
            setShowModalLogsStatus(false);
          }}></button>
        </Modal.Header>

        <Modal.Body className="modal-body bodyModalOrderDetails">
          <p>Estados por los que pasó el pedido:</p>

          {isLoading ? (
              <Loading/>
          ) : (
              <>
                <div className="orderLogTable">
                  <div className="orderLogRow header">
                    <div style={{width: '8%'}}>ID mov.</div>
                    <div style={{width: '25%'}}>Estado</div>
                    <div style={{width: '20%'}}>Fecha y hora</div>
                    <div>Observación</div>
                  </div>
                  {orderLogsStatus.map(log => (
                      <div className="orderLogRow" key={log.id}>
                        <div>{log.id}</div>
                        <div>{getStatus(log.status)}</div>
                        <div>{log.dateStatus}</div>
                        <div>{log.observation}</div>
                      </div>
                  ))}
                </div>
              </>
              )
          }

        </Modal.Body>

        <Modal.Footer className="modal-footer modalPedido">
          <button id="btnCloseModalAdd" type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => {
            setShowModalLogsStatus(false);
          }}>Cerrar</button>
        </Modal.Footer>
      </Modal>

        <Modal size="xl" show={showModalDetails} onHide={handleCloseModal}>
          <Modal.Header className="modal-header modalPedido">
            <h3 className="modal-title fs-5 titleModalOrderDetails" id="modalOrderDetailsLabel" style={{marginTop: '3%'}}>Detalles del pedido #{order.orderId}</h3>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModalDetails(false)}></button>
          </Modal.Header>

          <Modal.Body className="modal-body bodyModalOrderDetails">

              {isLoading ? (
                <Loading/>
              ) : (
                <>
                  {orderDetails.map(detail => (
                    <div className='detail' key={detail.id}>
                      <p>{detail.productName + " (id: " + detail.productId + ")"}</p>
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%' }}>
                        <p>X{detail.quantity}</p>
                        <p>${formatPrice(detail.subTotal)}</p>
                      </div>
                    </div>
                  ))}
                  {order.delivery &&
                      <div className='detail'>
                        <p>ENVÍO</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%' }}>
                          <p></p>
                          <p>${formatPrice(order.amountDelivery)}</p>
                        </div>
                      </div>
                  }
                <br/>

                <p style={{textAlign: 'end'}}><strong>TOTAL:</strong> ${formatPrice((order.totalAmount + (order.amountDelivery != null ? order.amountDelivery : 0)))}</p>

                <label htmlFor="observation" className="col-form-label">Observaciones:</label>
                <input type="text" className="form-control" value={order.orderObservation} disabled/>

                </>
              )}

          </Modal.Body>

          <Modal.Footer className="modal-footer modalPedido">
            <button id="btnCloseModalAdd" type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setShowModalDetails(false)}>Cerrar</button>
          </Modal.Footer>
        </Modal>

        <Modal size="xl" show={showModalCancell} onHide={() => setShowModalCancell(false)}>
          <Modal.Header className="modal-header modalPedido">
            <h3 className="modal-title fs-5 titleModalOrderDetails" id="modalCancelOrderLabel" style={{marginTop: '3%'}}>Cancelar pedido #{order.orderId}</h3>
            <button type="button" id="btnCloseModalCancelIcon" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {setReasonCancellation(""); setShowModalCancell(false)}}></button>
          </Modal.Header>

          <Modal.Body className="modal-body bodyModalCancelOrder">
            <div className="form-floating mb-3">
              <input type="text" className="form-control" id="canceledText" placeholder="Cancelado por ..." value={reasonCancellation} onChange={(e) => {setReasonCancellation(e.target.value)}}/>
              <label htmlFor="floatingInput">Motivo de cancelación</label>
            </div>
          </Modal.Body>

          <Modal.Footer className="modal-footer modalPedido">
            <button id={"btnCloseModalCancel" + order.orderId} type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => {setReasonCancellation(""); setShowModalCancell(false)}}>Cerrar</button>
            <button type="button" className="btn btn-primary" onClick={cancelOrder}>Cancelar pedido</button>
          </Modal.Footer>
        </Modal>

        <Modal size="xl" show={showModalAddress} onHide={() => setShowModalAddress(false)}>
          <Modal.Header className="modal-header modalPedido">
            <h3 className="modal-title fs-5 titleModalOrderDetails" id="modalAddressOrderLabel" style={{marginTop: '3%'}}>Domicilio de entrega del pedido #{order.orderId}</h3>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
              setShowModalAddress(false);
            }}></button>
          </Modal.Header>

          <Modal.Body className="modal-body bodyModalAddressOrder">
            {orderAddress != null ? (
              <>
              <div className='row'>
              <div className='col'>
                <label htmlFor="stateAddress" className="col-form-label">Calle:</label>
                <input type="text" className="form-control" value={orderAddress.adress} disabled/>
              </div>
              <div className='col'>
                <label htmlFor="stateAddress" className="col-form-label">Nro:</label>
                <input type="text" className="form-control" value={orderAddress.numberAdress} disabled/>
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <label htmlFor="stateAddress" className="col-form-label">Piso:</label>
                <input type="text" className="form-control" value={orderAddress.floor} disabled/>
              </div>
              <div className='col'>
                <label htmlFor="stateAddress" className="col-form-label">Depto:</label>
                <input type="text" className="form-control" value={orderAddress.department} disabled/>
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <label htmlFor="stateAddress" className="col-form-label">Indicaciones del domicilio:</label>
                <input type="text" className="form-control" value={orderAddress.deliveryObservation} disabled/>
              </div>
            </div>
              </>
            ) :
            (<></>)}
          </Modal.Body>

          <Modal.Footer className="modal-footer modalPedido">
            <button id="btnCloseModalAdd" type="button" className="btn btn-secondary" onClick={() => setShowModalAddress(false)}>Cerrar</button>
          </Modal.Footer>
        </Modal>
    </>

  );
};

export default Order;