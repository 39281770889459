import Swal from 'sweetalert2';
import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import {CloseLarge, RequestQuote, Search, TrashCan, View, ViewOff} from '@carbon/icons-react';
import * as axiosService from '../services/AxiosService';
import * as ConversorService from '../services/ConversorService';
import * as DownloadService from '../services/DownloadService';
import {Button, Col, Container, Pagination, Row, Table} from "react-bootstrap";
import './Categories.css';

export default function Categories() {

    const keyLocalStorage = 'authToken';

    // Paginado de categorías
    const [categories, setCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [currentNumberOfElements, setCurrentNumberOfElements] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    // Filtros
    const [searchTerm, setSearchTerm] = useState('');

    //Lista de categorias filtrados
    const [searchCategories, setSearchCategories] = useState([]);
    const [searching, setSearching] = useState(false);

    const [categoryName, setCategoryName] = useState('');
    const [displayOrder, setDisplayOrder] = useState('');

    const [categoryIdEdit, setCategoryIdEdit] = useState('');
    const [categoryNameEdit, setCategoryNameEdit] = useState('');
    const [categoryImageEdit, setCategoryImageEdit] = useState('');
    const [displayOrderEdit, setDisplayOrderEdit] = useState('');
    const [statusEdit, setStatusEdit] = useState('');

    const [isLoading, setIsLoading] = useState(true);

    const [fileMassiveCategories, setFileMassiveCategories] = useState(null);
    const [fileNameMassiveCategories, setFileNameMassiveCategories] = useState(null);

    const [nameSearching, setNameSearching] = useState('');

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [selectedIcon, setSelectedIcon] = useState(1); // Estado para guardar el icono seleccionado
    const [imagesCategories, setImagesCategories] = useState([]);

    const getPage = () => {
        axios
            .get(process.env.REACT_APP_API_CATEGORIES_PAGE_ROUTE, {
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem(keyLocalStorage),
                },
                params: {
                    page: currentPage,
                    filter: searchTerm,
                },
            })
            .then(response => {
                setCategories(response.data.content);
                setTotalPages(response.data.totalPages);
                setCurrentPage(response.data.number + 1);
                setCurrentNumberOfElements(response.data.numberOfElements)
                setTotalElements(response.data.totalElements)
                console.log(response);
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (localStorage.getItem(keyLocalStorage) != null) {
            getPage();
            getAllCategoryImages()
        }
    }, [currentPage, isLoading]);

    const getAllCategoryImages = () => {
        axios.get(process.env.REACT_APP_API_CATEGORIES_IMAGES_ROUTE, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
        .then(response => {
            console.log("respuesta");
            console.log(response.data);
            setImagesCategories(response.data);
        })
        .catch(error => {
            alertError();
            console.error(error);
        });
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const handleClear = () => {
        setSearchTerm('');
        setCurrentPage(1); // Reiniciar la página a la primera
        setIsLoading(true);
        getPage();
    };

    function alertError() {
        Swal.fire({
            title: 'Upsss',
            text: 'Ocurrió un error, vuelve a intentar',
            icon: 'error',
            timer: 1500,
            showConfirmButton: false,
        });
    }

    function alertErrorConMensaje(mensaje) {
        Swal.fire({
            title: 'Error',
            text: mensaje,
            icon: 'error',
            showConfirmButton: true,
            allowOutsideClick: false,  // Evita que se cierre haciendo clic fuera del mensaje
            allowEscapeKey: false,  // Evita que se cierre pulsando la tecla Esc
            allowEnterKey: false  // Evita que se cierre pulsando la tecla Enter
        });
    }

    const addCategory = () => {
        if (categoryName == '' || categoryName == null) {
            let fieldRequiredElement = document.querySelector('.fieldRequired');
            fieldRequiredElement.style.display = 'block';
            let inputElement = document.getElementById('nombre');
            inputElement.style.border = '1px solid #ff1717';
        } if(selectedIcon == '' || selectedIcon == null) {
            let fieldRequiredElement = document.querySelector('.fieldRequiredIcon');
            fieldRequiredElement.style.display = 'block';
            let inputElement = document.getElementById('icons');
            inputElement.style.border = '1px solid #ff1717';
        } else {
            let newCatogory = {
                "name": categoryName,
                "displayOrder": displayOrder,
                "categoryImageId": selectedIcon
            }

            axios.post(process.env.REACT_APP_API_CATEGORIES_ROUTE, newCatogory, {
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem(keyLocalStorage),
                }
            })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardada correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                    getPage();
                    const closeButton = document.getElementById('btnCloseModalAdd');
                    closeButton.click();
                    setCategoryName('');
                    setDisplayOrder('');
                    setSelectedIcon(1);
                    resetStyleError();
                })
                .catch(error => {
                    alertErrorConMensaje(error.response.data.errorMsg);
                });
        }
    };

    const editCategory = () => {
        if (categoryNameEdit == '' || categoryNameEdit == null) {
            let fieldRequiredElement = document.querySelector('.fieldRequiredEdit');
            fieldRequiredElement.style.display = 'block';
            let inputElement = document.getElementById('nombreEdit');
            inputElement.style.border = '1px solid #ff1717';
        } else {
            let editCategory = {
                name: categoryNameEdit,
                displayOrder: displayOrderEdit,
                status: statusEdit,
                categoryImageId: categoryImageEdit
            }

            axios.put(process.env.REACT_APP_API_CATEGORIES_ROUTE + '/' + categoryIdEdit, editCategory, {
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem(keyLocalStorage),
                }
            })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Editada correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                    getPage();
                })
                .catch(error => {
                    alertError();
                });

            const closeButton = document.getElementById('btnCloseModalEdit');
            closeButton.click();

            setCategoryNameEdit('');
            setDisplayOrderEdit('');
            setStatusEdit('');
            setCategoryIdEdit('');
        }
    };

    const handleSearch = () => {
        setCurrentPage(1);
        getPage(searchTerm, 1);
    }

    function onEdit(category) {
        setCategoryIdEdit(category.id);
        setCategoryNameEdit(category.name);
        setCategoryImageEdit(category.categoryImage.id);
        setDisplayOrderEdit(category.displayOrder);
        setStatusEdit(category.status);
    }

    function onDelete(category) {
        Swal.fire({
            title: '¿Estás seguro de eliminar la categoría?',
            text: 'Al eliminar la categoría todos sus productos se inactivaran.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: 'rgb(246, 139, 127)',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(process.env.REACT_APP_API_CATEGORIES_ROUTE + '/' + category.id, {
                    withCredentials: true,
                    headers: {
                        Authorization: localStorage.getItem(keyLocalStorage),
                    }
                })
                    .then(response => {
                        Swal.fire({
                            icon: 'success',
                            title: '¡Éxito!',
                            text: 'Eliminado correctamente.',
                            timer: 1500,
                            showConfirmButton: false,
                        });
                        getPage();
                    })
                    .catch(error => {
                        alertErrorConMensaje(error.response.data.errorMsg);
                    });
            }
        });
    }

    const resetStyleError = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequired');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('nombre');
        inputElement.style.border = '';

        fieldRequiredElement = document.querySelector('.fieldRequiredIcon');
        fieldRequiredElement.style.display = 'none';
        inputElement = document.getElementById('icons');
        inputElement.style.border = '';
    }

    const resetStyleErrorFile = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredFile');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('fileMassiveCategories');
        inputElement.style.border = '';
        setFileMassiveCategories('');
        setFileNameMassiveCategories('');
    }

    const resetStyleErrorEdit = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredEdit');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('nombreEdit');
        inputElement.style.border = '';
    }

    const cleanError = () => {
        if (categoryName != '' && categoryName != null) {
            resetStyleError();
        }
    }

    const cleanErrorEdit = () => {
        if (categoryNameEdit != '' && categoryNameEdit != null) {
            resetStyleErrorEdit();
        }
    }

    const cleanValues = () => {
        setCategoryName('');
        setDisplayOrder('');
        setSelectedIcon(1);
    }

    const enableCategory = (categoryId) => {
        axios.put(process.env.REACT_APP_API_CATEGORIES_ENABLED_ROUTE + '/' + categoryId,
            {},
            {
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem(keyLocalStorage),
                },
            }
        )
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Activada correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                getPage();
            })
            .catch(error => {
                alertErrorConMensaje(error.response.data.errorMsg);
            });
    }

    const disableCategory = (categoryId) => {
        axios.put(
            process.env.REACT_APP_API_CATEGORIES_DISABLED_ROUTE + '/' + categoryId,
            {},
            {
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem(keyLocalStorage),
                },
            }
        )
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Desactivada correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                getPage();
            })
            .catch(error => {
                alertErrorConMensaje(error.response.data.errorMsg);
            });
    }

    const addCategoriesMassive = () => {
        if (fileMassiveCategories == '' || fileMassiveCategories == null) {
            let fieldRequiredElement = document.querySelector('.fieldRequiredFile');
            fieldRequiredElement.style.display = 'block';
            let inputElement = document.getElementById('fileMassiveCategories');
            inputElement.style.border = '1px solid #ff1717';
            return;
        }
        setIsButtonDisabled(true);

        const reader = new FileReader();

        reader.onload = () => {
            const base64String = reader.result.split(",")[1]; // Obtiene el string base64

            axios.post(process.env.REACT_APP_API_CATEGORIES_FILE_UPLOAD_ROUTE, base64String, {
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem(keyLocalStorage),
                    "Content-Type": "text/plain"
                }
            })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardada correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });

                    setTimeout(() => {
                        const btnCloseModal = document.getElementById('btnCloseModalAddMassiveCategories');
                        if (btnCloseModal) {
                            btnCloseModal.click();
                        }
                    }, 1800);

                    setTimeout(() => {
                        window.location.reload();
                    }, 2100);
                })
                .catch(error => {
                    setIsButtonDisabled(false);
                    alertErrorConMensaje(error.response.data.errorMsg)
                });
        };

        reader.readAsDataURL(fileMassiveCategories); // Lee el archivo como una URL de datos
    }

    const downloadExcelFile = () => {
        axios.get(process.env.REACT_APP_API_CATEGORIES_FILE_DOWNLOAD_ROUTE, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            },
            responseType: 'blob', // Indica que esperamos una respuesta binaria (archivo)
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'CategoriasUCODE.xlsx');
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // Liberar recursos
            })
            .catch(error => {
                alertErrorConMensaje(error.response.data.errorMsg)
            });
    };

    const handleFileChange = (event) => {
        setFileMassiveCategories(event.target.files[0]);
    };

    const deleteMassiveWithParams = () => {
        Swal.fire({
            title: '¿Estás seguro de eliminar todas las categorías filtradas? ' + searchCategories.length + (searchCategories.length > 1 ? ' categorías' : ' categoría'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: 'rgb(246, 139, 127)',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                const filter = {
                    name: nameSearching
                };

                axios.delete(process.env.REACT_APP_API_CATEGORIES_ROUTE, {
                    withCredentials: true,
                    headers: {
                        Authorization: localStorage.getItem(keyLocalStorage),
                    },
                    params: filter
                })
                    .then(response => {
                        Swal.fire({
                            icon: 'success',
                            title: '¡Éxito!',
                            text: 'Eliminado correctamente.',
                            timer: 1500,
                            showConfirmButton: false,
                        });
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500);
                    })
                    .catch(error => {
                        alertError();
                    });
            }
        });

    }

    const handleDownload = async () => {
        try {
            const {data} = await axiosService.getWithCredentialsSync(process.env.REACT_APP_API_CATEGORIES_FILE_DOWNLOAD_ROUTE); // Obtengo la data
            const blob = ConversorService.convertBase64ToBlob(data); // Conversion de la data
            DownloadService.downloadFile(blob, "CategoriasUCODE", "xlsx"); // Genero y descargo el archivo
        } catch (error) {

        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            getPage()
        }
    };

    return (
        <div className="categoriesContainer">
            {(localStorage.getItem(keyLocalStorage) != null) ? (
                <>

                    <Container className="mt-3">
                        <Row>
                            <Col>
                                <h1 className="titleProducts">Categorías</h1>
                            </Col>
                        </Row>
                    </Container>

                    <Container className="mt-3">
                        <Row>
                            <Col>
                                <input type="text"
                                       className="searchTerm"
                                       placeholder="Nombre de categoría"
                                       value={searchTerm}
                                       onChange={(e) => {setSearchTerm(e.target.value)}}
                                       onKeyUp={handleKeyPress}/>
                            </Col>
                            <Col className="text-center text-md-start mt-3 mt-md-0 mb-sm-3 mb-md-0">
                                <Button id="btnSearchProduct" className="me-1" variant="primary" onClick={() => handleSearch()}>
                                    <Search/>
                                </Button>
                                <Button id="btnCleanSearchProducts" className="ms-1" variant="primary" onClick={() => handleClear()}>
                                    <CloseLarge/>
                                </Button>
                            </Col>
                        </Row>
                    </Container>

                    {(isLoading) ? (
                            <div className="ms-3 me-3 mt-3">
                                <Table responsive className="table-striped">
                                    <thead>
                                    <tr className="trTable">
                                        <th className="colsTableCategory" style={{
                                            backgroundColor: "rgb(235, 120, 107)",
                                            width: "10%",
                                            color: "#ffffff"
                                        }} scope="col-1">ID
                                        </th>
                                        <th className="colsTableCategory"
                                            style={{backgroundColor: "rgb(235, 120, 107)", color: "#ffffff"}}
                                            scope="col">Nombre
                                        </th>
                                        <th className="colsTableCategory" style={{
                                            backgroundColor: "rgb(235, 120, 107)",
                                            width: "20%",
                                            color: "#ffffff"
                                        }} scope="col">Prioridad
                                        </th>
                                        <th className="colsTableCategory" style={{
                                            backgroundColor: "rgb(235, 120, 107)",
                                            width: "20%",
                                            color: "#ffffff"
                                        }} scope="col">Estado
                                        </th>
                                        <th className="colsTableCategory" style={{
                                            backgroundColor: "rgb(235, 120, 107)",
                                            width: "10%",
                                            color: "#ffffff"
                                        }} scope="col"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td colSpan="6" className="backgroundTable text-center">
                                            <div className="loader-container-listado">
                                                <div className="loader"></div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                    ) : (
                        ((searching && (searchCategories != '' && searchCategories != null)) || (categories != '' && categories != null)) ? (
                            <div className="ms-3 me-3 mt-3">
                                <Table responsive className="table-striped">
                                    <thead className="tableHead">
                                    <tr className="trTable">
                                        <th>ID</th>
                                        <th>Nombre</th>
                                        <th>Prioridad</th>
                                        <th>Estado</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(searching) ? (
                                        searchCategories
                                            .map(category => (
                                                <tr key={category.id}>
                                                    <td>{category.id}</td>
                                                    <td>{category.name}</td>
                                                    <td>{category.displayOrder}</td>
                                                    <td>{category.status ? "ACTIVO" : "INACTIVO"}</td>
                                                    <td>
                                                        <div className="colBtnsActionCat">
                                                            <button data-bs-toggle="modal"
                                                                    data-bs-target="#modaleEditCategory"
                                                                    className="btn btnEdit"
                                                                    onClick={() => onEdit(category)}><RequestQuote
                                                                className="btnEdit" size="24"/></button>
                                                            {category.status ? (
                                                                <button className="btn btnActiveInactive"
                                                                        onClick={() => disableCategory(category.id)}>
                                                                    <View className="btnActiveInactive" size="24"/>
                                                                </button>
                                                            ) : (
                                                                <button className="btn btnActiveInactive"
                                                                        onClick={() => enableCategory(category.id)}>
                                                                    <ViewOff className="btnActiveInactive" size="24"/>
                                                                </button>
                                                            )}
                                                            <button className="btn btnDelete"
                                                                    onClick={() => onDelete(category)}><TrashCan
                                                                className="btnDelete" size="24"/></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                    ) : (
                                        categories
                                            .map(category => (
                                                <tr key={category.id}>
                                                    <td className="text-alig-center fw-bold">{category.id}</td>
                                                    <td>{category.name}</td>
                                                    <td className="text-alig-center">{category.displayOrder}</td>
                                                    <td className="text-alig-center">{category.status ? "ACTIVO" : "INACTIVO"}</td>
                                                    <td width="5%">
                                                        <div className="colBtnsActionCat justify-content-center">
                                                            <button data-bs-toggle="modal"
                                                                    data-bs-target="#modaleEditCategory"
                                                                    className="btn btnEdit"
                                                                    onClick={() => onEdit(category)}><RequestQuote
                                                                className="btnEdit" size="24"/></button>
                                                            {category.status ? (
                                                                <button className="btn btnActiveInactive"
                                                                        tooltip="I'm up above it!"
                                                                        onClick={() => disableCategory(category.id)}>
                                                                    <View className="btnActiveInactive" size="24"/>
                                                                </button>
                                                            ) : (
                                                                <button className="btn btnActiveInactive"
                                                                        tooltip="I'm up above it!"
                                                                        onClick={() => enableCategory(category.id)}>
                                                                    <ViewOff className="btnActiveInactive" size="24"/>
                                                                </button>
                                                            )}
                                                            <button className="btn btnDelete"
                                                                    onClick={() => onDelete(category)}><TrashCan
                                                                className="btnDelete" size="24"/></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                    )}
                                    </tbody>
                                </Table>

                                {(searching) &&
                                    <div className="divDeleteMassive">
                                        <button className="btnAddCategory btn"
                                                disabled={searchCategories.length > 0 ? false : true} style={{
                                            backgroundColor: "rgb(235, 120, 107)",
                                            marginBottom: "5%",
                                            marginLeft: '3%',
                                            color: "#ffffff",
                                            width: '11%'
                                        }} onClick={deleteMassiveWithParams}>Eliminar categorías
                                        </button>
                                    </div>
                                }

                            </div>
                        ) : (
                            <div className="m-3">
                                <Table responsive className="table-responsive">
                                    <thead className="tableHead">
                                    <tr>
                                        <th>ID</th>
                                        <th>Nombre</th>
                                        <th>Prioridad</th>
                                        <th>Estado</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td colSpan="6" className="text-center">Aún no hay
                                            categorías
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        )
                    )}

                    <Container fluid>
                        <Row>
                            <Col className="d-flex align-items-center">
                                <Pagination>
                                    <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1}/>
                                    <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}
                                                     disabled={currentPage === 1}/>
                                    {currentPage > 2 && (
                                        <Pagination.Item onClick={() => handlePageChange(currentPage - 2)}>
                                            {currentPage - 2}
                                        </Pagination.Item>
                                    )}
                                    {currentPage > 1 && (
                                        <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                                            {currentPage - 1}
                                        </Pagination.Item>
                                    )}
                                    <Pagination.Item active>{currentPage}</Pagination.Item>
                                    {currentPage < totalPages && (
                                        <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                                            {currentPage + 1}
                                        </Pagination.Item>
                                    )}
                                    {currentPage < totalPages - 1 && (
                                        <Pagination.Item onClick={() => handlePageChange(currentPage + 2)}>
                                            {currentPage + 2}
                                        </Pagination.Item>
                                    )}
                                    <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}
                                                     disabled={currentPage === totalPages || categories.length === 0}/>
                                    <Pagination.Last onClick={() => handlePageChange(totalPages)}
                                                     disabled={currentPage === totalPages || categories.length === 0}/>
                                </Pagination>
                                <div className="ms-2">
                                    <p>Mostrando {currentNumberOfElements} de {totalElements} </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>


                    <Container>
                        <Row>
                            <Col className="text-alig-center">
                                <Button variant="primary" className="me-1" data-bs-toggle="modal"
                                        data-bs-target="#modaleAddCategory">Agregar</Button>
                                <Button variant="primary" className="ms-1" data-bs-toggle="modal"
                                        data-bs-target="#modaleAddCategoryMassive">Carga Masiva</Button>
                            </Col>
                        </Row>
                    </Container>

                    <div className="modal fade modal-lg" id="modaleAddCategory" data-bs-backdrop="static"
                         data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modaleAddCategoryLabel"
                         aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="modaleAddCategoryLabel"
                                        className="titleModalCreateCategory">Crear categoría</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => {
                                                resetStyleError();
                                                cleanValues();
                                            }}></button>
                                </div>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label htmlFor="nombre" className="col-form-label">Nombre:</label>
                                        <input type="text" className="form-control" id="nombre" value={categoryName}
                                               onChange={(e) => {
                                                   const categoryNameIng = e.target.value;
                                                   if (categoryNameIng.length <= 50) {
                                                       setCategoryName(e.target.value);
                                                   }
                                                   cleanError();
                                               }}/>
                                        <div className="fieldRequired">Campo obligatorio</div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="displayOrder" className="col-form-label">Orden de
                                            importancia:</label>
                                        <input type="number" className="form-control" id="displayOrder"
                                               value={displayOrder}
                                               onChange={(e) => setDisplayOrder(e.target.value)}/>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="displayOrder" className="col-form-label">Icono de
                                            categoría:</label>
                                        <div className="imagesCategories d-flex flex-wrap" id="icons">
                                            {Array.isArray(imagesCategories) && imagesCategories.map((image) => (
                                                <div key={image.id}
                                                     className="m-2 d-flex align-items-center justify-content-center"
                                                     style={{
                                                         backgroundColor: selectedIcon === image.id ? 'rgb(235, 120, 107)' : 'rgb(255, 157, 146)',
                                                         borderRadius: '50%', // Círculo
                                                         width: '60px', // Tamaño del círculo
                                                         height: '60px', // Tamaño del círculo
                                                         overflow: 'hidden', // Asegura que la imagen no se salga del contenedor circular
                                                         display: 'flex',
                                                         alignItems: 'center',
                                                         justifyContent: 'center',
                                                         padding: '1%'
                                                     }}>
                                                    <img
                                                        src={image.url}
                                                        className={`icon-image ${selectedIcon === image.id ? 'selected' : ''}`}
                                                        onClick={() => setSelectedIcon(image.id)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            borderRadius: '4px',
                                                            objectFit: 'contain', // Mantiene el aspecto de la imagen sin deformarla
                                                            width: '100%'
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="fieldRequiredIcon">Campo obligatorio</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button id="btnCloseModalAdd" type="button" className="btn btn-secondary"
                                            data-bs-dismiss="modal" onClick={() => {
                                        resetStyleError();
                                        cleanValues();
                                    }}>Cerrar
                                    </button>
                                    <button type="button" className="btn btn-primary"
                                            onClick={() => addCategory()}>Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade modal-lg" id="modaleAddCategoryMassive" data-bs-backdrop="static"
                         data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modaleAddCategoryMassiveLabel"
                         aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="modaleAddCategoryMassiveLabel"
                                        className="titleModalCreateCategory">Carga masiva de categorías</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => {
                                                resetStyleErrorFile()
                                            }}></button>
                                </div>
                                <div className="modal-body">
                                    <button className="btn" style={{backgroundColor: '#C65A4E', color: 'white'}}
                                            onClick={handleDownload}>
                                        Descargar Template
                                    </button>
                                    <div style={{marginTop: '5%'}}>
                                        <label htmlFor="fileMassiveCategories" className="col-form-label">Carga archivo
                                            excel
                                            con categorías:</label>
                                        <input type="file" className="form-control" id="fileMassiveCategories"
                                               value={fileNameMassiveCategories} onChange={(e) => {
                                            setFileNameMassiveCategories(e.target.value);
                                            handleFileChange(e);
                                        }}/>
                                        <div className="fieldRequiredFile" display='none'>El archivo es obligatorio
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button id="btnCloseModalAddMassiveCategories" type="button" className="btn btn-secondary"
                                            data-bs-dismiss="modal" onClick={() => {
                                        resetStyleErrorFile()
                                    }}>Cerrar
                                    </button>
                                    <button type="button" className="btn btn-primary"
                                            onClick={addCategoriesMassive}
                                            disabled={isButtonDisabled}>
                                        {isButtonDisabled ? "Guardando..." : "Guardar"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade modal-lg" id="modaleEditCategory" data-bs-backdrop="static"
                         data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modaleEditCategoryLabel"
                         aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="modaleEditCategoryLabel"
                                        className="titleModalEditCategory">Editar categoría</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label htmlFor="nombre" className="col-form-label">Nombre:</label>
                                        <input type="text" className="form-control" id="nombreEdit"
                                               value={categoryNameEdit}
                                               onChange={(e) => {
                                                   const categoryNameEditIng = e.target.value;
                                                   if (categoryNameEditIng.length <= 50) {
                                                       setCategoryNameEdit(categoryNameEditIng);
                                                   }
                                                   cleanErrorEdit();
                                               }}/>
                                        <div className="fieldRequiredEdit">Campo obligatorio</div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="displayOrder" className="col-form-label">Orden de
                                            importancia:</label>
                                        <input type="number" className="form-control" id="displayOrder"
                                               value={displayOrderEdit}
                                               onChange={(e) => setDisplayOrderEdit(e.target.value)}/>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="displayOrder" className="col-form-label">Icono de
                                            categoría:</label>
                                        <div className="imagesCategories d-flex flex-wrap">
                                            {Array.isArray(imagesCategories) && imagesCategories.map((image) => (
                                                <div key={image.id}
                                                     className="m-2 d-flex align-items-center justify-content-center"
                                                     style={{
                                                         backgroundColor: categoryImageEdit === image.id ? 'rgb(235, 120, 107)' : 'rgb(255, 157, 146)',
                                                         borderRadius: '50%', // Círculo
                                                         width: '60px', // Tamaño del círculo
                                                         height: '60px', // Tamaño del círculo
                                                         overflow: 'hidden', // Asegura que la imagen no se salga del contenedor circular
                                                         display: 'flex',
                                                         alignItems: 'center',
                                                         justifyContent: 'center',
                                                         padding: '1%'
                                                     }}>
                                                    <img
                                                        src={image.url}
                                                        className={`icon-image ${categoryImageEdit === image.id ? 'selected' : ''}`}
                                                        onClick={() => setCategoryImageEdit(image.id)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            borderRadius: '4px',
                                                            objectFit: 'contain', // Mantiene el aspecto de la imagen sin deformarla
                                                            width: '100%'
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button id="btnCloseModalEdit" type="button" className="btn btn-secondary"
                                            data-bs-dismiss="modal" onClick={() => {
                                        setCategoryNameEdit('');
                                        setDisplayOrderEdit('');
                                        setStatusEdit('');
                                        setCategoryIdEdit('');
                                        resetStyleErrorEdit();
                                    }}>Cerrar
                                    </button>
                                    <button type="button" className="btn btn-primary"
                                            onClick={() => editCategory()}>Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div id="mainNotFound">
                    <div className="fof">
                        <h1 className='h1Error404'>Error 404</h1>
                    </div>
                </div>
            )}

        </div>
    );
}