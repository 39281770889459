import axios from 'axios';

const getConfig = () => ({
    withCredentials: true,
    headers: {
        Authorization: localStorage.getItem(process.env.REACT_APP_API_KEY_LOCAL_STORAGE)
    }
});

export const getWithCredentials = (url) => {
    return axios.get(url, getConfig());
}

export const postWithCredentials = async(url, data) => {
    return await axios.post(url, data, getConfig());
};

export const putWithCredentials = async(url, data) => {
    return await axios. put(url, data, getConfig());
};
