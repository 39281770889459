import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import axios from 'axios'; 
import Loading from "../components/Loading";
import Order from "../components/Order";
import {Col, Container, Nav, Row, Table} from "react-bootstrap";
import { Form, Button } from "react-bootstrap";

export default function Orders() {
  const keyLocalStorage = 'authToken';

  const [orders, setOrders] = useState([]);
  const [ordersPag, setOrdersPag] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [isReceived, setIsReceived] = useState(true);
  const [isInPreparation, setIsInPreparation] = useState(false);
  const [isDeliveryInPregress, setIsDeliveryInPregress] = useState(false);
  const [isFinalized, setIsFinalized] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);

  const [orderId, setOrderId] = useState("");
  const [orderOrigen, setOrderOrigen] = useState("");

  const [actualPage, setActualPage] = useState(1);
  const productsPerPage = 10;
  let indexOfLastProduct = actualPage * productsPerPage;
  let indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  useEffect(() => {
    document.title = 'QReate - Pedidos';
    if(localStorage.getItem(keyLocalStorage) != null){
      getAllOrders("RECEIVED");
    }
  }, []);

  const getAllOrders = (status) => {
    const queryParams = new URLSearchParams();
    queryParams.append('status', status);
    
    axios.get(`${process.env.REACT_APP_API_ORDER_ROUTE}?${queryParams.toString()}`, {
      withCredentials: true,
      headers: {
        Authorization: localStorage.getItem(keyLocalStorage),
      }
    })
    .then(response => {
      console.log(response.data)
        indexOfLastProduct = 1 * productsPerPage;
        indexOfFirstProduct = indexOfLastProduct - productsPerPage;

        setOrders(response.data);
        setOrdersPag(response.data.slice(indexOfFirstProduct, indexOfLastProduct));
        setActualPage(1);
      })
      .catch(error => {
        alertError();
        console.error(error)})
      .finally(() => {
        setIsLoading(false);
      });
  }

  function alertError(){
    Swal.fire({
      title: 'Upsss',
      text: 'Ocurrió un error, vuelve a intentar',
      icon: 'error',
      timer: 1500,
      showConfirmButton: false,
    });
  }

  function formatPrice(price) {
    if (!price) return ''; // Manejar caso de valor vacío
    const parts = price.toString().split('.'); // Separar parte entera de la decimal
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agregar separador de miles
    return parts.join(','); // Unir las partes con coma como separador decimal
  }

  const previewPage = () => {
    if(actualPage != 1){
      setActualPage(actualPage - 1);
      indexOfLastProduct = (actualPage - 1) * productsPerPage;
      indexOfFirstProduct = indexOfLastProduct - productsPerPage;
      setOrdersPag(orders.slice(indexOfFirstProduct, indexOfLastProduct));
    }
  }

  const nextPage = () => {
    if(orders.slice((((actualPage + 1) * productsPerPage) - productsPerPage), ((actualPage + 1) * productsPerPage)).length > 0){
      setActualPage(actualPage + 1);
      indexOfLastProduct = (actualPage + 1) * productsPerPage;
      indexOfFirstProduct = indexOfLastProduct - productsPerPage;
      setOrdersPag(orders.slice(indexOfFirstProduct, indexOfLastProduct));
    }
  }

  const hasMoreOrders = (actualPageParam) =>{
    if (orders && orders.length > 0 &&
      orders.slice((((actualPageParam + 1) * productsPerPage) - productsPerPage), ((actualPageParam + 1) * productsPerPage)).length > 0) {
      return true;
    }
    return false;
  }

  const getStatus = () => {
    if (isReceived) {
      return "RECEIVED";
    }
    if (isInPreparation) {
      return "IN_PREPARATION";
    }
    if (isDeliveryInPregress) {
      return "DELIVERY_IN_PROGRESS";
    }
    if (isFinalized) {
      return "FINALIZED";
    }
    if (isCanceled) {
      return "CANCELLED";
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevenir que se envíe el formulario si hay uno
      handleOrderSearch();
    }
  };
  const handleOrderSearch = () => {
    setIsLoading(true);

    const queryParams = new URLSearchParams();
    if (orderOrigen !== null && orderOrigen !== ''){
      queryParams.append('orderType', orderOrigen);
    }
    if (orderId !== null && orderId !== ''){
      queryParams.append('orderNumber', orderId);
    }

    let status = statusActive();
    if(status != null){
      queryParams.append('status', status);
    }

    axios.get(`${process.env.REACT_APP_API_ORDER_ROUTE}?${queryParams.toString()}`, {
      withCredentials: true,
      headers: {
        Authorization: localStorage.getItem(keyLocalStorage),
      }
    })
        .then(response => {
          console.log(response.data)
          indexOfLastProduct = 1 * productsPerPage;
          indexOfFirstProduct = indexOfLastProduct - productsPerPage;

          setOrders(response.data);
          setOrdersPag(response.data.slice(indexOfFirstProduct, indexOfLastProduct));
          setActualPage(1);
        })
        .catch(error => {
          alertError();
          console.error(error)})
        .finally(() => {
          setIsLoading(false);
        });
  };

  const statusActive = () => {
    if(isReceived){
      return "RECEIVED"
    } else if(isInPreparation){
      return "IN_PREPARATION";
    } else if(isDeliveryInPregress){
      return "DELIVERY_IN_PROGRESS";
    } else if(isFinalized){
      return "FINALIZED";
    } else if(isCanceled){
      return "CANCELLED";
    }
    return null;
  }

  const handleOrderSearchClean = () => {
    setOrderOrigen('');
    setOrderId('');
    setIsLoading(true);
    setIsReceived(false);
    setIsInPreparation(false);
    setIsDeliveryInPregress(false);
    setIsFinalized(false);
    setIsCanceled(false);
    getAllOrders("");
  }

  if (isLoading) {
      return <Loading/>;
  }

    return (
      <div className="containersOrders">
        <Container className="mt-3">
          <Row>
            <Col>
              <h1 className="titleOrdersWhatsapp">Pedidos</h1>
            </Col>
          </Row>
        </Container>

        <Container className="divStatus">
          <Row
              className={isReceived ? "divRecived fondoYletraBlanca" : "divRecived"}
              onClick={(e) => {
                if (isReceived) {
                  setIsReceived(false);
                  getAllOrders(""); // Búsqueda sin estado
                } else {
                  setIsLoading(true);
                  setIsReceived(true);
                  setIsInPreparation(false);
                  setIsDeliveryInPregress(false);
                  setIsFinalized(false);
                  setIsCanceled(false);
                  getAllOrders("RECEIVED");
                }
                setOrderId('');
                setOrderOrigen('');
              }}
          >
            Recibidos
          </Row>
          <Row
              className={isInPreparation ? "divInPreparation fondoYletraBlanca" : "divInPreparation"}
              onClick={(e) => {
                if (isInPreparation) {
                  setIsInPreparation(false);
                  getAllOrders(""); // Búsqueda sin estado
                } else {
                  setIsLoading(true);
                  setIsInPreparation(true);
                  setIsReceived(false);
                  setIsDeliveryInPregress(false);
                  setIsFinalized(false);
                  setIsCanceled(false);
                  getAllOrders("IN_PREPARATION");
                }
                setOrderId('');
                setOrderOrigen('');
              }}
          >
            En preparación
          </Row>
          <Row
              className={isDeliveryInPregress ? "divDeliveryinProgress fondoYletraBlanca" : "divDeliveryinProgress"}
              onClick={(e) => {
                if (isDeliveryInPregress) {
                  setIsDeliveryInPregress(false);
                  getAllOrders(""); // Búsqueda sin estado
                } else {
                  setIsLoading(true);
                  setIsDeliveryInPregress(true);
                  setIsReceived(false);
                  setIsInPreparation(false);
                  setIsFinalized(false);
                  setIsCanceled(false);
                  getAllOrders("DELIVERY_IN_PROGRESS");
                }
                setOrderId('');
                setOrderOrigen('');
              }}
          >
            En camino
          </Row>
          <Row
              className={isFinalized ? "divFinalized fondoYletraBlanca" : "divFinalized"}
              onClick={(e) => {
                if (isFinalized) {
                  setIsFinalized(false);
                  getAllOrders(""); // Búsqueda sin estado
                } else {
                  setIsLoading(true);
                  setIsFinalized(true);
                  setIsReceived(false);
                  setIsInPreparation(false);
                  setIsDeliveryInPregress(false);
                  setIsCanceled(false);
                  getAllOrders("FINALIZED");
                }
                setOrderId('');
                setOrderOrigen('');
              }}
          >
            Finalizado
          </Row>
          <Row
              className={isCanceled ? "divCancels fondoYletraBlanca" : "divCancels"}
              onClick={(e) => {
                if (isCanceled) {
                  setIsCanceled(false);
                  getAllOrders(""); // Búsqueda sin estado
                } else {
                  setIsLoading(true);
                  setIsCanceled(true);
                  setIsReceived(false);
                  setIsInPreparation(false);
                  setIsDeliveryInPregress(false);
                  setIsFinalized(false);
                  getAllOrders("CANCELLED");
                }
                setOrderId('');
                setOrderOrigen('');
              }}
          >
            Cancelados
          </Row>
        </Container>

        <Container className="filterContainer">
          <Row className="justify-content-center align-items-center" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
            <Col xs={12} md={4} lg={3} className="text-center mb-3">
              <Form.Control
                  type="text"
                  placeholder="Número de Pedido"
                  value={orderId}
                  onChange={(e) => setOrderId(e.target.value)}
                  onKeyDown={handleKeyPress}
              />
            </Col>
            <Col xs={12} md={4} lg={3} className="text-center mb-3">
              <Form.Control
                  as="select"
                  value={orderOrigen}
                  onChange={(e) => setOrderOrigen(e.target.value)}
                  onKeyDown={handleKeyPress}
              >
                <option value="">Selecciona origen</option>
                <option value="WHATSAP">WhatsApp</option>
                <option value="MOSTRADOR">Mostrador</option>
              </Form.Control>
            </Col>
            <Col xs={12} md={2} lg={1} className="text-center mb-3">
              <Button variant="primary" onClick={handleOrderSearch}>Buscar</Button>
            </Col>
            <Col xs={12} md={2} lg={2} className="text-center mb-3">
              <Button variant="primary" onClick={handleOrderSearchClean}>Ver todos</Button>
            </Col>
          </Row>
        </Container>

        <div className="m-2">
          <Table responsive className="table-striped">
            <thead className="tableHead">
            <tr>
              <th>ID</th>
              <th>Número de pedido</th>
              <th>Fecha y hora</th>
              <th>Nombre y apellido</th>
              <th>Origen</th>
              <th>Estado</th>
              <th>Total</th>
              <th>Delivery</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
              {(ordersPag != '' && ordersPag != [] && ordersPag != null) ? (
                  ordersPag.map(order => (
                      <Order order={order} getAllOrders={getAllOrders} actualStatus={getStatus()}/>
                  ))
              ) : (
                  <tr>
                    <td colSpan="9" className="backgroundTable text-center">Aún no hay Pedidos</td>
                  </tr>
              )}
            </tbody>
          </Table>

            <Nav aria-label="Page navigation example" className="pb-2">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link colorPrincipal" href="#" aria-label="Previous" onClick={() => {previewPage()}}>
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                {actualPage > 1 && <li className="page-item"><a className="page-link colorPrincipal">{actualPage - 1}</a></li>}
                <li className="page-item"><a className="page-link colorPrincipal" style={{backgroundColor: 'rgb(255 230 230)'}}>{actualPage}</a></li>
                {hasMoreOrders(actualPage) && <li className="page-item"><a className="page-link colorPrincipal">{actualPage + 1}</a></li>}
                <li className="page-item">
                  <a className="page-link colorPrincipal" href="#" aria-label="Next" onClick={() => {nextPage()}}>
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </Nav>

        </div>
      </div>
    );
  }