import React, {useEffect, useState} from 'react';
import Loading from '../components/Loading';
import * as axiosService from '../services/AxiosService'
import './ShopPage.css';
import ShopTitle from "../components/Shop/ShopTitle";
import ShopForm from "../components/Shop/ShopForm";

export default function ShopPage() {

    const [isLoading, setIsLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [shopDTO, setShopDTO] = useState({}); // TODO -> Lo necesito para el edit

    useEffect(() => {
        getShop();
    }, [isEdit]);

    const getShop = () =>{
        axiosService.getWithCredentials(process.env.REACT_APP_API_SHOP_ROUTE + '/1')
            .then(response => {
                setShopDTO(response.data);
                setIsEdit(true);
            })
            .catch(error => {
                if(error.response.status === 404) {
                    setIsEdit(false);
                } else {
                    console.error("Error obteniendo el local");
                }
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <div className='shopContainer'>
                    <ShopTitle/>
                    <ShopForm isEdit={isEdit} setIsEdit={setIsEdit} shopDTO={shopDTO}/>
                </div>
            )}
        </>
    );
}