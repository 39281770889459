import React from 'react';
import Loading from '../components/Loading';
import { useState, useEffect } from 'react';
import axios from 'axios';
import * as axiosService from '../services/AxiosService'
import Swal from 'sweetalert2';
import './ShopPage.css';
import ShopTitle from '../components/Shop/ShopTitle';
import ShopGenericPanel from '../components/Shop/ShopGeneralInfoPanel';

export default function ShopPage_old() {

    const keyLocalStorage = 'authToken';
    
    const [name, setName] = useState();
    const [businessName, setBusinessName] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [address, setAddress] = useState();
    const [state, setState] = useState();
    const [location, setLocation] = useState();
    const [logo, setLogo] = useState();
    const [nameLogo, setNameLogo] = useState();
    const [color, setColor] = useState('#FF9D92');

    const [nameEdit, setNameEdit] = useState();
    const [businessNameEdit, setBusinessNameEdit] = useState();
    const [phoneNumberEdit, setPhoneNumberEdit] = useState();
    const [addressEdit, setAddressEdit] = useState();
    const [stateEdit, setStateEdit] = useState();
    const [locationEdit, setLocationEdit] = useState();
    const [logoEdit, setLogoEdit] = useState();
    const [nameLogoEdit, setNameLogoEdit] = useState();
    const [colorEdit, setColorEdit] = useState();

    const [shop, setShop] = useState();

    const [isLoading, setIsLoading] = useState(true);

    const [errors, setErrors] = useState({
        name: '',
        businessName: '',
        phoneNumber: '',
        address: '',
        state: '',
        location: '',
        color: ''
    });

    const [errorsEdit, setErrorsEdit] = useState({
        nameEdit: '',
        businessNameEdit: '',
        phoneNumberEdit: '',
        addressEdit: '',
        stateEdit: '',
        locationEdit: '',
        colorEdit: ''
    });

    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };

        if (!name) {
            newErrors.name = 'Campo obligatorio';
            isValid = false;
        } else {
            newErrors.name = '';
        }

        if (!businessName) {
            newErrors.businessName = 'Campo obligatorio';
            isValid = false;
        } else {
            newErrors.businessName = '';
        }

        if (!phoneNumber) {
            newErrors.phoneNumber = 'Campo obligatorio';
            isValid = false;
        } else {
            newErrors.phoneNumber = '';
        }

        if (!address) {
            newErrors.address = 'Campo obligatorio';
            isValid = false;
        } else {
            newErrors.address = '';
        }

        if (!state) {
            newErrors.state = 'Campo obligatorio';
            isValid = false;
        } else {
            newErrors.state = '';
        }

        if (!location) {
            newErrors.location = 'Campo obligatorio';
            isValid = false;
        } else {
            newErrors.location = '';
        }

        if (!color) {
            newErrors.color = 'Campo obligatorio';
            isValid = false;
        } else {
            newErrors.color = '';
        }

        setErrors(newErrors);
        return isValid;
    };

    const validateFormEdit = () => {
        let isValid = true;
        const newErrors = { ...errorsEdit };

        if (!nameEdit) {
            newErrors.nameEdit = 'Campo obligatorio';
            isValid = false;
        } else {
            newErrors.nameEdit = '';
        }

        if (!businessNameEdit) {
            newErrors.businessNameEdit = 'Campo obligatorio';
            isValid = false;
        } else {
            newErrors.businessNameEdit = '';
        }

        if (!phoneNumberEdit) {
            newErrors.phoneNumberEdit = 'Campo obligatorio';
            isValid = false;
        } else {
            newErrors.phoneNumberEdit = '';
        }

        if (!addressEdit) {
            newErrors.addressEdit = 'Campo obligatorio';
            isValid = false;
        } else {
            newErrors.addressEdit = '';
        }

        if (!stateEdit) {
            newErrors.stateEdit = 'Campo obligatorio';
            isValid = false;
        } else {
            newErrors.stateEdit = '';
        }

        if (!locationEdit) {
            newErrors.locationEdit = 'Campo obligatorio';
            isValid = false;
        } else {
            newErrors.locationEdit = '';
        }

        if (!colorEdit) {
            newErrors.colorEdit = 'Campo obligatorio';
            isValid = false;
        } else {
            newErrors.colorEdit = '';
        }

        setErrorsEdit(newErrors);
        return isValid;
    };

    const validateFormEditBool = () => {

        if (!nameEdit || !businessNameEdit || !phoneNumberEdit || !addressEdit || !stateEdit || !locationEdit || !logoEdit || !colorEdit){
            return false;
        }else{
            return true;
        }
        
    };

    const addShop = () => {
        if (validateForm()) {
            const newShop = {
                name: name,
                address: address,
                phone: phoneNumber,
                state: state,
                location: location,
                businessName: businessName,
                pathLogo: nameLogo,
                principalColorShop: color,
                image: logo
            };

            axios.post(process.env.REACT_APP_API_SHOP_ROUTE, newShop, {
                withCredentials: true,
                headers: {
                  Authorization: localStorage.getItem(keyLocalStorage),
                }
              })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Datos cargados.',
                        timer: 1500,
                        showConfirmButton: false,
                      });
                      window.location.reload();
                })
                .catch(error => {
                    alertError();
                    console.error(error.response.data.errorMsg);
                });

            const closeButton = document.getElementById('btnCerrarModalAdd');
            closeButton.click();
        }
    };

    const editShop = () => {
        if (validateFormEdit()) {
            const editShop = {
                name: nameEdit,
                address: addressEdit,
                phone: phoneNumberEdit,
                state: stateEdit,
                location: locationEdit,
                businessName: businessNameEdit,
                pathLogo: nameLogoEdit,
                principalColorShop: colorEdit,
                image: logoEdit
            };

            axios.put(process.env.REACT_APP_API_SHOP_ROUTE + '/1', editShop, {
                withCredentials: true,
                headers: {
                  Authorization: localStorage.getItem(keyLocalStorage),
                }
              })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Datos editados.',
                        timer: 1500,
                        showConfirmButton: false,
                      });
                    getShop();
                })
                .catch(error => {
                    alertError();
                    console.error(error.response.data.errorMsg);
                });

            const closeButton = document.getElementById('btnCloseModalEdit');
            closeButton.click();
        }
    };

    const [shopInfo, setShopInfo] = useState({});

    useEffect(() => {
        getShop();
    }, []);

    const getShop = () =>{
        axiosService.getWithCredentials(process.env.REACT_APP_API_SHOP_ROUTE + '/1')
            .then(response => setShopInfo(response.data))
            .catch(error => console.error(error.response.data.errorMsg))
            .finally(() => setIsLoading(false));
    }

    function alertError(){
        Swal.fire({
            title: 'Upsss',
            text: 'Ocurrió un error, vuelve a intentar',
            icon: 'error',
            timer: 1500,
            showConfirmButton: false,
        });
    }

    const resetErrorsAndValues = () => {
        setName('');
        setBusinessName('');
        setPhoneNumber('');
        setAddress('');
        setState('');
        setLocation('');
        setLogo('');
        setColor('');

        resetErrors();
    };

    const resetErrors = () => {
        setErrors({
            name: '',
            businessName: '',
            phoneNumber: '',
            address: '',
            state: '',
            location: '',
            logo: '',
            color: ''
        });
    };

    const resetErrorsEdit = () => {
        setErrorsEdit({
            nameEdit: '',
            businessNameEdit: '',
            phoneNumberEdit: '',
            addressEdit: '',
            stateEdit: '',
            locationEdit: '',
            logoEdit: '',
            colorEdit: ''
        });
    };

    const setValuesEdit = () =>{
        setNameEdit(name);
        setBusinessNameEdit(businessName);
        setAddressEdit(address);
        setPhoneNumberEdit(phoneNumber);
        setStateEdit(state);
        setLocationEdit(location);
        setLogoEdit(logo);
        setColorEdit(color);
        setNameLogoEdit(nameLogo);
    }

    const codedAndSetLogo = (event) => {
        const file = event.target.files[0];
        setNameLogo(file.name);
    
        const reader = new FileReader();
        reader.onload = () => {
            const arrayBuffer = reader.result; // Resultado en ArrayBuffer
    
            // Convertir el ArrayBuffer a base64
            const base64Image = arrayBufferToBase64(arrayBuffer);
    
            // Llamar a la función para enviar la imagen a la API con el Base64
            setLogo(base64Image);
        };
        reader.readAsArrayBuffer(file);
    };
    
    const arrayBufferToBase64 = (arrayBuffer) => {
        const bytes = new Uint8Array(arrayBuffer);
        let binary = '';
        for (let i = 0; i < bytes.byteLength; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    };

    const codedAndSetLogoEdit = (event) => {
        const file = event.target.files[0];
        setNameLogoEdit(file.name);
    
        const reader = new FileReader();
        reader.onload = () => {
            const arrayBuffer = reader.result; // Resultado en ArrayBuffer
    
            // Convertir el ArrayBuffer a base64
            const base64Image = arrayBufferToBase64(arrayBuffer);
    
            // Llamar a la función para enviar la imagen a la API con el Base64
            setLogoEdit(base64Image);
        };
        reader.readAsArrayBuffer(file);
    };

        if (isLoading) {
            return <Loading/>; // Muestra un mensaje de carga mientras se realiza la petición
        }

        return (
            <></>
/*            <div className="shopContainer">
                <ShopTitle />

                {(shop != null && shop != '') ? (
                    <>
                        <ShopGenericPanel title={'Información'}data={shopGeneralInfoPanel} />
                        <ShopGenericPanel title={'Localización'}data={shopLocationInfoPanel} />
                        <ShopGenericPanel title={'Identidad Gráfica'}data={shopIdentidadGrafica} />


                        <div className="shopEditButtonContainer">
                            <button data-bs-toggle="modal" data-bs-target="#modaleEditShop" className="shopEditButton" onClick={setValuesEdit}>Editar datos</button>
                        </div>                    
                    </>
                    ) : (
                        <>
                        <div className="divNoShop">
                            <h2 className='textNoShop'>¡El primer paso para digitalizar <br/> tu negocio es completar con <br/> los datos de tu local!</h2>
                            <button data-bs-toggle="modal" data-bs-target="#modaleCreateShop" className="btnCreateShop btn">Ingresar datos</button>
                        </div>

                        <div className="modal fade modal-lg" id="modaleCreateShop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modaleCreateShopLabel" aria-hidden="true">
                            <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5 titleModalCreateShop" id="modaleCreateShopLabel">Completa con los datos de tu local</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={resetErrorsAndValues}></button>
                                </div>
                                <div className="modal-body row sacarGutter">

                                    <div className="mb-3 col-5" style={{marginRight: '15%'}}>
                                        <label htmlFor="name" className="col-form-label">Nombre:</label>
                                        {(errors.name != '' && (name == '' || name == null)) ? 
                                            <input type="text" className="form-control border-error" id="name" value={name} onChange={(e) => {
                                                const nameIng = e.target.value;
                                                if(nameIng.length <= 255){
                                                    setName(nameIng)
                                                }}}/> : 
                                            <input type="text" className="form-control" id="name" value={name} onChange={(e) => {
                                                const nameIng = e.target.value;
                                                if(nameIng.length <= 255){
                                                    setName(nameIng)
                                                }}}/>}
                                        {errors.name != '' && (name == '' || name == null) && <div className="fieldRequiredNewError">{errors.name}</div>}
                                    </div>

                                    <div className="mb-3 col-5">
                                        <label htmlFor="businessName" className="col-form-label">Razón social:</label>
                                        {(errors.businessName != '' && (businessName == '' || businessName == null)) ? 
                                            <input type="text" className="form-control border-error" id="businessName" value={businessName} onChange={(e) => {
                                                const businessNameIng = e.target.value;
                                                if(businessNameIng.length <= 255){
                                                setBusinessName(businessNameIng); 
                                                }}}/> : 
                                            <input type="text" className="form-control" id="businessName" value={businessName} onChange={(e) => {
                                                const businessNameIng = e.target.value;
                                                if(businessNameIng.length <= 255){
                                                setBusinessName(businessNameIng); 
                                                }}}/>}
                                        {errors.businessName && (businessName == '' || businessName == null) && <div className="fieldRequiredNewError">{errors.businessName}</div>}
                                    </div>

                                    <div className="mb-3 col-5" style={{marginRight: '15%'}}>
                                        <label htmlFor="phoneNumber" className="col-form-label">Número de teléfono:</label>
                                        {(errors.phoneNumber != '' && (phoneNumber == '' || phoneNumber == null)) ? 
                                            <input type="number" className="form-control border-error" id="phoneNumber" value={phoneNumber} onChange={(e) => {
                                                const phoneNumberIng = e.target.value;
                                                if(phoneNumberIng.length <= 255){
                                                    setPhoneNumber(phoneNumberIng);
                                                }}}/> : 
                                            <input type="number" className="form-control" id="phoneNumber" value={phoneNumber} onChange={(e) => {
                                                const phoneNumberIng = e.target.value;
                                                if(phoneNumberIng.length <= 255){
                                                    setPhoneNumber(phoneNumberIng);
                                                }}}/>}
                                        {errors.phoneNumber && (phoneNumber == '' || phoneNumber == null) && <div className="fieldRequiredNewError">{errors.phoneNumber}</div>}
                                    </div>

                                    <div className="mb-3 col-5">
                                        <label htmlFor="address" className="col-form-label">Dirección:</label>
                                        {(errors.address != '' && (address == '' || address == null)) ? 
                                            <input type="text" className="form-control border-error" id="address" value={address} onChange={(e) => {
                                                const addressIng = e.target.value;
                                                if(addressIng.length <=255){
                                                    setAddress(addressIng);
                                                }}}/> : 
                                            <input type="text" className="form-control" id="address" value={address} onChange={(e) => {
                                                const addressIng = e.target.value;
                                                if(addressIng.length <=255){
                                                    setAddress(addressIng);
                                                }}}/>}
                                        {errors.address && (address == '' || address == null) && <div className="fieldRequiredNewError">{errors.address}</div>}
                                    </div>

                                    <div className="mb-3 col-5" style={{marginRight: '15%'}}>
                                        <label htmlFor="location" className="col-form-label">Provincia:</label>
                                        {(errors.state != '' && (state == '' || state == null)) ? 
                                            <input type="text" className="form-control border-error" id="location" value={state} onChange={(e) => {
                                                const stateIng = e.target.value;
                                                if(stateIng.length <= 255){
                                                    setState(stateIng);
                                                }}}/> : 
                                            <input type="text" className="form-control" id="location" value={state} onChange={(e) => {
                                                const stateIng = e.target.value;
                                                if(stateIng.length <= 255){
                                                    setState(stateIng);
                                                }}}/>}
                                        {errors.state && (state == '' || state == null) && <div className="fieldRequiredNewError">{errors.state}</div>}
                                    </div>

                                    <div className="mb-3 col-5">
                                        <label htmlFor="city" className="col-form-label">Ciudad:</label>
                                        {(errors.location != '' && (location == '' || location == null)) ? 
                                            <input type="text" className="form-control border-error" id="city" value={location} onChange={(e) => {
                                                const locationIng = e.target.value;
                                                if(locationIng.length <= 255){
                                                    setLocation(locationIng);
                                                }}}/> : 
                                            <input type="text" className="form-control" id="city" value={location} onChange={(e) => {
                                                const locationIng = e.target.value;
                                                if(locationIng.length <= 255){
                                                    setLocation(locationIng);
                                                }}}/>}
                                        {errors.location && (location == '' || location == null) && <div className="fieldRequiredNewError">{errors.location}</div>}
                                    </div>

                                    <div className="mb-3 col-5" style={{marginRight: '15%'}}>
                                        <label htmlFor="city" className="col-form-label">Banner:</label>
                                            <input className="form-control" type="file" id="imagen" name="imagen" accept="image/!*" onChange={codedAndSetLogo}/>
                                    </div>

                                    <div className="mb-3 col-5">
                                        <label htmlFor="colorInput" className="col-form-label">Color principal:</label>
                                        {(errors.color != '' && (color == '' || color == null)) ? 
                                            <input type="color" className="inputColor border-error" id="colorInput" name="colorInput" value={color} onChange={(e) => setColor(e.target.value)}/> : 
                                            <input type="color" className="inputColor" id="colorInput" name="colorInput" value={color} onChange={(e) => setColor(e.target.value)}/>}
                                        {errors.color && (color == '' || color == null) && <div className="fieldRequiredNewError">{errors.color}</div>}
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button id='btnCerrarModalAdd' type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={resetErrorsAndValues}>Cerrar</button>
                                    <button type="button" className="btn btn-primary" onClick={addShop}>Guardar</button>
                                </div>
                            </div>
                            </div>
                        </div>
                        </>
                    )}

                <div className="modal fade modal-lg" id="modaleEditShop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modaleEditShopLabel" aria-hidden="true">
                    <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 titleModalEditShop" id="modaleEditShopLabel">Editar</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={resetErrorsEdit}></button>
                        </div>
                        <div className="modal-body row sacarGutter">

                            <div className="mb-3 col-5" style={{marginRight: '15%'}}>
                                <label htmlFor="nameEdit" className="col-form-label">Nombre:</label>
                                {(errorsEdit.nameEdit != '' && (nameEdit == '' || nameEdit == null)) ? 
                                    <input type="text" className="form-control border-error" id="nameEdit" value={nameEdit} onChange={(e) => {
                                        const nameIngEdit = e.target.value;
                                        if(nameIngEdit.length <= 255){
                                            setNameEdit(nameIngEdit);
                                        }}}/> : 
                                    <input type="text" className="form-control" id="nameEdit" value={nameEdit} onChange={(e) => {
                                        const nameIngEdit = e.target.value;
                                        if(nameIngEdit.length <= 255){
                                            setNameEdit(nameIngEdit);
                                        }}}/>}
                                {errorsEdit.nameEdit != '' && (nameEdit == '' || nameEdit == null) && <div className="fieldRequiredNewError">{errorsEdit.nameEdit}</div>}
                            </div>

                            <div className="mb-3 col-5">
                                <label htmlFor="businessNameEdit" className="col-form-label">Razón social:</label>
                                {(errorsEdit.businessNameEdit != '' && (businessNameEdit == '' || businessNameEdit == null)) ? 
                                    <input type="text" className="form-control border-error" id="businessNameEdit" value={businessNameEdit} onChange={(e) => {
                                        const businessNameIngEdit = e.target.value;
                                        if(businessNameIngEdit.length <= 255){
                                            setBusinessNameEdit(businessNameIngEdit);
                                        }}}/>: 
                                    <input type="text" className="form-control" id="businessNameEdit" value={businessNameEdit} onChange={(e) => {
                                        const businessNameIngEdit = e.target.value;
                                        if(businessNameIngEdit.length <= 255){
                                            setBusinessNameEdit(businessNameIngEdit);
                                        }}}/>}
                                {errorsEdit.businessNameEdit && (businessNameEdit == '' || businessNameEdit == null) && <div className="fieldRequiredNewError">{errorsEdit.businessNameEdit}</div>}
                            </div>

                            <div className="mb-3 col-5" style={{marginRight: '15%'}}>
                                <label htmlFor="phoneNumberEdit" className="col-form-label">Número de teléfono:</label>
                                {(errorsEdit.phoneNumberEdit != '' && (phoneNumberEdit == '' || phoneNumberEdit == null)) ? 
                                    <input type="number" className="form-control border-error" id="phoneNumberEdit" value={phoneNumberEdit} onChange={(e) => {
                                        const phoneNumberIngEdit = e.target.value;
                                        if(phoneNumberIngEdit.length <= 255){
                                            setPhoneNumberEdit(phoneNumberIngEdit);
                                        }}}/>: 
                                    <input type="number" className="form-control" id="phoneNumberEdit" value={phoneNumberEdit} onChange={(e) => {
                                        const phoneNumberIngEdit = e.target.value;
                                        if(phoneNumberIngEdit.length <= 255){
                                            setPhoneNumberEdit(phoneNumberIngEdit);
                                        }}}/>}
                                {errorsEdit.phoneNumberEdit && (phoneNumberEdit == '' || phoneNumberEdit == null) && <div className="fieldRequiredNewError">{errorsEdit.phoneNumberEdit}</div>}
                            </div>

                            <div className="mb-3 col-5">
                                <label htmlFor="addressEdit" className="col-form-label">Dirección:</label>
                                {(errorsEdit.addressEdit != '' && (addressEdit == '' || addressEdit == null)) ? 
                                    <input type="text" className="form-control border-error" id="addressEdit" value={addressEdit} onChange={(e) => {
                                        const addressIngEdit = e.target.value;
                                        if(addressIngEdit.length <= 255){
                                            setAddressEdit(addressIngEdit);
                                        }}}/>: 
                                    <input type="text" className="form-control" id="addressEdit" value={addressEdit} onChange={(e) => {
                                        const addressIngEdit = e.target.value;
                                        if(addressIngEdit.length <= 255){
                                            setAddressEdit(addressIngEdit);
                                        }}}/>}
                                {errorsEdit.addressEdit && (addressEdit == '' || addressEdit == null) && <div className="fieldRequiredNewError">{errorsEdit.addressEdit}</div>}
                            </div>

                            <div className="mb-3 col-5" style={{marginRight: '15%'}}>
                                <label htmlFor="stateEdit" className="col-form-label">Provincia:</label>
                                {(errorsEdit.stateEdit != '' && (stateEdit == '' || stateEdit == null)) ? 
                                    <input type="text" className="form-control border-error" id="stateEdit" value={stateEdit} onChange={(e) => {
                                        const stateIngEdit = e.target.value;
                                        if(stateIngEdit.length <= 255){
                                        setStateEdit(stateIngEdit); 
                                        }}}/>: 
                                    <input type="text" className="form-control" id="stateEdit" value={stateEdit} onChange={(e) => {
                                        const stateIngEdit = e.target.value;
                                        if(stateIngEdit.length <= 255){
                                        setStateEdit(stateIngEdit); 
                                        }}}/>}
                                {errorsEdit.stateEdit && (stateEdit == '' || stateEdit == null) && <div className="fieldRequiredNewError">{errorsEdit.stateEdit}</div>}
                            </div>

                            <div className="mb-3 col-5">
                                <label htmlFor="locationEdit" className="col-form-label">Ciudad:</label>
                                {(errorsEdit.locationEdit != '' && (locationEdit == '' || locationEdit == null)) ? 
                                    <input type="text" className="form-control border-error" id="locationEdit" value={locationEdit} onChange={(e) => {
                                        const locationIngEdit = e.target.value;
                                        if(locationIngEdit.length <=255){
                                            setLocationEdit(locationIngEdit);
                                        }}}/>: 
                                    <input type="text" className="form-control" id="locationEdit" value={locationEdit} onChange={(e) => {
                                        const locationIngEdit = e.target.value;
                                        if(locationIngEdit.length <=255){
                                            setLocationEdit(locationIngEdit);
                                        }}}/>}
                                {errorsEdit.locationEdit && (locationEdit == '' || locationEdit == null) && <div className="fieldRequiredNewError">{errorsEdit.locationEdit}</div>}
                            </div>

                            <div className="mb-3 col-5" style={{marginRight: '15%'}}>
                                <label htmlFor="logoEdit" className="col-form-label">Banner:</label>
                                    {nameLogoEdit && <p>Nombre del archivo: {nameLogoEdit}</p>}
                                    <input className="form-control" type="file" id="imagen" name="imagen" accept="image/!*" onChange={codedAndSetLogoEdit}/>
                            </div>

                            <div className="mb-3 col-5">
                                <label htmlFor="colorInputEdit" className="col-form-label">Color principal:</label>
                                {(errorsEdit.colorEdit != '' && (colorEdit == '' || colorEdit == null)) ? 
                                    <input type="color" className="inputColor border-error" id="colorInputEdit" name="colorInputEdit" value={colorEdit} onChange={(e) => setColorEdit(e.target.value)}/>: 
                                    <input type="color" className="inputColor" id="colorInputEdit" name="colorInputEdit" value={colorEdit} onChange={(e) => setColorEdit(e.target.value)}/>}
                                {errorsEdit.colorEdit && (colorEdit == '' || colorEdit == null) && <div className="fieldRequiredNewError">{errorsEdit.colorEdit}</div>}
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button id='btnCloseModalEdit' type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={resetErrorsEdit}>Cerrar</button>
                            <button type="button" className="btn btn-primary" onClick={editShop}>Guardar</button>
                        </div>
                    </div>
                    </div>
                </div>
                
            </div>*/
        );
}