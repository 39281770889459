import React, {useEffect, useState} from 'react';
import {CloseLarge, RequestQuote, Search, TrashCan, View, ViewOff} from '@carbon/icons-react';
import Swal from 'sweetalert2';
import axios from 'axios';
import * as AxiosService from '../services/AxiosService';
import * as ConversorService from '../services/ConversorService';
import * as DownloadService from '../services/DownloadService';
import {Modal} from "react-bootstrap";
import {Button, Col, Container, Pagination, Row, Table} from "react-bootstrap";

export default function MisProductos() {
    const keyLocalStorage = 'authToken';

    // Estados para paginado de todos los productos
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [currentNumberOfElements, setCurrentNumberOfElements] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    // Estados de Filtros
    const [productNameFilter, setProductNameFilter] = useState("");
    const [categoryIdFilter, setCategoryIdFilter] = useState("");

    //Lista de todas las categorias
    const [categories, setCategories] = useState([]);
    //list categorias activas
    const [categoriesActive, setCategoriesActive] = useState([]);

    //Lista de productos filtrados
    const [searching, setSearching] = useState(false);

    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');

    const [productIdEdit, setProductIdEdit] = useState('');
    const [productNameEdit, setProductNameEdit] = useState('');
    const [productDescriptionEdit, setProductDescriptionEdit] = useState('');
    const [productPriceEdit, setProductPriceEdit] = useState('');
    const [selectedCategoryEdit, setSelectedCategoryEdit] = useState('');
    const [productStatusEdit, setProductStatusEdit] = useState('');

    const [fileMassiveProducts, setFileMassiveProducts] = useState(null);
    const [fileNameMassiveProducts, setFileNameMassiveProducts] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const [actualPage, setActualPage] = useState(1);
    const productsPerPage = 10;
    let indexOfLastProduct = actualPage * productsPerPage;

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [showModalEditImageProduct, setShowModalEditImageProduct] = useState(false);
    const [showModalEditProduct, setShowModalEditProduct] = useState(false);

    const [selectedImage, setSelectedImage] = useState(null);
    const [isUpdateImage, setIsUpdateImage] = useState(null);
    const [productImage, setProductImage] = useState(null);
    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            setSelectedImage(file);
        }
    };

    const [selectedProductsToEdit, setSelectedProductsToEdit] = useState([]);

    const [adjustmentType, setAdjustmentType] = useState(''); // 'aumentar' o 'disminuir'
    const [valueType, setValueType] = useState(''); // 'porcentaje' o 'valor'
    const [adjustmentValue, setAdjustmentValue] = useState(); // Valor del ajuste

    const [showModalEditPrice, setShowModalEditPrice] = useState(false);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const handleSelectProduct = (productId) => {
        if (selectedProductsToEdit.includes(productId)) {
            // Si el producto ya está en la lista, lo eliminamos
            setSelectedProductsToEdit(selectedProductsToEdit.filter(id => id !== productId));
        } else {
            // Si no está en la lista, lo agregamos
            setSelectedProductsToEdit([...selectedProductsToEdit, productId]);
        }
    };

    const handleImageUpload = () => {
        setIsUpdateImage(true);
        // Validar si se ha seleccionado una imagen
        if (!selectedImage) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No se ha seleccionado ninguna imagen.',
            });
            setIsUpdateImage(false);
            return;
        }

        // Validar el tamaño de la imagen (máximo 1MB)
        const maxFileSize = 1 * 1024 * 1024; // 1MB en bytes
        if (selectedImage.size > maxFileSize) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'El archivo excede el tamaño máximo permitido de 1MB.',
            });
            setIsUpdateImage(false);
            return;
        }

        const formData = new FormData();
        formData.append('productId', productIdEdit);
        formData.append('image', selectedImage);

        axios.post(`${process.env.REACT_APP_API_PRODUCTS_IMAGES_ROUTE}`, formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: localStorage.getItem(keyLocalStorage),
            },
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Imagen cargada correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                setShowModalEditImageProduct(false);
                setShowModalEditProduct(true);
            })
            .catch(error => {
                const errorMessage = error.response?.data || 'Hubo un problema al cargar la imagen.';
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorMessage,
                });
                console.error(error);
            }).finally(() => {
            setIsUpdateImage(false);
        });
    };

    const deleteImage = () => {
        axios.delete(process.env.REACT_APP_API_PRODUCTS_IMAGES_GET_ROUTE + productIdEdit.toString(), {
            responseType: 'arraybuffer',
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Imagen eliminada.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                setShowModalEditImageProduct(false);
                setShowModalEditProduct(true);
            })
            .catch(error => {
                alertError();
                console.error(error);
            });
    }

    const fetchProductImage = (productId) => {
        axios.get(process.env.REACT_APP_API_PRODUCTS_IMAGES_GET_ROUTE + productId.toString(), {
            responseType: 'arraybuffer',
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
        .then(response => {
            if (response.data && response.data.byteLength > 0){
                const imageBlob = new Blob([response.data], { type: 'image/webp' });
                const imageUrl = URL.createObjectURL(imageBlob);
                setProductImage(imageUrl);
            } else {
                setProductImage(null);
            }

        })
        .catch(error => {
            alertError();
            console.error(error);
            setProductImage(null);
        });
    };

    useEffect(() => {
        document.title = 'QReate - Productos';
        if (localStorage.getItem(keyLocalStorage) != null) {
            getPage();
            getAllCategories();
        }
    }, [currentPage, isLoading]);

    // Armado de la página
    const getPage = () => {
        axios.get(process.env.REACT_APP_API_PRODUCTS_PAGE_ROUTE, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            },
            params: {
                page: currentPage,
                name: productNameFilter,
                category: categoryIdFilter
            }
        })
            .then(response => {
                console.warn(response);
                setProducts(response.data.content);
                setTotalPages(response.data.totalPages);
                setCurrentPage(response.data.number + 1);
                setCurrentNumberOfElements(response.data.numberOfElements)
                setTotalElements(response.data.totalElements)
            })
            .catch(error => {
                alertError();
                console.warn(error)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleSelectCategory = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleSelectCategoryEdit = (event) => {
        setSelectedCategoryEdit(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            getPage()
        }
    };

    const saveEditPriceProduct = () => {
        let editPriceProduct = {
            productIds: selectedProductsToEdit,
            adjustmentType: adjustmentType,
            valueType: valueType,
            value: adjustmentValue,
        }

        axios.put(process.env.REACT_APP_API_PRODUCTS_EDIT_PRICE_ROUTE, editPriceProduct, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Editado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                setAdjustmentType('');
                setAdjustmentValue('');
                setValueType('');
                setSelectedProductsToEdit([]);
                setShowModalEditPrice(false);
                getPage();
            })
            .catch(error => {
                alertError();
            }).finally(() => {
                setLoadingEdit(false);
            });
    }

    const saveProduct = () => {
        if (productName == '' || productName == null || selectedCategory == '' || selectedCategory == null || productPrice == '' || productPrice == null) {
            if (productName == '' || productName == null) {
                let fieldRequiredElement = document.querySelector('.fieldRequiredName');
                fieldRequiredElement.style.display = 'block';
                let inputElement = document.getElementById('nombre');
                inputElement.style.border = '1px solid #ff1717';
            }
            if (selectedCategory == '' || selectedCategory == null) {
                let fieldRequiredElement = document.querySelector('.fieldRequiredCategory');
                fieldRequiredElement.style.display = 'block';
                let inputElement = document.getElementById('selector');
                inputElement.style.border = '1px solid #ff1717';
            }
            if (productPrice == '' || productPrice == null) {
                let fieldRequiredElement = document.querySelector('.fieldRequiredPrice');
                fieldRequiredElement.style.display = 'block';
                let inputElement = document.getElementById('price');
                inputElement.style.border = '1px solid #ff1717';
            }
        } else {
            let newProduct = {
                name: productName,
                description: productDescription,
                price: productPrice,
                idCategory: selectedCategory
            }

            axios.post(process.env.REACT_APP_API_PRODUCTS_ROUTE, newProduct, {
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem(keyLocalStorage),
                }
            })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardada correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                    const closeButton = document.getElementById('btnCloseModalAdd');
                    closeButton.click();
                    getPage();
                })
                .catch(error => {
                    alertError();
                    console.error(error)
                });

            setProductName('');
            setProductDescription('');
            setSelectedCategory('');
            setProductPrice('');
        }
    }



    function alertError() {
        Swal.fire({
            title: 'Upsss',
            text: 'Ocurrió un error, vuelve a intentar',
            icon: 'error',
            timer: 1500,
            showConfirmButton: false,
        });
    }

    function alertErrorConMensaje(mensaje) {
        Swal.fire({
            title: 'Error',
            text: mensaje,
            icon: 'error',
            showConfirmButton: true,
            confirmButtonColor: 'rgb(246, 139, 127)',
            allowOutsideClick: false,  // Evita que se cierre haciendo clic fuera del mensaje
            allowEscapeKey: false,  // Evita que se cierre pulsando la tecla Esc
            allowEnterKey: false  // Evita que se cierre pulsando la tecla Enter
        });
    }

    const deleteProduct = (product) => {
        Swal.fire({
            title: '¿Estás seguro de eliminar el producto?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: 'rgb(246, 139, 127)',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(process.env.REACT_APP_API_PRODUCTS_ROUTE + '/' + product.id, {
                    withCredentials: true,
                    headers: {
                        Authorization: localStorage.getItem(keyLocalStorage),
                    }
                })
                    .then(response => {
                        Swal.fire({
                            icon: 'success',
                            title: '¡Éxito!',
                            text: 'Eliminado correctamente.',
                            timer: 1500,
                            showConfirmButton: false,
                        });
                        getPage();
                    })
                    .catch(error => {
                        alertError();
                        console.error('Error al realizar la solicitud PUT', error);
                    });
            }
        });
    }

    const editProduct = (product) => {
        setProductIdEdit(product.id);
        setProductNameEdit(product.name);
        setProductDescriptionEdit(product.description);
        setProductPriceEdit(product.price);
        setSelectedCategoryEdit(product.idCategory);
        setProductStatusEdit(product.status);
    }

    const saveEditproduct = () => {
        if (productNameEdit == '' || productPriceEdit == '' || selectedCategoryEdit == '') {
            if (productNameEdit == '' || productNameEdit == null) {
                let fieldRequiredElement = document.querySelector('.fieldRequiredNameEdit');
                fieldRequiredElement.style.display = 'block';
                let inputElement = document.getElementById('nombreEdit');
                inputElement.style.border = '1px solid #ff1717';
            }
            if (selectedCategoryEdit == '' || selectedCategoryEdit == null) {
                let fieldRequiredElement = document.querySelector('.fieldRequiredCategoryEdit');
                fieldRequiredElement.style.display = 'block';
                let inputElement = document.getElementById('selectorEdit');
                inputElement.style.border = '1px solid #ff1717';
            }
            if (productPriceEdit == '' || productPriceEdit == null) {
                let fieldRequiredElement = document.querySelector('.fieldRequiredPriceEdit');
                fieldRequiredElement.style.display = 'block';
                let inputElement = document.getElementById('priceEdit');
                inputElement.style.border = '1px solid #ff1717';
            }
        } else {
            let editProduct = {
                name: productNameEdit,
                description: productDescriptionEdit,
                price: productPriceEdit,
                idCategory: selectedCategoryEdit,
                status: productStatusEdit == "ACTIVO" ? 1 : 0
            }

            axios.put(process.env.REACT_APP_API_PRODUCTS_ROUTE + '/' + productIdEdit, editProduct, {
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem(keyLocalStorage),
                }
            })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Editada correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                    getPage();
                    if (searching) {
                        setTimeout(() => {
                            const reloadSearch = document.getElementById('btnReloadSearchProduct');
                            reloadSearch.click();
                        }, 1500);
                    }
                })
                .catch(error => {
                    alertError();
                    console.error(error)
                });

            const closeButton = document.getElementById('btnCloseModalEdit');
            closeButton.click();

            setProductIdEdit('');
            setProductNameEdit('');
            setProductDescriptionEdit('');
            setProductPriceEdit('');
            setSelectedCategoryEdit('');
            setProductStatusEdit('');
        }
    }

    const getAllCategories = () => {
        axios.get(process.env.REACT_APP_API_CATEGORIES_ROUTE, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
            .then(response => {
                setCategories(response.data);
                setCategoriesActive(response.data.filter((categoria) => categoria.status));
            })
            .catch(error => {
                alertError();
                console.error(error)
            });
    }

    const resetValuesAndStyleErrors = () => {
        resetStyleErrorName();
        resetStyleErrorDesc();
        resetStyleErrorCat();
        resetStyleErrorPrice();

        setProductName('');
        setProductDescription('');
        setSelectedCategory('');
        setProductPrice('');
    }

    const resetStyleErrorsEdit = () => {
        resetStyleErrorNameEdit();
        resetStyleErrorDescEdit();
        resetStyleErrorCatEdit();
        resetStyleErrorPriceEdit();
    }

    const cleanErrorName = () => {
        if (productName != '' && productName != null) {
            resetStyleErrorName();
        }
    }

    const resetStyleErrorName = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredName');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('nombre');
        inputElement.style.border = '';
    }

    const cleanErrorNameEdit = () => {
        if (productNameEdit != '' && productNameEdit != null) {
            resetStyleErrorNameEdit();
        }
    }

    const resetStyleErrorNameEdit = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredNameEdit');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('nombreEdit');
        inputElement.style.border = '';
    }

    const cleanErrorDesc = () => {
        if (productDescription != '' && productDescription != null) {
            resetStyleErrorDesc();
        }
    }

    const resetStyleErrorDesc = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredDescription');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('description');
        inputElement.style.border = '';
    }

    const resetStyleErrorCat = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredCategory');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('selector');
        inputElement.style.border = '';
    }

    const cleanErrorDescEdit = () => {
        if (productDescriptionEdit != '' && productDescriptionEdit != null) {
            resetStyleErrorDescEdit();
        }
    }

    const resetStyleErrorDescEdit = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredDescriptionEdit');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('descriptionEdit');
        inputElement.style.border = '';
    }

    const resetStyleErrorCatEdit = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredCategoryEdit');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('selectorEdit');
        inputElement.style.border = '';
    }

    const cleanErrorPrice = () => {
        if (productPrice != '' && productPrice != null) {
            resetStyleErrorPrice();
        }
    }

    const resetStyleErrorPrice = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredPrice');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('price');
        inputElement.style.border = '';
    }

    const cleanErrorPriceEdit = () => {
        if (productPriceEdit != '' && productPriceEdit != null) {
            resetStyleErrorPriceEdit();
        }
    }

    const resetStyleErrorPriceEdit = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredPriceEdit');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('priceEdit');
        inputElement.style.border = '';
    }

    const cleanFilters = () => {
        setProductNameFilter("");
        setCategoryIdFilter("");
        setIsLoading(true);
        getPage();
    }

    const isCategoryActive = (id) => {
        return categoriesActive.filter((categoria) => categoria.id == id).length > 0;
    };

    const enableProd = (product) => {
        if (product.idCategory != null && isCategoryActive(product.idCategory)) {
            let editProduct = {
                name: product.name,
                description: product.description,
                imgPath: product.imgPath,
                status: 1,
                price: product.price,
                idCategory: product.idCategory
            }

            axios.put(process.env.REACT_APP_API_PRODUCTS_ROUTE + '/' + product.id, editProduct, {
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem(keyLocalStorage),
                }
            })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Activado correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                    getPage();
                    if (searching) {
                        setTimeout(() => {
                            const reloadSearch = document.getElementById('btnReloadSearchProduct');
                            reloadSearch.click();
                        }, 1500);
                    }

                })
                .catch(error => {
                    alertError();
                    console.error(error)
                });
        } else {
            Swal.fire({
                title: 'Error',
                text: "No se puede ACTIVAR un producto sin CATEORIA O con CATEGORÍA INACTIVA",
                icon: 'error',
                showConfirmButton: true,
                confirmButtonColor: 'rgb(246, 139, 127)',
                allowOutsideClick: false,  // Evita que se cierre haciendo clic fuera del mensaje
                allowEscapeKey: false,  // Evita que se cierre pulsando la tecla Esc
                allowEnterKey: false  // Evita que se cierre pulsando la tecla Enter
            });
        }
    }

    const disableProd = (product) => {
        let editProduct = {
            name: product.name,
            description: product.description,
            imgPath: product.imgPath,
            status: 0,
            price: product.price,
            idCategory: product.idCategory
        }

        axios.put(process.env.REACT_APP_API_PRODUCTS_ROUTE + '/' + product.id, editProduct, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Desactivado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                getPage();
                if (searching) {
                    setTimeout(() => {
                        const reloadSearch = document.getElementById('btnReloadSearchProduct');
                        reloadSearch.click();
                    }, 1500);
                }
            })
            .catch(error => {
                alertError();
                console.error(error)
            });
    }

    function formatPrice(price) {
        if (!price) return ''; // Manejar caso de valor vacío
        const parts = price.toString().split('.'); // Separar parte entera de la decimal
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agregar separador de miles
        return parts.join(','); // Unir las partes con coma como separador decimal
    }

    const resetStyleErrorFile = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredFile');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('fileMassiveProducts');
        inputElement.style.border = '';
        setFileMassiveProducts('');
        setFileNameMassiveProducts('');
    }

    const addProductsMassive = () => {
        if (fileMassiveProducts == '' || fileMassiveProducts == null) {
            let fieldRequiredElement = document.querySelector('.fieldRequiredFile');
            fieldRequiredElement.style.display = 'block';
            let inputElement = document.getElementById('fileMassiveProducts');
            inputElement.style.border = '1px solid #ff1717';
            return;
        }

        setIsButtonDisabled(true);

        const reader = new FileReader();

        reader.onload = () => {
            const base64String = reader.result.split(",")[1]; // Obtiene el string base64

            axios.post(process.env.REACT_APP_API_PRODUCTS_FILE_UPLOAD_ROUTE, base64String, {
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem(keyLocalStorage),
                    "Content-Type": "text/plain"
                }
            })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardada correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });

                    setTimeout(() => {
                        const btnCloseModal = document.getElementById('btnCloseModalAddMassive');
                        if (btnCloseModal) {
                            btnCloseModal.click();
                        }
                    }, 1800);

                    setTimeout(() => {
                        window.location.reload();
                    }, 2100);
                })
                .catch(error => {
                    setIsButtonDisabled(false);
                    alertErrorConMensaje(error.response.data.errorMsg)
                    console.error(error);
                });
        };

        reader.readAsDataURL(fileMassiveProducts); // Lee el archivo como una URL de datos

    }

    const handleDownload = async () => {
        const {data} = await AxiosService.getWithCredentialsSync(process.env.REACT_APP_API_PRODUCTS_FILE_DOWNLOAD_ROUTE); // Obtengo la data
        const blob = ConversorService.convertBase64ToBlob(data); // Conversion de la data
        DownloadService.downloadFile(blob, "ProductosUCODE", "xlsx"); // Genero y descargo el archivo
    }

    const handleFileChange = (event) => {
        setFileMassiveProducts(event.target.files[0]);
    };

    // Basicos
    const pageHeader = () => {
        return (
            <Container className="mt-3">
                <Row>
                    <Col>
                        <h1 className="titleProducts">Productos</h1>
                    </Col>
                </Row>
            </Container>
        )
    }

    // Manejo de filtros
    const filtersContainer = () => {
        return (
            <Container>
                <Row className="mt-3 justify-content-center" xs={2} md={3}>
                    <Col>
                        <input type="text" className="searchTerm" placeholder="Nombre de producto"
                               value={productNameFilter}
                               onChange={(e) => setProductNameFilter(e.target.value)}
                                onKeyUp={handleKeyPress}/>
                    </Col>
                    <Col>
                        <select
                            className="selectFilterProd"
                            value={categoryIdFilter}
                            onChange={(e) => setCategoryIdFilter(e.target.value)}
                        >
                            <option value=''>Seleccione categoría</option>
                            {categories
                                .map(category => (
                                    <option key={category.id} value={category.id}>{category.name}</option>))}
                        </select>
                    </Col>
                    <Col className="text-center text-md-start mt-3 mt-md-0 mb-sm-3 mb-md-0">
                        <Button id="btnSearchProduct" className="me-1" variant="primary" onClick={() => getPage()}>
                            <Search/>
                        </Button>
                        <Button id="btnCleanSearchProducts" className="ms-1" variant="primary" onClick={() => cleanFilters()}>
                            <CloseLarge/>
                        </Button>
                    </Col>
                </Row>
            </Container>
        )
    }

    // Manejo de tablas
    const loadingTable = () => {
        return (
            <>
                <div className="m-3">
                    <Table responsive className="table-striped">
                        <thead className="tableHead">
                        <tr>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th>Categoría</th>
                            <th>Descripción</th>
                            <th>Precio</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan="7" className="backgroundTable text-center">
                                <div className="loader-container-listado">
                                    <div className="loader"></div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            </>
        )
    }
    const paginationModule = () => {
        return (
            <Container fluid>
                <Row>
                    <Col className="d-flex align-items-center">
                        <Pagination>
                            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1}/>
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}
                                             disabled={currentPage === 1}/>
                            {currentPage > 2 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 2)}>
                                    {currentPage - 2}
                                </Pagination.Item>
                            )}
                            {currentPage > 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                                    {currentPage - 1}
                                </Pagination.Item>
                            )}
                            <Pagination.Item active>{currentPage}</Pagination.Item>
                            {currentPage < totalPages && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                                    {currentPage + 1}
                                </Pagination.Item>
                            )}
                            {currentPage < totalPages - 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 2)}>
                                    {currentPage + 2}
                                </Pagination.Item>
                            )}
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}
                                             disabled={currentPage === totalPages || categories.length === 0}/>
                            <Pagination.Last onClick={() => handlePageChange(totalPages)}
                                             disabled={currentPage === totalPages || categories.length === 0}/>
                        </Pagination>
                        <div className="ms-2">
                            <p>Mostrando {currentNumberOfElements} de {totalElements} </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    // Manejo de modales
    const modalControlButtons = () => {
        return (
            <Container className="mt-3">
                <Row>
                    <Col className="text-alig-center">
                        <Button variant="primary" className="me-1" data-bs-toggle="modal" data-bs-target="#modaleAddProduct">Agregar</Button>
                        <Button variant="primary" className="ms-1" data-bs-toggle="modal" data-bs-target="#modaleAddProductMassive">Carga Masiva</Button>
                    </Col>
                </Row>
            </Container>
        )
    }
    const addProductMassiveModal = () => {
        return (
            <div className="modal fade modal-lg" id="modaleAddProductMassive" data-bs-backdrop="static"
                 data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modaleAddProductMassiveLabel"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="modaleAddProductMassiveLabel"
                                className="titleModalCreateCategory">Carga masiva de productos</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        resetStyleErrorFile()
                                    }}></button>
                        </div>
                        <div className="modal-body">
                            <button className="btn" style={{backgroundColor: '#C65A4E', color: 'white'}}
                                    onClick={handleDownload}>
                                Descargar Template
                            </button>
                            <div style={{marginTop: '5%'}}>
                                <label htmlFor="fileMassiveProducts" className="col-form-label">Carga archivo excel con
                                    productos:</label>
                                <input type="file" className="form-control" id="fileMassiveProducts"
                                       value={fileNameMassiveProducts} onChange={(e) => {
                                    setFileNameMassiveProducts(e.target.value);
                                    handleFileChange(e);
                                }}/>
                                <div className="fieldRequiredFile" display='none'>El archivo es obligatorio
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button id="btnCloseModalAddMassive" type="button" className="btn btn-secondary"
                                    data-bs-dismiss="modal" onClick={() => {
                                resetStyleErrorFile();
                            }}>Cerrar
                            </button>
                            <button type="button" className="btn btn-primary"
                                    onClick={addProductsMassive}
                                    disabled={isButtonDisabled}>
                                {isButtonDisabled ? "Guardando..." : "Guardar"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const addProductModal = () => {
        return (
            <div className="modal fade modal-lg" id="modaleAddProduct" data-bs-backdrop="static"
                 data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modaleAddProductLabel"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="modaleAddProductLabel"
                                className="titleModalCreateProduct">Nuevo producto</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    onClick={() => resetValuesAndStyleErrors()}></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="nombre" className="col-form-label">Nombre:</label>
                                <input type="text" className="form-control" id="nombre" value={productName}
                                       onChange={(e) => {
                                           const productNameIng = e.target.value;
                                           if (productNameIng.length <= 100) {
                                               setProductName(productNameIng);
                                           }
                                           cleanErrorName();
                                       }}/>
                                <div className="fieldRequiredName">Campo obligatorio</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="description" className="col-form-label">Descripción:</label>
                                <input type="text" className="form-control" id="description"
                                       value={productDescription} onChange={(e) => {
                                    const productDescriptionIng = e.target.value;
                                    if (productDescriptionIng.length <= 255) {
                                        setProductDescription(productDescriptionIng);
                                    }
                                    cleanErrorDesc();
                                }}/>
                                <div className="fieldRequiredDescription">Campo obligatorio</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="nombre" className="col-form-label">Categoría:</label>
                                <select id="selector" className="form-control" value={selectedCategory}
                                        onChange={handleSelectCategory}>
                                    <option value=''>Seleccionar categoría</option>
                                    {categoriesActive
                                        .map(category => (
                                            <option value={category.id}>{category.name}</option>
                                        ))}
                                </select>
                                <div className="fieldRequiredCategory">Campo obligatorio</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="price" className="col-form-label">Precio:</label>
                                <div className="price-input">
                                    <p className="dollar-sign"> $ </p>
                                    <input type="number" className="form-control" id="price" value={productPrice}
                                           onChange={(e) => {
                                               const productPriceIng = e.target.value;
                                               const hasDecimal = productPriceIng.includes(".");
                                               if (hasDecimal) {
                                                   if (productPriceIng.length <= 20) {
                                                       setProductPrice(productPriceIng);
                                                   }
                                               } else {
                                                   if (productPriceIng.length <= 17) {
                                                       setProductPrice(productPriceIng);
                                                   }
                                               }
                                               cleanErrorPrice();
                                           }}/>
                                </div>
                                <div className="fieldRequiredPrice">Campo obligatorio</div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button id="btnCloseModalAdd" type="button" className="btn btn-secondary"
                                    data-bs-dismiss="modal" onClick={() => resetValuesAndStyleErrors()}>Cerrar
                            </button>
                            <button type="button" className="btn btn-primary"
                                    onClick={() => saveProduct()}>Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const editPriceMasiveProductModal = () => {
        return (
            <Modal size="l" show={showModalEditPrice} onHide={() => setShowModalEditPrice(false)}>
                <Modal.Header className="modal-header">
                    <h1 className="modal-title fs-5" id="modaleEditPriceProductsLabel"
                        className="titleModalCreateProduct">Editar precio de productos</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setAdjustmentType('');
                                setValueType('');
                                setAdjustmentValue('');
                                setShowModalEditPrice(false)
                            }}></button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    {/* Conjunto de 2 radio buttons para Aumentar o Disminuir */}
                    <div className="mb-3">
                        <label className="form-label" style={{marginBottom: '3%'}}><strong> Acción: </strong></label>
                        <div>
                            <input
                                type="radio"
                                id="aumentar"
                                name="adjustmentType"
                                value="INCREASE"
                                checked={adjustmentType === "INCREASE"}
                                onChange={() => setAdjustmentType("INCREASE")}
                            />
                            <label htmlFor="aumentar" className="ms-2">Aumentar</label>

                            <input
                                type="radio"
                                id="disminuir"
                                name="adjustmentType"
                                value="DECREASE"
                                className="ms-4"
                                checked={adjustmentType === "DECREASE"}
                                onChange={() => setAdjustmentType("DECREASE")}
                            />
                            <label htmlFor="disminuir" className="ms-2">Disminuir</label>
                        </div>
                    </div>

                    {/* Conjunto de 2 radio buttons para Porcentaje o Valor Fijo */}
                    <div className="mb-3">
                        <label className="form-label" style={{marginBottom: '3%'}}><strong> Tipo de ajuste: </strong></label>
                        <div>
                            <input
                                type="radio"
                                id="porcentaje"
                                name="valueType"
                                value="PERCENTAGE"
                                checked={valueType === "PERCENTAGE"}
                                onChange={() => setValueType("PERCENTAGE")}
                            />
                            <label htmlFor="porcentaje" className="ms-2">Porcentaje</label>

                            <input
                                type="radio"
                                id="valor"
                                name="valueType"
                                value="FIXED_VALUE"
                                className="ms-4"
                                checked={valueType === "FIXED_VALUE"}
                                onChange={() => setValueType("FIXED_VALUE")}
                            />
                            <label htmlFor="valor" className="ms-2">Valor Fijo</label>
                        </div>
                    </div>

                    {/* Input de número para ingresar el valor */}
                    <div className="mb-3">
                        <label htmlFor="adjustmentValue" className="form-label" style={{marginBottom: '3%'}}><strong> Valor del ajuste: </strong></label>
                        <input
                            type="number"
                            id="adjustmentValue"
                            className="form-control"
                            value={adjustmentValue}
                            onChange={(e) => setAdjustmentValue(parseFloat(e.target.value))}
                            min="0"
                            step="0.01"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className="btn btn-secondary"
                            onClick={() => {
                                setAdjustmentType('');
                                setValueType('');
                                setAdjustmentValue('');
                                setShowModalEditPrice(false);
                            }}
                            onHide={() => setShowModalEditPrice(false)}>Cerrar
                    </button>
                    <button type="button" className="btn btn-primary"
                            disabled={!adjustmentType || !valueType || !adjustmentValue || loadingEdit}
                            onClick={() => {
                                setLoadingEdit(true);
                                saveEditPriceProduct();
                            }}>Editar
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
    const editProductModal = () => {

        return (
    <>
            <Modal size="xl" show={showModalEditProduct} onHide={() => setShowModalEditProduct(false)}>
                <Modal.Header className="modal-header">
                    <h1 className="modal-title fs-5" id="modaleEditProductLabel"
                        className="titleModalEditProduct">Editar producto</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => resetStyleErrorsEdit()}></button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="mb-3">
                        <label htmlFor="nombreEdit" className="col-form-label">Nombre:</label>
                        <input type="text" className="form-control" id="nombreEdit" value={productNameEdit}
                               onChange={(e) => {
                                   const productNameEditIng = e.target.value;
                                   if (productNameEditIng.length <= 100) {
                                       setProductNameEdit(productNameEditIng);
                                   }
                                   cleanErrorNameEdit();
                               }}/>
                        <div className="fieldRequiredNameEdit">Campo obligatorio</div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="descriptionEdit" className="col-form-label">Descripción:</label>
                        <input type="text" className="form-control" id="descriptionEdit"
                               value={productDescriptionEdit} onChange={(e) => {
                            const productDescriptionEditIng = e.target.value;
                            if (productDescriptionEditIng.length <= 255) {
                                setProductDescriptionEdit(productDescriptionEditIng);
                            }
                            cleanErrorDescEdit();
                        }}/>
                        <div className="fieldRequiredDescriptionEdit">Campo obligatorio</div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="category" className="col-form-label">Categoría:</label>
                        <select id="selectorEdit" className="form-control" value={selectedCategoryEdit}
                                onChange={handleSelectCategoryEdit}>
                            {categoriesActive
                                .map(category => (
                                    <option value={category.id}>{category.name}</option>
                                ))}
                        </select>
                        <div className="fieldRequiredCategoryEdit">Campo obligatorio</div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="priceEdit" className="col-form-label">Precio:</label>
                        <div className="price-input">
                            <p className="dollar-sign"> $ </p>
                            <input type="number" className="form-control" id="priceEdit"
                                   value={productPriceEdit} onChange={(e) => {
                                const productPriceEditIng = e.target.value;
                                const hasDecimal = productPriceEditIng.includes(".");
                                if (hasDecimal) {
                                    if (productPriceEditIng.length <= 20) {
                                        setProductPriceEdit(productPriceEditIng);
                                    }
                                } else {
                                    if (productPriceEditIng.length <= 17) {
                                        setProductPriceEdit(productPriceEditIng);
                                    }
                                }
                                cleanErrorPriceEdit();
                            }}/>
                        </div>
                        <div className="fieldRequiredPriceEdit">Campo obligatorio</div>
                    </div>

                    <div className="text-center">
                        <button
                            type="button"
                            className="btn btn-primary mt-3"
                            onClick={() => {
                                fetchProductImage(productIdEdit);
                                setShowModalEditImageProduct(true);
                                const closeButton = document.getElementById('btnCloseModalEdit');
                                closeButton.click();
                            }}
                        >
                            Editar Imagen del Producto
                        </button>
                    </div>

                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button id="btnCloseModalEdit" type="button" className="btn btn-secondary"
                            onClick={() => {
                                resetStyleErrorsEdit();
                                setShowModalEditProduct(false)
                            }}>Cerrar
                    </button>
                    <button type="button" className="btn btn-primary"
                            onClick={() => saveEditproduct()}>Guardar
                    </button>
                </Modal.Footer>
            </Modal>

        {/* Modal de edición de imagen */}
        <Modal size="xl" show={showModalEditImageProduct} onHide={() => setShowModalEditImageProduct(false)}>
            <Modal.Header className="modal-header modalImageProduct">
                <h1 className="modal-title fs-5" id="modaleEditProductLabel"
                    className="titleModalEditProduct">Editar imagen del producto</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                    setShowModalEditImageProduct(false);
                }}></button>
            </Modal.Header>

            <Modal.Body className="modal-body bodyModalImageProduct">
                {productImage ? (
                    <>
                        <p>Imagen del producto:</p>
                        <img src={productImage} alt="Imagen del producto" className="img-fluid" width={200}/>
                        <br/>
                        <br/>
                        <button id="btnSaveModalImageProduct" type="button" className="btn btn-primary" onClick={() => {
                            deleteImage();
                        }}
                                disabled={isUpdateImage}>Eliminar imagen
                        </button>
                        <br/>
                        <br/>
                        <p>Cambiar imagen:</p>
                        <p className='recomendacionImg'>Tamaño recomendado: 225x225 px</p>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageChange(e)}
                            className="form-control mt-3"
                        />
                    </>
                ) : (
                    <>
                        <p>No hay imagen cargada.</p>
                        <p>Cargar imagen:</p>
                        <p className='recomendacionImg'>Tamaño recomendado: 225 x 225 px</p>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageChange(e)}
                            className="form-control mt-3"
                        />
                    </>
                )}

            </Modal.Body>

            <Modal.Footer className="modal-footer modalImageProduct">
                <button id="btnCloseModalImageProduct" type="button" className="btn btn-secondary"
                        data-bs-dismiss="modal" onClick={() => {
                    setShowModalEditImageProduct(false);
                    setShowModalEditProduct(true);
                }}>Cerrar
                </button>
                {productImage ? (
                    <button id="btnSaveModalImageProduct" type="button" className="btn btn-primary" onClick={() => {
                        handleImageUpload();
                    }}
                            disabled={isUpdateImage}>Editar imagen
                    </button>
                ) : (
                    <button id="btnSaveModalImageProduct" type="button" className="btn btn-primary" onClick={() => {
                        handleImageUpload();
                    }}
                            disabled={isUpdateImage}>Subir imagen
                    </button>
                )}
            </Modal.Footer>
        </Modal>
        </>
        )
    }



    return (
        <div className="productsContainer">
            {(localStorage.getItem(keyLocalStorage) != null) ? (
                <>
                    { pageHeader() }
                    { filtersContainer() }

                    {(isLoading) ? (
                        loadingTable()
                    ) : (
                        <div className="m-3">
                            <Table responsive className="table-striped">
                                <thead className="tableHead">
                                <tr>
                                    <th>ID</th>
                                    <th>Nombre</th>
                                    <th>Categoría</th>
                                    <th>Descripción</th>
                                    <th>Precio</th>
                                    <th>Estado</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    products.map(product => (
                                            <tr key={product.id}>
                                                <td className="text-alig-center fw-bold">{product.id}</td>
                                                <td className="text-alig-center">{product.name}</td>
                                                <td className="text-alig-center">{product.categoryName}</td>
                                                <td className="text-alig-center">{product.description}</td>
                                                <td className="text-alig-center">${formatPrice(product.price)}</td>
                                                <td className="text-alig-center">{product.status}</td>
                                                <td width="5%">
                                                    <div className="colBtnsActionProd">
                                                        <button className="btn btnEdit"><RequestQuote
                                                        className="btnEdit" size="24"
                                                        onClick={() => {
                                                            editProduct(product);
                                                            setShowModalEditProduct(true);
                                                        }}/></button>
                                                        {product.status == "ACTIVO" ? (
                                                            <button className="btn btnActiveInactive"
                                                                    onClick={() => disableProd(product)}><View
                                                                className="btnActiveInactive" size="24"/>
                                                            </button>
                                                        ) : (
                                                            <button className="btn btnActiveInactive"
                                                                    onClick={() => enableProd(product)}><ViewOff
                                                                className="btnActiveInactive" size="24"/>
                                                            </button>
                                                        )}
                                                        <button className="btn btnDelete"
                                                                onClick={() => deleteProduct(product)}><TrashCan
                                                            className="btnDelete" size="24"/>
                                                        </button>
                                                        {/* Checkbox para seleccionar productos */}
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            checked={selectedProductsToEdit.includes(product.id)}
                                                            onChange={() => handleSelectProduct(product.id)}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                }
                                </tbody>
                            </Table>

                        </div>
                    )}
                    {selectedProductsToEdit.length > 0 &&
                        <div className="mt-3" style={{display: 'flex', flexDirection: 'row-reverse', marginRight: '3%'}}>
                            <Button variant="primary" className="me-1" onClick={() => setShowModalEditPrice(true)}>Editar precios de productos</Button>
                        </div>
                    }
                    { editPriceMasiveProductModal() }
                    { paginationModule() }
                    { modalControlButtons() }
                    { addProductMassiveModal() }
                    { addProductModal() }
                    { editProductModal() }

                </>

            ) : (
                <div id="mainNotFound">
                    <div className="fof">
                        <h1 className='h1Error404'>Error 404</h1>
                    </div>
                </div>
            )}

        </div>
    );
}