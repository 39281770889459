import React from 'react';
import './App.css';
import HomePage from './screens/HomePage';
import NotFound from './screens/NotFound';
import Login from './screens/Login';
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import MisProductos from './screens/MisProductos';
import Categories from './screens/Categories';
import SocialMedia from './screens/SocialMedia';
import Carta from './screens/Carta';
import CartaOrderShop from './screens/CartaOrderShop';
import Orders from './screens/Orders';
import Menu from './components/Menu/Menu';
import ProtectedRoute from './hoc/ProtectedRoute';
import ShopPage_old from './screens/ShopPage_old';
import ShopPage from "./screens/ShopPage";
import {ShopProvider} from "./context/ShopContext";
import ReporteProductos from "./screens/ReporteProductos";
import ReporteCategorias from "./screens/ReporteCategorias";
import ReporteVentas from "./screens/ReporteVentas";
import StatusOrderView from "./screens/StatusOrderView";

function App() {
  return (
    <Router>
      <RoutesWithMenu />
    </Router>
  );
}

function RoutesWithMenu() {

  const location = useLocation();
  const noMenuRoutes = ["/", "/carta", "/estadodelpedido", "/notfound"]; // Poner las rutas que no usan menu completamente en minusculas.
  const showMenu = !noMenuRoutes.includes(location.pathname.toLocaleLowerCase());

  return (
    <ShopProvider>
      {showMenu && <Menu />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/carta" element={<Carta />} />
        <Route path="/estadoDelPedido" element={<StatusOrderView />} />
        <Route path="/pedidoMostrador" element={<CartaOrderShop />} />
        <Route path="/home" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
        <Route path="/shop" element={<ProtectedRoute><ShopPage /></ProtectedRoute>} />
        <Route path="/misProductos" element={<ProtectedRoute><MisProductos /></ProtectedRoute>} />
        <Route path="/categories" element={<ProtectedRoute><Categories /></ProtectedRoute>} />
        <Route path="/diseño" element={<ProtectedRoute><SocialMedia /></ProtectedRoute>} />
        <Route path="/orders" element={<ProtectedRoute><Orders /></ProtectedRoute>} />
        <Route path="/reporteProductos" element={<ProtectedRoute><ReporteProductos /></ProtectedRoute>} />
        <Route path="/reporteCategorias" element={<ProtectedRoute><ReporteCategorias /></ProtectedRoute>} />
        <Route path="/reporteVentas" element={<ProtectedRoute><ReporteVentas /></ProtectedRoute>} />
        {/* <Route path="/createOrder" element={<ProtectedRoute><CreateOrder /></ProtectedRoute>} /> */}
      </Routes>
    </ShopProvider>
  );
}

export default App;
