import {createContext, useContext, useEffect, useState} from "react";
import axios from "axios";

const ShopContext = createContext();

const ShopProvider = ({children}) => {

    const keyLocalStorage = 'authToken';

    const [activeOrders, setActiveOrders] = useState(false);
    const [activeDelivery, setActiveDelivery] = useState(false);
    const [deliveryPrice, setDeliveryPrice] = useState(0);

    const [modules, setModules] = useState([]);
    const [moduleConfigs, setModuleConfigs] = useState([]);

    useEffect(() => {
        getAllModules();
        getAllModuleConfigs();
    }, []);

    setTimeout(function () {
        setActiveDelivery(getValueByCode("isDelivery") == "true");
        setActiveOrders(getValueByCode("isOrderActive") == "true");
        setDeliveryPrice(getValueByCode("priceDeliveryRange1"));
    }, 2000);

    const getAllModules = () => {
        axios.get(process.env.REACT_APP_API_MODULES_ROUTE, {
            withCredentials: true
        })
            .then(response => {
                setModules(response.data);
            })
            .catch(error => {
                console.error(error)});
    }

    const getAllModuleConfigs = () => {
        axios.get(process.env.REACT_APP_API_MODULES_CONFIGS_ROUTE + "1", {
            withCredentials: true
        })
            .then(response => {
                setModuleConfigs(response.data);
            })
            .catch(error => {
                console.error(error)});
    }

    function getValueByCode(code) {
        const moduleId = modules.find(module => module.code === code)?.id;
        if (moduleId === undefined) {
            return null;
        }

        const config = moduleConfigs.find(config => config.moduleConfigId === moduleId);
        if (config) {
            return config.value;
        } else {
            return null;
        }
    }

    const setValueConfig = (value, config) => {
        const queryParams = {
            [config]: value.toString()
        };
        axios.put(process.env.REACT_APP_API_MODULES_CONFIGS_SET_ROUTE + 1, null,{
            params: queryParams,
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
            .then(() => {
                getAllModules();
                getAllModuleConfigs();
            })
            .catch(() => { console.log("error") });
    }

    const toggleActiveOrders = () => {
        setValueConfig(!activeOrders, "isOrderActive");
        setActiveOrders(!activeOrders);
    }

    const toggleActiveDelivery = () => {
        setValueConfig(!activeDelivery, "isDelivery");
        setActiveDelivery(!activeDelivery)
    }

    const changeDeliveryPrice = (deliveryPrice) => {
        setValueConfig(deliveryPrice, "priceDeliveryRange1");
        setDeliveryPrice(deliveryPrice);
    }

    return (
        <ShopContext.Provider
            value={{
                activeOrders,
                toggleActiveOrders,
                activeDelivery,
                toggleActiveDelivery,
                deliveryPrice,
                changeDeliveryPrice
            }}>
            {children}
        </ShopContext.Provider>
    );

}

export {ShopProvider, ShopContext};

export function useShop() {
    return useContext(ShopContext);
}

/*
*
*  Para usar el contexto simplemente tengo que agregar
*  const { activeOrders, toggleActiveOrders } = useShop();
*  O las funciones que querramos de este contexto
*  En el componente que las quiera usar y lo consumimos
*  como si fuese un estado mas, pero afectara a toda la aplicacion
* 
* */