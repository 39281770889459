const statusMessages = {
    RECEIVED: "Recibido",
    IN_PREPARATION: "En Preparación",
    DELIVERY_IN_PROGRESS: "En Delivery",
    FINALIZED: "Finalizado",
    UPDATED: "Actualizado",
    CANCELLED: "Cancelado"
};

export default statusMessages;
