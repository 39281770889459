import './Menu.css';
import axios from 'axios';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IoLogOutOutline } from "react-icons/io5"

const MenuLogoutButton = ({ isOpen }) => {

    const keyLocalStorage = 'authToken';

    const deauthenticate = () => {
        
        axios.get(process.env.REACT_APP_API_LOGOUT_ROUTE, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
        .then(() => { localStorage.removeItem(keyLocalStorage)})
        .catch(() => {});

        localStorage.removeItem(keyLocalStorage);

    }

  return (
    <div className="logout-section menu-item-container">
        <Nav.Link as={Link} to="/" onClick={deauthenticate}>
        <div>
            <IoLogOutOutline className="icon" />
        </div>
        <div className='span-container'>
            <span className={isOpen ? "text" : "text hidden"}>Logout</span>
        </div>
        </Nav.Link>
    </div>
  )
}

export default MenuLogoutButton;