import React, {useEffect, useState} from 'react';
import './ReporteVentas.css';
import axios from "axios";
import {Button, Col, Container, Form, InputGroup, Row, Table} from "react-bootstrap";
import {CloseLarge, Search} from "@carbon/icons-react";
import orderTypeMessages from '../mappers/orderTypeMapper';
import orderSendTypeMessages from '../mappers/orderSendTypeMapper';
import statusMessages from '../mappers/statusMapper';

const ReporteVentas = () => {

  const [report, setReport] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedOrderType, setSelectedOrderType] = useState('');
  const [selectedSendOrderType, setSelectedSendOrderType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [isStartDateInvalid, setIsStartDateInvalid] = useState(false);
  const [endDate, setEndDate] = useState('');
  const [isEndDateInvalid, setIsEndDateInvalid] = useState(false);

  const [totalAmount, setTotalAmount] = useState(0);
  const [subTotalAmount, setSubTotalAmount] = useState(0);

  const orderStatusEnum = ['RECEIVED', 'IN_PREPARATION', 'DELIVERY_IN_PROGRESS', 'FINALIZED', 'UPDATED', 'CANCELLED'];
  const orderTypeEnum = ['WHATSAP', 'EN_MESA', 'A_DISTANCIA', 'MOSTRADOR'];
  const orderSendTypeEnum = ['A_DOMICILIO', 'RETIRO_LOCAL'];

  const keyLocalStorage = 'authToken';
  const getConfig = () => {
    return {
      withCredentials: true,
      headers: {
        Authorization: localStorage.getItem(keyLocalStorage),
      },
      params: {
        status: selectedStatus,
        orderType: selectedOrderType,
        sendOrderType: selectedSendOrderType,
        startDate: startDate,
        endDate: endDate
      }
    }
  }

  useEffect(() => {
    return () => {
    };
  }, [report]);

  const getCleanReport = () => {
    setSelectedStatus('');
    setSelectedOrderType('');
    setSelectedSendOrderType('');
    setStartDate('');
    setEndDate('');
    setIsStartDateInvalid(false);
    setIsEndDateInvalid(false);
    setReport([]);
  }

  const getReport = () => {
    if(areValidDates()) {
      axios.get(process.env.REACT_APP_API_REPORTE_ROUTE, getConfig())
        .then((response) => {
          console.log(response);
          setReport(response.data.reportDetailsDTO);
          setTotalAmount(response.data.totalAmountOrder);
          setSubTotalAmount(response.data.subtotalAmountOrder);
        })
        .catch((error) => {
          console.error(error);
        })
    }
  }

  const areValidDates = () => {
    let isValid = true;
    if (!startDate) {
      setIsStartDateInvalid(true);
      isValid = false;
    }
    if (!endDate) {
      setIsEndDateInvalid(true);
      isValid = false;
    }
    return isValid;
  };

  const handleStatusChange = (event) => {
    const newStatus = event.target.value;
    setSelectedStatus(newStatus);
  };

  const handleOrderTypeChange = (event) => {
    const newOrderType = event.target.value;
    setSelectedOrderType(newOrderType);
  };

  const handleSendOrderTypeChange = (event) => {
    const newSendOrderType = event.target.value;
    setSelectedSendOrderType(newSendOrderType);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setIsStartDateInvalid(false); // Restablecer el estado de invalidación
  };


  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setIsEndDateInvalid(false); // Restablecer el estado de invalidación
  };

  function formatPrice(price) {
    if (!price) return ''; // Manejar caso de valor vacío
    const parts = price.toString().split('.'); // Separar parte entera de la decimal
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agregar separador de miles
    return parts.join(','); // Unir las partes con coma como separador decimal
  }

  return (
    <div className="reporteVentasContainer">
      <Container className="mt-3 mb-3">
        <Row>
          <Col>
            <h1 className="titleReporteVentas">Reporte de ventas</h1>
          </Col>
        </Row>
      </Container>
      {/* Filtros Inicio */}
      <Container>
        <Row>
          <Col>
            <InputGroup className="mb-3">
              <InputGroup.Text>Fecha Desde</InputGroup.Text>
              <Form.Control
                  aria-label="startDate"
                  type="date"
                  name="startDate"
                  value={startDate}
                  onChange={handleStartDateChange}
                  isInvalid={isStartDateInvalid}
              />
            </InputGroup>
          </Col>
          <Col>
            <InputGroup className="mb-3">
              <InputGroup.Text>Fecha Hasta</InputGroup.Text>
              <Form.Control
                  aria-label="endDate"
                  type="date"
                  name="endDate"
                  value={endDate}
                  onChange={handleEndDateChange}
                  isInvalid={isEndDateInvalid}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <InputGroup className="mb-3">
              <Form.Select
                  aria-label="orderStatus"
                  name="orderStatus"
                  value={selectedStatus}
                  onChange={handleStatusChange}
              >
                <option value="">Estado de Pedido</option>
                {orderStatusEnum.map((status) => (
                    <option key={status} value={status}>
                      {statusMessages[status] || status}
                    </option>
                ))}
              </Form.Select>
            </InputGroup>
          </Col>
          <Col xs={3}>
            <InputGroup className="mb-3">
              <Form.Select
                  aria-label="orderType"
                  name="orderType"
                  value={selectedOrderType}
                  onChange={handleOrderTypeChange}
              >
                <option value="">Origen de Pedido</option>
                {orderTypeEnum.map((orderType) => (
                    <option key={orderType} value={orderType}>
                      {orderTypeMessages[orderType] || orderType}
                    </option>
                ))}
              </Form.Select>
            </InputGroup>
          </Col>
          <Col xs={3}>
            <InputGroup className="mb-3">
              <Form.Select
                  aria-label="orderType"
                  name="orderSendType"
                  value={selectedSendOrderType}
                  onChange={handleSendOrderTypeChange}
              >
                <option value="">Tipo de Envío</option>
                {orderSendTypeEnum.map((orderSendType) => (
                    <option key={orderSendType} value={orderSendType}>
                      {orderSendTypeMessages[orderSendType] || orderSendType}
                    </option>
                ))}
              </Form.Select>
            </InputGroup>
          </Col>
          <Col xs={1}>
            <Button className="me-1" variant="primary" onClick={() => getReport()}>
              <Search/>
            </Button>
          </Col>
          <Col xs={1}>
            <Button className="me-1" variant="primary" onClick={() => getCleanReport()}>
              <CloseLarge/>
            </Button>
          </Col>
        </Row>
      </Container>
      {/* Filtros Fin */}
      <Container fluid>
        <Row>
          <Table responsive className="table-striped">
            <thead>
            <tr>
              <th style={{
                backgroundColor: "rgb(235, 120, 107)",
                color: "#ffffff"
              }}>Id de orden
              </th>
              <th style={{
                backgroundColor: "rgb(235, 120, 107)",
                color: "#ffffff"
              }}>Número de orden
              </th>
              <th style={{
                backgroundColor: "rgb(235, 120, 107)",
                color: "#ffffff"
              }}>Fecha de Orden
              </th>
              <th style={{
                backgroundColor: "rgb(235, 120, 107)",
                color: "#ffffff"
              }}>Descripción
              </th>
              <th style={{
                backgroundColor: "rgb(235, 120, 107)",
                color: "#ffffff"
              }}>Origen Pedido
              </th>
              <th style={{
                backgroundColor: "rgb(235, 120, 107)",
                color: "#ffffff"
              }}>Tipo de entrega
              </th>
              <th style={{
                backgroundColor: "rgb(235, 120, 107)",
                color: "#ffffff"
              }}>Estado
              </th>
              <th style={{
                backgroundColor: "rgb(235, 120, 107)",
                color: "#ffffff"
              }}>SubTotal
              </th>
              <th style={{
                backgroundColor: "rgb(235, 120, 107)",
                color: "#ffffff"
              }}>Bonif./Recarg.
              </th>
              <th style={{
                backgroundColor: "rgb(235, 120, 107)",
                color: "#ffffff"
              }}>Total
              </th>
            </tr>
            </thead>
            <tbody>
            {report.length === 0 ? (
                <tr>
                  <td colSpan="10" className="text-center">Por favor ingrese un filtro</td>
                </tr>
            ) : (
                report.map((order, index) => (
                    <tr key={index}>
                      <td>{order.orderId}</td>
                      <td>{order.orderNumber}</td>
                      <td>{order.orderDate}</td>
                      <td>{order.description}</td>
                      <td>{orderTypeMessages[order.orderType] || order.orderType}</td>
                      <td>{orderSendTypeMessages[order.orderSendType] || order.orderSendType}</td>
                      <td>{statusMessages[order.status] || order.status}</td>
                      <td>$ {formatPrice(order.amountSubTotal)}</td>
                      <td> {order.valueAddjustment}</td>
                      <td>$ {formatPrice(order.amountTotal)}</td>
                    </tr>
                )))
            }
            </tbody>
            <tfoot style={{fontWeight: "bold"}}>
            <tr>
              <td colSpan="7" className="text-right">Totales:</td>
              <td>$ {formatPrice(subTotalAmount)}</td>
              <td></td>
              <td>$ {formatPrice(totalAmount)}</td>
            </tr>
            </tfoot>
          </Table>
        </Row>
      </Container>
    </div>
  )
}

export default ReporteVentas;