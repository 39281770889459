import React, {useEffect, useState} from 'react';
import './StatusOrderView.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import {Container, Form, Button, Row, Col, Image} from 'react-bootstrap';
import {LogoFacebook, LogoInstagram, LogoTwitter} from "@carbon/icons-react";
import iconoFooter from "../logoFooter.svg";

export default function StatusOrderView() {
    const[isViewCredentialsOrder, setIsViewCredentialsOrder] = useState(true);

    const [orderLogsStatus, setOrderLogsStatus] = useState([]);
    const [order, setOrder] = useState([]);

    const [orderNumber, setOrderNumber] = useState('');
    const [orderOwner, setOrderOwner] = useState('');

    const [shop, setShop] = useState();
    const [imageSrc, setImageSrc] = useState('');
    const [colorshop, setColorShop] = useState('#000000');
    const [nameShop, setNameShop] = useState('');

    const [instagram, setInstagram] = useState('');
    const [facebook, setFacebook] = useState('');
    const [twitter, setTwitter] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsViewCredentialsOrder(false);
        getStatusOrders(orderNumber, orderOwner);
        getDetailsOrder(orderNumber, orderOwner);
    };

    useEffect(() => {
        document.title = 'QReate - Estado del pedido';
        getShop();
        getRedes()
    }, []);

    const getStatusOrders = (orderNumber, ownerOrder) => {
        axios.get(process.env.REACT_APP_API_ORDER_LOGS_WITH_CREDENTIALS_ROUTE.replace(":orderNumber", orderNumber.trim()).replace(":ownerOrder", ownerOrder.trim()))
            .then(response => {
                setOrderLogsStatus(response.data);
            })
            .catch((r) => {
                dontExistOrder();
            })
    }

    const getDetailsOrder = (orderNumber, ownerOrder) => {
        axios.get(process.env.REACT_APP_API_ORDER_VIEW_STATUS_ROUTE.replace(":orderNumber", orderNumber.trim()).replace(":ownerOrder", ownerOrder.trim()))
            .then(response => {
                setOrder(response.data);
                console.log(response.data);
            })
    }

    const dontExistOrder = () =>{
        setIsViewCredentialsOrder(true);
        Swal.fire({
            title: 'Upsss',
            text: 'No se encontró un pedido con estos datos.',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: 'Cerrar'
        });
    }

    function alertError(){
        Swal.fire({
            title: 'Upsss',
            text: 'Ocurrió un error, vuelve a intentar',
            icon: 'error',
            timer: 1500,
            showConfirmButton: false,
        });
    }

    const getStatus = (status) => {
        switch (status){
            case "RECEIVED" : return "Recibido";
            case "IN_PREPARATION" : return "En preparacion";
            case "DELIVERY_IN_PROGRESS" : return "En camino";
            case "FINALIZED" : return "Finalizado";
            case "CANCELLED" : return "Cancelado";
            default: return "Editado"
        }
    }
    const getShop = () => {
        axios.get(process.env.REACT_APP_API_SHOP_ROUTE + '/1', {
            withCredentials: true
        })
            .then(response => {
                const shopData = response.data;
                if(shopData.image != null){
                    const arrayBuffer = Uint8Array.from(atob(shopData.image), c => c.charCodeAt(0));
                    const blob = new Blob([arrayBuffer], { type: 'image/jpeg' }); // Cambia 'image/jpeg' al tipo MIME correcto según el tipo de imagen que recibas
                    const imageUrl = URL.createObjectURL(blob);
                    setImageSrc(imageUrl);
                } else {
                    setImageSrc(null);
                }
                setShop(shopData);
                setColorShop(shopData.principalColorShop);
                setNameShop(shopData.name);
            })
            .catch(error => {
                console.error(error)});
    }

    const getValueRedInstagram = (redes) => {
        let instagram = redes && redes.find(red => red.socialMediaType === "INSTAGRAM");
        if(instagram != null){
            setInstagram(instagram.socialMediaLink);
        }
    }

    const getValueRedFacebook = (redes) =>{
        let facebook = redes && redes.find(red => red.socialMediaType === "FACEBOOK");
        if(facebook != null){
            setFacebook(facebook.socialMediaLink);
        }
    }

    const getValueRedTwitter = (redes) =>{
        let twitter = redes && redes.find(red => red.socialMediaType === "TWITTER");
        if(twitter != null){
            setTwitter(twitter.socialMediaLink);
        }
    }

    const getRedes = () => {
        axios.get(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE, {
            withCredentials: true,
            params: {
                status: true,
            }
        })
            .then(response => {
                getValueRedInstagram(response.data);
                getValueRedFacebook(response.data);
                getValueRedTwitter(response.data);
            })
    }

    function formatPrice(price) {
        if (!price) return ''; // Manejar caso de valor vacío
        const parts = price.toString().split('.'); // Separar parte entera de la decimal
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agregar separador de miles
        return parts.join(','); // Unir las partes con coma como separador decimal
    }

    return (
        <div className="viewStatusOrder">
            <Container className="banner bannerStatusOrder"
                       style={{
                           backgroundColor: colorshop,
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center"
                       }}>
                {imageSrc != null && imageSrc != '' ? (
                    <Image src={imageSrc} alt="QReate" className='imgCartaBanner'/>
                ) : (
                    <h1 className='nameShopCarta'>{nameShop}</h1>
                )}
            </Container>
            {isViewCredentialsOrder ?
                (
                    <>
                        <h1 className="orderSearchTitle">Ver mi pedido</h1>
                        <p className="orderSearchComment">Conoce el estado de tu pedido y todos sus detalles en esta sección.</p>
                        <Row className="justify-content-center">
                            <Col xs={12} md={6}>
                                <Form onSubmit={handleSubmit} className="orderSearchForm">
                                    <Form.Group controlId="orderNumber">
                                        <Form.Label>Número de pedido</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Ingrese el número de pedido"
                                            value={orderNumber}
                                            onChange={(e) => setOrderNumber(e.target.value)}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="orderOwner" className="mt-3">
                                        <Form.Label>Nombre del titular</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Ingrese el nombre del titular del pedido"
                                            value={orderOwner}
                                            onChange={(e) => setOrderOwner(e.target.value)}
                                            required
                                        />
                                    </Form.Group>

                                    <button type="submit" className="btn consultButton mt-4">
                                        Ver
                                    </button>
                                </Form>
                            </Col>
                        </Row>
                    </>
                ) :
                (
                    <>
                        <div className="orderInfoBox">
                            <h1 className="orderStatusTitle">
                                <div className="orderInfo">
                                    <span className="orderLabel">Número pedido:</span>
                                    <span className="orderNumber">{orderNumber}</span>
                                </div>
                                <div className="orderInfo">
                                    <span className="orderLabel">Titular:</span>
                                    <span className="orderOwner">{orderOwner}</span>
                                </div>
                            </h1>
                        </div>
                        <div className="orderLogTimeline">
                            {orderLogsStatus.map(log => (
                                <div className="orderLogRowStatusOrder" key={log.id}>
                                    <div className="timelineDot"></div>
                                    <div className="orderLogContent">
                                        <div className="orderLogStatus">{getStatus(log.status)}</div>
                                        <div className="orderLogDate">{log.dateStatus}</div>
                                        <div className="orderLogObservation">{log.observation}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="orderDetailsContainer">
                            <h2 className="orderDetailsTitle">Detalles del Pedido</h2>
                            <div className="orderDetailsRow">
                                <span className="orderDetailsLabel">Fecha del pedido:</span>
                                <span className="orderDetailsValue">{order.orderDate}</span>
                            </div>
                            <div className="orderDetailsRow">
                                <span className="orderDetailsLabel">Tipo de entrega:</span>
                                <span
                                    className="orderDetailsValue">{order.delivery ? 'A domicilio' : 'Retiro por el local'}</span>
                            </div>
                            {order.delivery &&
                                <>
                                    <div className="orderDetailsRow">
                                        <span className="orderDetailsLabel">Precio de envio:</span>
                                        <span
                                            className="orderDetailsValue">${formatPrice(order.amountDelivery)} (Incluído en el total)</span>
                                    </div>
                                    <div className="orderDetailsRow">
                                        <span className="orderDetailsLabel">Calle:</span>
                                        <span
                                            className="orderDetailsValue">{order.address}</span>
                                    </div>
                                    <div className="orderDetailsRow">
                                        <span className="orderDetailsLabel">Numero:</span>
                                        <span
                                            className="orderDetailsValue">{order.numberAdress}</span>
                                    </div>
                                    <div className="orderDetailsRow">
                                        <span className="orderDetailsLabel">Piso:</span>
                                        <span
                                            className="orderDetailsValue">{order.floor}</span>
                                    </div>
                                    <div className="orderDetailsRow">
                                        <span className="orderDetailsLabel">Departamento:</span>
                                        <span
                                            className="orderDetailsValue">{order.department}</span>
                                    </div>
                                    <div className="orderDetailsRow">
                                        <span className="orderDetailsLabel">Observación del envio:</span>
                                    </div>
                                    <div className="orderDetailsRow">
                                        <div className="orderDetailsObservation">
                                            {order.deliveryObservation}
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="orderDetailsRow">
                                <span className="orderDetailsLabel">Observación del pedido:</span>
                            </div>
                            <div className="orderDetailsRow">
                                <div className="orderDetailsObservation">
                                    {order.orderObservation}
                                </div>
                            </div>
                            <div className="orderDetailsRow">
                                <span className="orderDetailsLabel">PRECIO TOTAL:</span>
                                <span className="orderDetailsValue">${formatPrice(order.totalAmount + order.amountDelivery)}</span>
                            </div>

                            <hr className="sectionDivider"/>

                            <h3 className="orderProductsTitle">Productos por categoría</h3>
                            {order.orderDetails && Object.entries(order.orderDetails.reduce((acc, detail) => {
                                if (!acc[detail.categoryName]) {
                                    acc[detail.categoryName] = [];
                                }
                                acc[detail.categoryName].push(detail);
                                return acc;
                            }, {})).map(([categoryName, details]) => (
                                <div key={categoryName} className="categoryGroup">
                                    <h4 className="categoryName">{categoryName}</h4>
                                    {details.map((detail) => (
                                        <div key={detail.id} className="productDetail">
                                            <div className="productName">{detail.productName}</div>
                                            <div className="productInfo">
                                                <span className="productQuantity">Cantidad: {detail.quantity}</span>
                                                <span
                                                    className="productPrice">Precio Unitario: ${formatPrice(detail.unitaryPrice)}</span>
                                                <span
                                                    className="productSubtotal">Subtotal: ${formatPrice(detail.subTotal)}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <button className="btn volverButton m-4" onClick={() => window.location.reload()}>
                            Volver
                        </button>
                    </>
                )
            }

            <footer className='footer-status-order' style={{
                backgroundColor: colorshop,
                padding: '20px 0',
                bottom: '0',
                width: '100%'
            }}>
                <Container>
                    <Row className="text-center">
                        <Col>
                            <p>Seguínos en nuestras redes</p>
                            <div>
                                {instagram && (
                                    <a className="btn" target="_blank" rel="noopener noreferrer" href={instagram}>
                                        <LogoInstagram size="24"/>
                                    </a>
                                )}
                                {facebook && (
                                    <a className="btn" target="_blank" rel="noopener noreferrer" href={facebook}>
                                        <LogoFacebook size="24"/>
                                    </a>
                                )}
                                {twitter && (
                                    <a className="btn" target="_blank" rel="noopener noreferrer" href={twitter}>
                                        <LogoTwitter size="24"/>
                                    </a>
                                )}
                            </div>
                            <img src={iconoFooter} alt="Icono" className="mt-3"/>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </div>
    );
}