import { IconContext } from 'react-icons';
import { FaCircle } from 'react-icons/fa';

const ShopOnlyReadInput = ({ field, labelText, value }) => {

  const iconStyles = {
    color: value,
  }

  return (
    <div className='shopGenericInfoPanelInput'>
        <div>
            <label htmlFor={field} className='shopGenericInfoPanelInputLabel'>{labelText}</label>
        </div>
          <div>
        {field === 'color' ? (
          <div className="colorDisplayContainer">
            <input id={field} className='shopInput' value={value} disabled readOnly/>
            <IconContext.Provider value={iconStyles}>
              <FaCircle className='colorDisplayIcon'/>
            </IconContext.Provider>
          </div>
        ) : ( 
          <div>
            <input id={field} className='shopInput' value={value} disabled readOnly/>
          </div>
        )}
          </div>

    </div>
  )
}

export default ShopOnlyReadInput;